import React from "react"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import useAccessToken from "../../hooks/useAccessToken"

export const AuthenticationTokenProviderContext = React.createContext<{ accessToken: string | undefined }>({
    accessToken: undefined,
})

const AuthenticationTokenProvider = ({
    children,
    scopes,
    isPublic,
}: {
    children: React.ReactNode
    scopes: string[]
    isPublic?: boolean
}) => {
    const { accessToken, isAccessTokenLoading } = useAccessToken({ scopes, isPublic })

    return (
        <LoadingPlaceholder loading={isAccessTokenLoading} message="Authenticating">
            <AuthenticationTokenProviderContext.Provider value={{ accessToken }}>
                {children}
            </AuthenticationTokenProviderContext.Provider>
        </LoadingPlaceholder>
    )
}

export default AuthenticationTokenProvider
