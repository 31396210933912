import makeStyles from "@mui/styles/makeStyles"

const useAttributeTableStyles = makeStyles({
    table: {
        minWidth: 750,
    },
    tableContainer: {
        height: "-webkit-fill-available",
    },
})

export default useAttributeTableStyles
