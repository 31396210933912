import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth"
import ClearAllIcon from "@mui/icons-material/ClearAll"
import DateRangeIcon from "@mui/icons-material/DateRange"
import { Stack, Tooltip } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { StyledButton } from "./CustomDatePicker.styles"

const NO_DATA_MESSAGE = "No data for this time interval."

type Props = {
    displayIcons?: boolean
    flexDirection?: "row" | "column" | undefined
    interval: [Dayjs, Dayjs]
    maxDate: Dayjs
    minDate: Dayjs
    resetOnClick?: boolean
    onClear: () => void
    onSetInterval: (e: [Dayjs, Dayjs]) => void
}

const QuickRangePicker = ({
    displayIcons = true,
    flexDirection = "column",
    interval,
    maxDate,
    resetOnClick = false,
    onClear,
    onSetInterval,
}: Props) => {
    const now = dayjs.utc()

    // Last 24 Hours
    const last24Hours = now.subtract(24, "hours")
    const isLast24HoursDisabled = last24Hours.isAfter(maxDate)
    const onLast24HoursClick = () => {
        !isLast24HoursSelected && onSetInterval([last24Hours, now])
        isLast24HoursSelected && onClear()
    }
    const isLast24HoursSelected = interval[0].isSame(last24Hours, "day") && interval[1].isSame(maxDate, "day")

    // Last 3 Days
    const threeDaysAgo = now.subtract(2, "days").startOf("day")
    const isThreeDaysAgoDisabled = threeDaysAgo.isAfter(maxDate)
    const onLastThreeDaysClick = () => {
        !isLastThreeDaysSelected && onSetInterval([threeDaysAgo, now])
        isLastThreeDaysSelected && onClear()
    }
    const isLastThreeDaysSelected = interval[0].isSame(threeDaysAgo, "day") && interval[1].isSame(maxDate, "day")

    // Last 7 Days
    const sevenDaysAgo = now.subtract(6, "days").startOf("day")
    const isSevenDaysAgoDisabled = sevenDaysAgo.isAfter(maxDate)
    const onLastWeekClick = () => {
        !isLastWeekSelected && onSetInterval([sevenDaysAgo, now])
        isLastWeekSelected && onClear()
    }
    const isLastWeekSelected = interval[0].isSame(sevenDaysAgo, "day") && interval[1].isSame(maxDate, "day")

    // Last Month
    const thirtyDaysAgo = now.subtract(29, "days").startOf("day")
    const isThirtyDaysAgoDisabled = thirtyDaysAgo.isAfter(maxDate)
    const onLastMonthClick = () => {
        !isLastMonthSelected && onSetInterval([thirtyDaysAgo, now])
        isLastMonthSelected && onClear()
    }
    const isLastMonthSelected = interval[0].isSame(thirtyDaysAgo, "day") && interval[1].isSame(maxDate, "day")

    return (
        <Stack alignItems="flex-start" flexDirection={flexDirection} gap={1} justifyContent="center">
            {!resetOnClick && (
                <StyledButton data-testid={clearTestId} onClick={onClear}>
                    {displayIcons && <ClearAllIcon />}
                    Clear
                </StyledButton>
            )}
            <Tooltip title={isLast24HoursDisabled ? NO_DATA_MESSAGE : ""}>
                <span>
                    <StyledButton
                        data-testid={last24HoursTestId}
                        disabled={isLast24HoursDisabled}
                        isSelected={isLast24HoursSelected}
                        onClick={onLast24HoursClick}
                    >
                        {displayIcons && <DateRangeIcon />}
                        Last 24 hours
                    </StyledButton>
                </span>
            </Tooltip>

            <Tooltip title={isThreeDaysAgoDisabled ? NO_DATA_MESSAGE : ""}>
                <span>
                    <StyledButton
                        data-testid={lastThreeDaysTestId}
                        disabled={isThreeDaysAgoDisabled}
                        isSelected={isLastThreeDaysSelected}
                        onClick={onLastThreeDaysClick}
                    >
                        {displayIcons && <CalendarMonthIcon />}
                        Last 3 days
                    </StyledButton>
                </span>
            </Tooltip>

            <Tooltip title={isSevenDaysAgoDisabled ? NO_DATA_MESSAGE : ""}>
                <span>
                    <StyledButton
                        data-testid={lastWeekTestId}
                        disabled={isSevenDaysAgoDisabled}
                        isSelected={isLastWeekSelected}
                        onClick={onLastWeekClick}
                    >
                        {displayIcons && <CalendarMonthIcon />}
                        Last 7 days
                    </StyledButton>
                </span>
            </Tooltip>

            <Tooltip title={isThirtyDaysAgoDisabled ? NO_DATA_MESSAGE : ""}>
                <span>
                    <StyledButton
                        data-testid={lastMonthTestId}
                        disabled={isThirtyDaysAgoDisabled}
                        isSelected={isLastMonthSelected}
                        onClick={onLastMonthClick}
                    >
                        {displayIcons && <CalendarViewMonthIcon />}
                        Last 30 days
                    </StyledButton>
                </span>
            </Tooltip>
        </Stack>
    )
}

export default QuickRangePicker

const clearTestId = "qa-date-range-clear-button"
const last24HoursTestId = "qa-date-range-last-24-hours-button"
const lastThreeDaysTestId = "qa-date-range-last-three-days-button"
const lastWeekTestId = "qa-date-range-last-week-button"
const lastMonthTestId = "qa-date-range-last-month-button"
