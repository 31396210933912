import baseAtlasApi from "../store/baseAtlasApi"
import { FeatureFlags } from "./featureFlags"

const featureFlagsApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        getFeatureFlagForPublicApp: build.query<{ isEnabled: boolean }, { featureFlag: FeatureFlags }>({
            query: ({ featureFlag }) => ({
                method: "GET",
                params: { feature: featureFlag },
                url: "feature/public",
            }),
        }),
    }),
})

export const { useGetFeatureFlagForPublicAppQuery } = featureFlagsApi
