import { useEffect, useMemo, useState } from "react"
import { LngLatBounds } from "@emblautec/mapbox-gl"
import { useMap } from "@emblautec/react-map-gl"
import { availableURLSearchParams, useSearchParams } from "@windgis/shared"
import { Subscription } from "model/ais/Subscription"
import { setSelectedVesselHasNoData } from "reducers/ais"
import { getAISSubscriptionVisibility } from "selectors/aisSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import { AisSubscriptionCard } from "../components/AisSubscriptionCard"
import { boatKeys } from "../constants"
import {
    filterVesselsBySearchValue,
    removeDuplicates,
    removeEmptyBoatTypes,
    sortVesselsAlphabetically,
    transformBoatDetailsToVesselsUiModel,
    transformBoatsToVesselsUiModel,
} from "../helpers"
import { BoatDetailsWithCoordinates } from "../models/BoatDetails"
import { VesselUiModel } from "../models/Vessel.ui.model"

export type AisSubscriptionCardContainerProps = {
    archiveVesselsDetails: BoatDetailsWithCoordinates[]
    subscription: Subscription
    isArchiveFeatureEnabled: boolean
    isArchiveMetadataLoading: boolean
    isArchivePlayerActive: boolean
    onArchivePlayerCheck: (isChecked: boolean) => void
}

const AisSubscriptionCardContainer = ({
    archiveVesselsDetails,
    subscription,
    isArchiveFeatureEnabled,
    isArchiveMetadataLoading,
    isArchivePlayerActive,
    onArchivePlayerCheck,
}: AisSubscriptionCardContainerProps) => {
    const subscriptionVisibility = useAppSelector(state => getAISSubscriptionVisibility(state, subscription.id))

    const areAISPlaybackBoatsOnMap = useAppSelector(state => state.AIS.areAISPlaybackBoatsOnMap)

    const liveData = useAppSelector(state => state.AIS.subscriptionData)

    const liveDataBoatCount = liveData[subscription.id]?.boats.length ?? 0

    const archiveDataBoatCount = archiveVesselsDetails.length

    const [isToggled, setIsToggled] = useState(subscriptionVisibility === "visible")

    const [searchValue, setSearchValue] = useState("")

    const { mainMap } = useMap()

    const dispatch = useAppDispatch()

    const { getSearchParamValue, updateSearchParams } = useSearchParams()

    const pausePlayback = getSearchParamValue(availableURLSearchParams.pausePlayback.key) === "true"

    const liveVessels = useMemo(
        () =>
            sortVesselsAlphabetically(
                removeDuplicates(
                    filterVesselsBySearchValue(
                        transformBoatsToVesselsUiModel(liveData[subscription.id]?.boats ?? []),
                        searchValue,
                    ),
                ),
            ),
        [liveData, subscription.id, searchValue],
    )

    const archiveVessels = useMemo(
        () =>
            sortVesselsAlphabetically(
                removeDuplicates(
                    filterVesselsBySearchValue(
                        transformBoatDetailsToVesselsUiModel(archiveVesselsDetails ?? []),
                        searchValue,
                    ),
                ),
            ),
        [archiveVesselsDetails, searchValue],
    )

    const liveVesselsByType = useMemo(
        () =>
            liveVessels.reduce(
                (acc: Record<string, VesselUiModel[]>, vessel) => {
                    acc[vessel.aisTypeSummary].push(vessel)

                    return acc
                },
                Object.fromEntries(Object.values(boatKeys).map(type => [type, []])),
            ),
        [liveVessels],
    )

    const vesselsByType = useMemo(
        () =>
            archiveVessels.reduce(
                (acc: Record<string, VesselUiModel[]>, vessel) => {
                    acc[vessel.aisTypeSummary].push(vessel)

                    return acc
                },
                Object.fromEntries(Object.values(boatKeys).map(type => [type, []])),
            ),
        [archiveVessels],
    )

    const formattedVesselsByType = useMemo(() => removeEmptyBoatTypes(vesselsByType), [vesselsByType])

    const formattedLiveVesselsByType = useMemo(() => removeEmptyBoatTypes(liveVesselsByType), [liveVesselsByType])

    const vesselsToDisplay = areAISPlaybackBoatsOnMap ? formattedVesselsByType : formattedLiveVesselsByType

    const vesselsToDisplayCount = areAISPlaybackBoatsOnMap ? archiveVessels.length : liveVessels.length

    const boatCount = areAISPlaybackBoatsOnMap ? archiveDataBoatCount : liveDataBoatCount

    const followedVesselId = getSearchParamValue(availableURLSearchParams.selectedVesselId.key) ?? undefined

    const followedVessel = useMemo(
        () => archiveVessels.find(vessel => vessel.id === followedVesselId),
        [archiveVessels, followedVesselId],
    )

    const handleZoomInClick = (coordinates: { lat?: number; lon?: number }) => {
        if (!coordinates.lat || !coordinates.lon) return

        const bounds = new LngLatBounds()
        bounds.extend([coordinates.lon, coordinates.lat])
        mainMap?.fitBounds(bounds, { animate: true, linear: false, maxZoom: 14, padding: 200 })
    }

    const handleFollowVesselPositionChange = (coordinates: { lat?: number; lon?: number }) => {
        if (!followedVessel || !coordinates.lat || !coordinates.lon) return

        const bounds = new LngLatBounds()
        bounds.extend([coordinates.lon, coordinates.lat])
        mainMap?.easeTo({ center: [coordinates.lon, coordinates.lat] })
    }

    const handleFollowVesselClick = (vesselId: string, coordinates: { lat?: number; lon?: number }) => {
        if (followedVesselId === vesselId) {
            updateSearchParams({ remove: [availableURLSearchParams.selectedVesselId.key] })
            return
        }

        updateSearchParams({
            set: [
                { searchParamKey: availableURLSearchParams.selectedVesselId.key, searchParamValue: vesselId },
                { searchParamKey: availableURLSearchParams.pausePlayback.key, searchParamValue: "true" },
            ],
        })

        const bounds = new LngLatBounds()
        bounds.extend([coordinates.lon, coordinates.lat])

        mainMap
            ?.fitBounds(bounds, { animate: true, linear: false, maxZoom: 12, padding: 200 })
            ?.once("idle", () => updateSearchParams({ remove: [availableURLSearchParams.pausePlayback.key] }))
    }

    useEffect(() => {
        setIsToggled(subscriptionVisibility === "visible")
    }, [subscriptionVisibility])

    useEffect(() => {
        setSearchValue("")
    }, [isArchivePlayerActive])

    useEffect(() => {
        const animationFrame = requestAnimationFrame(() => {
            if (pausePlayback) return

            handleFollowVesselPositionChange({ lat: followedVessel?.lat, lon: followedVessel?.lon })
        })

        return () => cancelAnimationFrame(animationFrame)
    }, [followedVesselId, followedVessel?.lat, followedVessel?.lon, pausePlayback])

    useEffect(() => {
        if (!followedVessel && followedVesselId) {
            dispatch(setSelectedVesselHasNoData(true))
        } else {
            dispatch(setSelectedVesselHasNoData(false))
        }
    }, [followedVessel, followedVesselId])

    return (
        <AisSubscriptionCard
            areLiveVesselsVisibleOnMap={!areAISPlaybackBoatsOnMap}
            followedVesselId={followedVesselId}
            searchValue={searchValue}
            subscription={subscription}
            vesselsCount={vesselsToDisplayCount}
            vesselsRecord={vesselsToDisplay}
            isArchiveFeatureEnabled={isArchiveFeatureEnabled && subscriptionVisibility != "none"}
            isArchiveMetadataLoading={isArchiveMetadataLoading}
            isArchivePlayerActive={isArchivePlayerActive}
            isToggled={isToggled && subscriptionVisibility === "visible" && subscription.isEnabled && boatCount > 0}
            isToggleDisabled={subscriptionVisibility !== "visible" || !subscription.isEnabled}
            onArchivePlayerCheck={onArchivePlayerCheck}
            onFollowVesselClick={handleFollowVesselClick}
            onSearchValueChange={value => setSearchValue(value)}
            onToggleVisibility={isVisible => setIsToggled(isVisible)}
            onZoomInClick={handleZoomInClick}
        />
    )
}

export default AisSubscriptionCardContainer
