import React, { useEffect, useRef, useState } from "react"
import { TextField, Tooltip, Typography } from "@mui/material"
import Popover from "@mui/material/Popover"
import Icons from "../../icons"

function IconPopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [query, setQuery] = React.useState("")

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : null

    const icons = React.useMemo(() => {
        return Object.keys(Icons).map(iconName => {
            return { icon: Icons[iconName], name: iconName }
        })
    }, [Icons])

    // This is a component instance
    const currentIcon = Icons[props.icon]

    return (
        <div>
            <div className="icon-container" style={{ backgroundColor: props.color }} onClick={handleClick}>
                {currentIcon}
                {!props.icon && <Typography variant="button">None</Typography>}
            </div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "center",
                }}
                id={id}
                open={open}
                PaperProps={{
                    style: { marginLeft: 8, overflowY: "hidden", userSelect: "none" },
                }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "center",
                }}
                onClose={handleClose}
            >
                <div className="icon-popover">
                    <div className="search">
                        <TextField
                            fullWidth
                            placeholder="search"
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                        ></TextField>
                    </div>
                    <div className="icons">
                        {icons
                            .filter(icon => icon.name.toLowerCase().includes(query.toLowerCase()))
                            .map(icon => {
                                return (
                                    <Tooltip title={icon.name}>
                                        <div
                                            className={icon.name === props.icon ? "icon selected" : "icon"}
                                            onClick={() => props.onIconChanged(icon.name)}
                                        >
                                            {icon.icon}
                                        </div>
                                    </Tooltip>
                                )
                            })}
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default IconPopover
