import { useCallback, useMemo } from "react"
import { Dayjs } from "dayjs"
import { PlaybackSpeed } from "features/ais/models/PlaybackSpeed"
import { PLAYBACK_SPEED_SETTINGS_DICTIONARY, SLIDER_RANGE_LENGTH, getSecondsBetweenBuckets } from "../utils"

const useSliderValueTransform = (
    startDate: Dayjs,
    endDate: Dayjs,
    initialIntervalStartDate: Dayjs,
    initialIntervalEndDate: Dayjs,
    playbackSpeed: PlaybackSpeed,
) => {
    const range = useMemo(
        () =>
            ((1 + PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].interpolationFactor) *
                endDate.diff(startDate, "minutes")) /
            PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].bucketSizeInMinutes,
        [endDate, playbackSpeed, startDate],
    )

    const step = useMemo(() => SLIDER_RANGE_LENGTH / range, [range])

    const secondsBetweenBuckets = useMemo(
        () =>
            getSecondsBetweenBuckets(
                PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].bucketSizeInMinutes,
                PLAYBACK_SPEED_SETTINGS_DICTIONARY[playbackSpeed].interpolationFactor,
            ),
        [playbackSpeed],
    )

    const dateToSliderValue = useCallback(
        (date: Dayjs) => {
            const diff = Math.floor(date.diff(startDate, "seconds") / secondsBetweenBuckets)

            return (diff * SLIDER_RANGE_LENGTH) / range
        },
        [range, secondsBetweenBuckets, startDate],
    )

    const dateToSliderValueInitially = useCallback(
        (date: Dayjs) => {
            const diff = Math.floor(date.diff(initialIntervalStartDate, "seconds") / secondsBetweenBuckets)

            return (diff * SLIDER_RANGE_LENGTH) / range
        },
        [range, secondsBetweenBuckets, initialIntervalStartDate],
    )

    const sliderValueToBucketIndex = useCallback((value: number) => (value / SLIDER_RANGE_LENGTH) * range, [range])

    const sliderValueToDate = useCallback(
        (value: number) => {
            const bucketIndex = sliderValueToBucketIndex(value)

            return startDate.add(bucketIndex * secondsBetweenBuckets, "seconds")
        },
        [secondsBetweenBuckets, sliderValueToBucketIndex, startDate],
    )

    return { dateToSliderValue, dateToSliderValueInitially, sliderValueToBucketIndex, sliderValueToDate, step }
}

export default useSliderValueTransform
