import { GenericLayer } from "../models/genericLayer.model"
import { isGroup } from "./isGroup"

export function isChildOf<T = object>(
    layers: GenericLayer<T>[],
    childId: string,
    parentId: string,
    obj = { childFound: false, parentFound: false },
) {
    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i]

        if (obj.childFound) {
            break
        }

        if (layer.resourceId === parentId) {
            obj.parentFound = true
        }

        if (layer.resourceId === childId) {
            obj.childFound = true
        }

        if (isGroup(layer)) {
            isChildOf(layer.layers!, childId, parentId, obj)
        }

        if (obj.childFound) {
            break
        }

        if (layer.resourceId === parentId) {
            obj.parentFound = false
        }
    }

    return obj.childFound && obj.parentFound
}
