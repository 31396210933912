import baseAtlasApi from "../../store/baseAtlasApi"
import { DprDataset } from "./models/DprDataset"
import { DprDatasetColumns } from "./models/DprDatasetColumns"
import { DprMilestoneHistoryEntry } from "./models/DprMilestoneHistoryEntry"

export const dprIntegrationApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        fetchDprDataset: build.query<DprDataset, string>({
            query: datasetId => ({
                method: "GET",
                url: `dpr/milestones/datasets/${datasetId}`,
            }),
        }),
        fetchDprDatasetColumns: build.query<DprDatasetColumns, { datasetId: string; isPublic: boolean }>({
            query: ({ datasetId, isPublic }) => ({
                method: "GET",
                url: `dpr/${isPublic ? "public/" : ""}milestones/datasets/${datasetId}/columns`,
            }),
        }),
        fetchLocationStatusHistory: build.query<
            DprMilestoneHistoryEntry[],
            { datasetId: string; locationName: string; isPublic: boolean }
        >({
            query: ({ datasetId, locationName, isPublic }) => ({
                method: "GET",
                url: `dpr/${isPublic ? "public/" : ""}milestones/datasets/${datasetId}/locations/${locationName}/status/history`,
            }),
        }),
    }),
})

export const {
    useFetchDprDatasetColumnsQuery,
    useFetchDprDatasetQuery,
    useFetchLocationStatusHistoryQuery,
    usePrefetch: useDatasetsPrefetch,
} = dprIntegrationApi
