import { styled } from "@mui/material"

export const StyledGroupTypeImage = styled("img")`
    width: 27px;
    height: 27px;
`

export const StyledVesselImage = styled("img")`
    width: 20px;
    height: 20px;
`
