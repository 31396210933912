import { ComponentProps } from "react"
import Map from "@emblautec/react-map-gl"
import config from "config"

const transformRequestResourceTypes = ["Tile", "SpriteJSON", "SpriteImage"]

export const getMapOptions = (
    accessToken: string | undefined,
    clientId: number | null,
    projectId: number | null,
): Partial<ComponentProps<typeof Map>> => ({
    antialias: true,
    attributionControl: false,
    boxZoom: true,
    dragPan: true,
    mapboxAccessToken: config.mapboxApiKey,
    maxZoom: 24,
    optimizeForTerrain: false,
    styleDiffing: false,
    transformRequest: (url: string, resourceType: any) => {
        if (transformRequestResourceTypes.includes(resourceType) && url.startsWith(config.apiUrl)) {
            let baseUrl = `${url}?ClientId=${clientId}&ProjectId=${projectId}`

            if (accessToken) baseUrl += `&key=${accessToken}`

            return { url: baseUrl }
        }
        return { url }
    },
    //@ts-ignore
    useWebGL2: true,
    hash: false,
})
