import { useState } from "react"
import { Terrain } from "@mui/icons-material"
import { Divider, Popover, Slider, Switch, Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { useExaggerationSliderStyles } from "./styles/exaggerationSliderStyles"

const ExaggerationSlider = ({
    exaggerationValue,
    fullScreenEl,
    terrainEnabled,
    onTerrainExaggerationChanged,
    onTerrainExaggerationCommitted,
    onTerrainToggled,
}) => {
    const classes = useExaggerationSliderStyles()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const onOpenPopover = event => {
        setAnchorEl(event.currentTarget)
    }

    const onClosePopover = () => {
        setAnchorEl(null)
    }

    const handleTerrainToggle = () => {
        onTerrainToggled()
    }

    const onExaggerationChange = (e, newValue) => {
        onTerrainExaggerationChanged(newValue)
    }

    const onExaggerationCommited = (e, value) => {
        if (onTerrainExaggerationCommitted) {
            onTerrainExaggerationCommitted(value)
        }
    }

    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Terrain exaggeration">
                <div className={classes.iconContainer} onClick={onOpenPopover}>
                    <Terrain className={`${classes.icon} ${terrainEnabled ? classes.IconEnabled : ""}`} />
                </div>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                container={fullScreenEl}
                getContentAnchorEl={null}
                open={open}
                onClose={onClosePopover}
            >
                <div className={classes.popoverRoot}>
                    <div className={classes.headerContainer}>
                        <Typography className={classes.headerTypography} variant="h6">
                            <Terrain className={`${classes.headerIcon} ${terrainEnabled ? classes.IconEnabled : ""}`} />
                            3D Terrain
                        </Typography>
                        <Switch checked={terrainEnabled} onChange={handleTerrainToggle} />
                    </div>
                    <Divider />
                    <div className={classes.exaggerationContainer}>
                        <Typography
                            className={classes.flexGrow}
                            style={{ color: terrainEnabled ? "rgba(0, 0, 0, 0.87)" : "lightgray" }}
                            variant="subtitle2"
                        >
                            Exaggeration
                        </Typography>
                        <div style={{ color: terrainEnabled ? "rgba(0, 0, 0, 0.87)" : "lightgray" }}>
                            {exaggerationValue}
                        </div>
                    </div>

                    <div className={classes.sliderContainer}>
                        <Tooltip title="Drag slider to adjust exaggeration">
                            <Slider
                                aria-labelledby="map-exaggeration-slider"
                                defaultValue={exaggerationValue}
                                max={5}
                                min={1}
                                step={0.1}
                                value={exaggerationValue}
                                valueLabelDisplay="auto"
                                disabled={!terrainEnabled}
                                onChange={onExaggerationChange}
                                onChangeCommitted={onExaggerationCommited}
                            />
                        </Tooltip>
                    </div>
                </div>
            </Popover>
        </div>
    )
}

export default ExaggerationSlider
