import { Close, CloudDownload } from "@mui/icons-material"
import { Tooltip } from "@mui/material"
import { TruncatedTypography } from "@windgis/shared"
import { StyledDownloadContainerDiv, StyledHeaderDiv, StyledIconButton, StyledSelectedValueTypography } from "./styles"

type Props = {
    layerName: string
    numberOfSelectedFeatures: number
    isDownloadable: boolean
    onClose: () => void
    onDownloadClick: () => void
}

const AttributeTableHeader = ({
    layerName,
    numberOfSelectedFeatures,
    isDownloadable,
    onClose,
    onDownloadClick,
}: Props) => {
    return (
        <StyledHeaderDiv>
            <TruncatedTypography color={theme => theme.customColors.white} variant="h6">
                Attribute table - {layerName}
            </TruncatedTypography>
            <StyledDownloadContainerDiv>
                {numberOfSelectedFeatures > 0 && (
                    <StyledSelectedValueTypography variant="subtitle2">
                        {numberOfSelectedFeatures}
                    </StyledSelectedValueTypography>
                )}
                <Tooltip
                    title={
                        isDownloadable
                            ? numberOfSelectedFeatures > 0
                                ? "Download selected features"
                                : "Download all features"
                            : "Layer is not downloadable"
                    }
                >
                    <StyledIconButton
                        aria-label="Download"
                        data-testid={downloadTestId}
                        size="small"
                        disabled={!isDownloadable}
                        onClick={onDownloadClick}
                    >
                        <CloudDownload />
                    </StyledIconButton>
                </Tooltip>
            </StyledDownloadContainerDiv>
            <StyledIconButton data-testid={closeTestId} size="large" onClick={onClose}>
                <Close />
            </StyledIconButton>
        </StyledHeaderDiv>
    )
}

export default AttributeTableHeader

const closeTestId = "qa-attribute-table-close-button"
const downloadTestId = "qa-attribute-table-download-button"
