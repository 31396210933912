import makeStyles from "@mui/styles/makeStyles"

const useSearchFieldStyles = makeStyles({
    searchIcon: {
        width: 20,
    },
    searchInput: {
        fontSize: 14,
        padding: 8,
    },
})

export default useSearchFieldStyles
