import { ReactNode, useMemo } from "react"
import { Grid } from "@mui/material"
import clsx from "clsx"
import { LegendClasses, LegendSpacingOptions } from "features/common/models/legend"
import { getDigitizeLayers, getDigitizeLayersVisibilityMapSelector } from "selectors/digitizeSelectors"
import { getLayerGroups, getLayerStylesMap, getLayerVisibilityMap } from "selectors/layerSelector"
import { getFilters } from "selectors/mapSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { digitizeLayerToPartialAppLayer, digitizeLayerToStyle } from "utils/digitizeUtils"
import LegendEntry from "./LegendEntry"

type Props = {
    classes: LegendClasses
    spacingOptions: LegendSpacingOptions
}

const LegendEntries = ({ classes, spacingOptions }: Props) => {
    const digitizeLayers = useAppSelector(getDigitizeLayers)
    const layerGroups = useAppSelector(getLayerGroups)
    const stylesMap = useAppSelector(getLayerStylesMap)
    const layerVisibilityMap = useAppSelector(getLayerVisibilityMap)
    const digitizeLayersVisibilityMap = useAppSelector(getDigitizeLayersVisibilityMapSelector)
    const filters = useAppSelector(getFilters)

    const normalLegendEntries = useMemo(() => {
        const renderedLegendEntries: ReactNode[] = []
        layerGroups.forLayersRecursive(lay => {
            if (layerVisibilityMap[lay.resourceId] && lay.isShown) {
                if (!lay.geometryType) return

                const layerStyles = stylesMap[lay.resourceId]

                const dprStyle = layerStyles.find(style => style.type === "box-chart" || style.type === "pie-chart")

                if (dprStyle) {
                    renderedLegendEntries.push(
                        <LegendEntry
                            filters={filters[lay.resourceId]}
                            layer={lay}
                            spacingOptions={spacingOptions}
                            styleClasses={classes}
                            styles={[dprStyle]}
                        />,
                    )
                }

                const otherStyles = layerStyles.filter(({ styleId }) => styleId !== dprStyle?.styleId)

                if (otherStyles.length) {
                    renderedLegendEntries.push(
                        <LegendEntry
                            filters={filters[lay.resourceId]}
                            layer={lay}
                            spacingOptions={spacingOptions}
                            styleClasses={classes}
                            styles={otherStyles}
                        />,
                    )
                }
            }
        })

        return renderedLegendEntries
    }, [layerGroups, stylesMap, layerVisibilityMap, classes, spacingOptions])

    const digitizeLegendEntries = useMemo(() => {
        const renderedLegendEntries: ReactNode[] = []
        digitizeLayers.forEach((lay: any) => {
            if (digitizeLayersVisibilityMap[lay.id]) {
                lay.geometryType &&
                    renderedLegendEntries.push(
                        <LegendEntry
                            layer={digitizeLayerToPartialAppLayer(lay)}
                            spacingOptions={spacingOptions}
                            styleClasses={classes}
                            styles={[digitizeLayerToStyle(lay, lay.styleCount)]}
                        />,
                    )
            }
        })
        return renderedLegendEntries
    }, [digitizeLayersVisibilityMap, digitizeLayers])

    return (
        <Grid className={clsx(classes.labelsContainer)} container>
            {[...normalLegendEntries, ...digitizeLegendEntries]}
        </Grid>
    )
}

export default LegendEntries
