import React from "react"
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined"
import GradientOutlinedIcon from "@mui/icons-material/GradientOutlined"
import ZoomIcon from "@mui/icons-material/LineStyle"
import LockResetSharpIcon from "@mui/icons-material/LockResetSharp"
import RefreshIcon from "@mui/icons-material/Refresh"
import { Button, IconButton, Tooltip } from "@mui/material"
import { getIsLayerTypeRaster } from "@windgis/shared"
import { PropertyStyleTypes } from "../../constants/propertyStyleTypes"
import { useStylerContext } from "../../hooks/useStylerContext"
import { isColorProperty } from "../../utils/helpers/isColorProperty"
import { isExpressionProperty } from "../../utils/helpers/isExpressionProperty"
import { withConfirm } from "../../utils/withConfirm"
import ExpressionButtonGuard from "./utils/ExpressionButtonGuard"

const StylePropertyOptions = ({
    layerType,
    property,
    onCategorizeClick,
    onGraduateClick,
    onResetClick,
    onZoomDependentClick,
}) => {
    const { sharedColumnName, sharedExpressionType, resetSharedExpression } = useStylerContext()

    //We dont want to show the experssion actions for these
    if (
        getIsLayerTypeRaster(layerType) ||
        property.propertyType === "color-array" ||
        property.propertyType === "column"
    )
        return null

    const shouldRenderSharedExpressionReset =
        isColorProperty(property) && sharedExpressionType !== PropertyStyleTypes.None

    const resetIsOnlyOption = shouldRenderSharedExpressionReset && isExpressionProperty(property)

    const onSharedExpressionReset = () => {
        withConfirm(
            "Are you sure you want to reset the shared expression? This action will revert all of the synced properties to their default value",
            resetSharedExpression,
        )
    }

    return (
        <div className="property-title">
            <div className="actions">
                <ExpressionButtonGuard expressionType={PropertyStyleTypes.Graduate} property={property}>
                    <Tooltip title="Graduate">
                        <IconButton size="medium" onClick={onGraduateClick}>
                            <GradientOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </ExpressionButtonGuard>
                <ExpressionButtonGuard expressionType={PropertyStyleTypes.Categorize} property={property}>
                    <Tooltip title="Categorise">
                        <IconButton size="medium" onClick={onCategorizeClick}>
                            <CategoryOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </ExpressionButtonGuard>
                <ExpressionButtonGuard expressionType={PropertyStyleTypes.ZoomDependent} property={property}>
                    <Tooltip title="Zoom dependent">
                        <IconButton size="medium" onClick={onZoomDependentClick}>
                            <ZoomIcon />
                        </IconButton>
                    </Tooltip>
                </ExpressionButtonGuard>
                {!resetIsOnlyOption && <div className="grow" />}
                <Tooltip size="medium" title="Reset property value">
                    <IconButton size="large" onClick={() => onResetClick(property)}>
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                {resetIsOnlyOption && <div className="grow" />}

                {!sharedColumnName && shouldRenderSharedExpressionReset && (
                    <Tooltip size="medium" title="Reset the shared expression">
                        <IconButton size="large" onClick={onSharedExpressionReset}>
                            <LockResetSharpIcon />
                        </IconButton>
                    </Tooltip>
                )}
                {sharedColumnName && shouldRenderSharedExpressionReset && (
                    <Tooltip title="Reset the shared expression">
                        <Button
                            size="small"
                            variant="contained"
                            startIcon={<LockResetSharpIcon style={{ color: "white" }} />}
                            onClick={onSharedExpressionReset}
                        >
                            {sharedColumnName}
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    )
}

export default StylePropertyOptions
