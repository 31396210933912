import { Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles<Theme>({
    metadataContainer: {
        height: 400,
        maxWidth: 450,
        minWidth: 450,
        overflow: "auto",
        padding: "16px",
    },
})

export default useStyles
