import { FC } from "react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { MenuItem, Tooltip } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import toastr from "utils/customToastr"
import packageJson from "../../../../../package.json"
import { MENU_ITEM_HEIGHT } from "../UserMenu"
import { useStyles } from "./styles"

const Version: FC = () => {
    const classes = useStyles()

    const onClickCopy = () => {
        navigator.clipboard.writeText(packageJson.version)
        toastr.info("Version number copied to clipboard")
    }

    const versions = packageJson.version.split(".")

    return (
        <MenuItem divider sx={{ height: MENU_ITEM_HEIGHT }} onClick={onClickCopy}>
            <Tooltip title="Copy version number to clipboard">
                <div className={classes.version}>
                    <span>
                        <CustomTypography
                            color="inherit"
                            sx={{ marginRight: 0.25 }}
                            textWeight="bold"
                            variant="caption"
                        >
                            v{versions[0]}.{versions[1]}
                        </CustomTypography>
                        <CustomTypography color="inherit" variant="caption">
                            ({versions[2]})
                        </CustomTypography>
                    </span>
                    <ContentCopyIcon fontSize="small" />
                </div>
            </Tooltip>
        </MenuItem>
    )
}

export default Version
