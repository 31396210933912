import React from "react"
import { PropertyStyleTypes } from "../../../constants/propertyStyleTypes.js"
import { useStylerContext } from "../../../hooks/useStylerContext.js"
import { isColorProperty } from "../../../utils/helpers/isColorProperty.js"
import { nonInterpolableProperties } from "../../../utils/nonInterpolableProperties.js"

// this was defined just so that we can move some logic from the StylePropertyOptions component
// it's very tightly coupled and it isn't meant to be really reusable
const ExpressionButtonGuard = ({ children, expressionType, property }) => {
    const { sharedExpressionType } = useStylerContext()

    const isColor = isColorProperty(property)

    const propertyExpressionType = property.expressionType

    const expressionInitialized = sharedExpressionType !== PropertyStyleTypes.None

    const finalChecks = () => {
        switch (expressionType) {
            case PropertyStyleTypes.Graduate:
                return isColor && children
            case PropertyStyleTypes.ZoomDependent:
                return !nonInterpolableProperties[property.name] && children
            default:
                return children
        }
    }

    switch (true) {
        // For these we potentially return all the options
        case !isColor:
        case !expressionInitialized:
            return finalChecks()
        case propertyExpressionType !== "none": // Property is already synced with the shared expression
        case expressionType !== sharedExpressionType: // To block other options when it's not synced
            return null
        default:
            return finalChecks()
    }
}

export default ExpressionButtonGuard
