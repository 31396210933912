import { useEffect, useMemo, useState } from "react"
import { Box } from "@mui/material"
import { useDispatch } from "react-redux"
import { capitalizeFirstLetter } from "@windgis/shared"
import { getPublicRasterPixelValue, getRasterPixelValue } from "actions/raster"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import AisArchiveFeature from "components/map/infoBoxes/AisArchiveFeature/AisArchiveFeature"
import RasterFeature from "components/map/infoBoxes/RasterFeature/RasterFeature"
import VectorFeature from "components/map/infoBoxes/VectorFeature/VectorFeature"
import { POSITION_RECEIVED_PROPERTY, getRenamedPropertyKey } from "components/sidebar/ais/utils"
import useComponentCancelToken from "utils/customHooks/useComponentCancelToken"
import toastr from "utils/customToastr"
import * as Utils from "utils/infoBoxUtils"
import {
    CollapsedExpandLess,
    ExpandedExpandLess,
    StyledCollapseAllButton,
    StyledFeaturesBox,
    StyledRootStack,
} from "./DefaultInfobox.styles"

const DefaultInfobox = props => {
    const [loading, setLoading] = useState(true)
    const [rasters, setRasters] = useState([])
    const isPublic = useGetIsPublicApp()
    const dispatch = useDispatch()
    const cancelToken = useComponentCancelToken()
    const [rasterFeatureCollapsedState, setRasterFeatureCollapsedState] = useState([])
    const [aisArchiveFeatureCollapsedState, setAisArchiveFeatureCollapsedState] = useState(
        props.archiveBoats.map((_, index) => Boolean(index)),
    )
    const [vectorFeatureCollapsedState, setVectorFeatureCollapsedState] = useState(
        aisArchiveFeatureCollapsedState.length === 0
            ? props.features.map((_, index) => Boolean(index))
            : new Array(props.features.length).fill(true),
    )
    const areAllFeaturesCollapsed = useMemo(
        () =>
            vectorFeatureCollapsedState.every(collapsed => collapsed) &&
            rasterFeatureCollapsedState.every(collapsed => collapsed) &&
            aisArchiveFeatureCollapsedState.every(collapsed => collapsed),
        [vectorFeatureCollapsedState, rasterFeatureCollapsedState, aisArchiveFeatureCollapsedState],
    )
    const [hasMultipleFeatures, setHasMultipleFeatures] = useState(false)

    const featureProperties = feature => Object.entries(feature.properties).map(([key, value]) => ({ key, value }))
    const aisArchiveFeatureProperties = index =>
        Object.entries(props.archiveBoats[index])
            .filter(([key, value]) => Utils.isValidProperty([key, value]) && !Utils.excludedProperties.includes(key))
            .map(([key, value]) => ({
                key: Utils.generatePropertyName(key),
                value: Utils.getRenderedPropertyValue(value),
            }))

    useEffect(() => {
        const promises = []
        const lng = props.lngLat.lng
        const lat = props.lngLat.lat

        props.rasters.forEach(raster => {
            if (isPublic)
                promises.push(dispatch(getPublicRasterPixelValue(raster.sourceId, lng, lat, cancelToken.token)))
            else promises.push(dispatch(getRasterPixelValue(raster.sourceId, lng, lat, cancelToken.token)))
        })

        Promise.allSettled(promises).then(responses => {
            const newRasters = responses.map((response, index) => {
                return { ...props.rasters[index], lat, lng, value: response.value?.data || null }
            })

            setRasters(newRasters)
            setLoading(false)

            setRasterFeatureCollapsedState(
                vectorFeatureCollapsedState.length === 0 && aisArchiveFeatureCollapsedState.length === 0
                    ? newRasters.map((_, index) => Boolean(index))
                    : new Array(newRasters.length).fill(true),
            )

            setHasMultipleFeatures(props.features.length + props.archiveBoats.length + newRasters.length > 1)
        })
        return () => {
            cancelToken.cancel("Canceled")
        }
    }, [])

    const formatDate = dateValue => {
        const date = new Date(dateValue)
        const dd = date.getDate()
        const mm = date.getMonth() + 1
        const yyyy = date.getFullYear()
        return `${dd}/${mm}-${yyyy}`
    }

    const copyPropertiesToClipboard = (header, properties) => {
        let copyText = `${header}\n`
        copyText += properties
            .map((propKeyValue, _) => {
                let { key, value } = propKeyValue

                if (Utils.excludedProperties.includes(key) || key.includes("link") || key.includes("foto")) return

                value = key.includes("dato") ? formatDate(value) : value

                if (key.includes(POSITION_RECEIVED_PROPERTY)) {
                    key = getRenamedPropertyKey(POSITION_RECEIVED_PROPERTY)
                }

                return `${capitalizeFirstLetter(key)}: ${value}`
            })
            .join("\n")
        navigator.clipboard.writeText(copyText)
        toastr.success("The text has been copied")
    }

    const onCopyClick = index => {
        const feature = props.features[index]
        const header = `${props.sourceToNameMapping[feature.sourceLayer ?? feature.source]}`

        copyPropertiesToClipboard(header, featureProperties(feature))
    }

    const onAisArchiveFeatureCopyClick = index => {
        const header = `${props.sourceToNameMapping[props.subscriptionId]} - Archive`

        copyPropertiesToClipboard(header, aisArchiveFeatureProperties(index))
    }

    const toggleAllFeatures = () => {
        setVectorFeatureCollapsedState(vectorFeatureCollapsedState.map(_ => !areAllFeaturesCollapsed))
        setRasterFeatureCollapsedState(rasterFeatureCollapsedState.map(_ => !areAllFeaturesCollapsed))
        setAisArchiveFeatureCollapsedState(aisArchiveFeatureCollapsedState.map(_ => !areAllFeaturesCollapsed))
    }

    const toggleVectorFeature = index => {
        const updatedCollapsed = [...vectorFeatureCollapsedState]
        updatedCollapsed[index] = !vectorFeatureCollapsedState[index]
        setVectorFeatureCollapsedState(updatedCollapsed)
    }

    const toggleRasterFeature = index => {
        const updatedCollapsed = [...rasterFeatureCollapsedState]
        updatedCollapsed[index] = !rasterFeatureCollapsedState[index]
        setRasterFeatureCollapsedState(updatedCollapsed)
    }

    const toggleAisArchiveFeature = index => {
        const updatedCollapsed = [...aisArchiveFeatureCollapsedState]
        updatedCollapsed[index] = !aisArchiveFeatureCollapsedState[index]
        setAisArchiveFeatureCollapsedState(updatedCollapsed)
    }

    const getIsVectorFeatureCollapsed = index => vectorFeatureCollapsedState[index]

    const getIsRasterFeatureCollapsed = index => rasterFeatureCollapsedState[index]

    const getIsAisArchiveFeatureCollapsed = index => aisArchiveFeatureCollapsedState[index]

    return (
        <StyledRootStack>
            <Box color={theme => theme.customColors.primaryColor} height={38} position="absolute" top={0}>
                {hasMultipleFeatures ? (
                    <StyledCollapseAllButton size="large" onClick={() => toggleAllFeatures()}>
                        {areAllFeaturesCollapsed ? <CollapsedExpandLess /> : <ExpandedExpandLess />}

                        <span>{areAllFeaturesCollapsed ? "Expand All Layers" : "Collapse All Layers"}</span>
                    </StyledCollapseAllButton>
                ) : null}
            </Box>

            <StyledFeaturesBox>
                <LoadingPlaceholder loading={loading} message="Loading information">
                    {props.archiveBoats.map((archiveBoat, index) => (
                        <AisArchiveFeature
                            key={index}
                            boatDetails={archiveBoat}
                            collapsed={getIsAisArchiveFeatureCollapsed(index)}
                            index={index}
                            subscriptionName={props.sourceToNameMapping[props.subscriptionId]}
                            hasMultipleFeatures={hasMultipleFeatures}
                            onCopyClick={onAisArchiveFeatureCopyClick}
                            onToggleFeature={toggleAisArchiveFeature}
                        />
                    ))}
                    {props.features.map((feature, featureIndex) => (
                        <VectorFeature
                            key={featureIndex}
                            collapsed={getIsVectorFeatureCollapsed(featureIndex)}
                            feature={feature}
                            featureIndex={featureIndex}
                            sourceToNameMapping={props.sourceToNameMapping}
                            hasMultipleFeatures={hasMultipleFeatures}
                            toggleFeature={toggleVectorFeature}
                            onCopyClick={onCopyClick}
                        />
                    ))}
                    {rasters.map((raster, index) => (
                        <RasterFeature
                            key={index}
                            collapsed={getIsRasterFeatureCollapsed(index)}
                            featureIndex={index}
                            lat={raster.lat}
                            long={raster.lng}
                            name={raster.name}
                            value={raster.value}
                            hasMultipleFeatures={hasMultipleFeatures}
                            toggleFeature={toggleRasterFeature}
                        />
                    ))}
                </LoadingPlaceholder>
            </StyledFeaturesBox>
        </StyledRootStack>
    )
}

export default DefaultInfobox
