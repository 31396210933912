import React, { FC } from "react"
import { useVirtualizer } from "@tanstack/react-virtual"
import { StyledContainerDiv, StyledInnerContainerDiv, StyledVirtualItemsContainerDiv } from "./VirtualizedList.styles"

export type VirtualizedListProps = {
    children: Array<React.ReactNode>
    height: number
    width: number
    enabled?: boolean
}

const VirtualizedList: FC<VirtualizedListProps> = ({
    children = [],
    height,
    width,
    enabled = true,
}: VirtualizedListProps) => {
    const parentRef = React.useRef<HTMLDivElement>(null)
    const virtualizer = useVirtualizer({
        count: children.length,
        estimateSize: () => 45, // this is required for the first render only
        getScrollElement: () => parentRef.current,
        enabled,
    })

    const items = virtualizer.getVirtualItems()

    return (
        <StyledContainerDiv height={height} ref={parentRef} width={width}>
            <StyledInnerContainerDiv height={virtualizer.getTotalSize()}>
                <StyledVirtualItemsContainerDiv startYPosition={items[0]?.start}>
                    {items.map(({ key, index }) => (
                        <div key={key} data-index={index} ref={virtualizer.measureElement}>
                            {children?.[index] || null}
                        </div>
                    ))}
                </StyledVirtualItemsContainerDiv>
            </StyledInnerContainerDiv>
        </StyledContainerDiv>
    )
}

export default VirtualizedList
