import { useCallback, useEffect, useMemo, useState } from "react"
import proj4 from "proj4"
import { useHistory } from "react-router-dom"
import { MapMouseEvent, MapRef } from "@emblautec/react-map-gl"
import { isGroup } from "@emblautec/recursive-array-extensions"
import { availableURLSearchParams, useSearchParams } from "@windgis/shared"
import { mapSubToGeoJsonSource } from "components/sidebar/ais/utils"
import { getToggledWidgetsSet } from "features/mapTools/selectors"
import { setConfig } from "features/mapTools/slice"
import { getAISSubscriptionData, getAISSubscriptionInfo } from "selectors/aisSelectors"
import { getSelectedApp } from "selectors/appsSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { clearMap } from "../actions/globalActions"
import * as printActions from "../actions/print"
import * as sidebarActions from "../actions/sidebarAndDrawer"
import HeaderButtons from "../app/components/HeaderButtons/HeaderButtons"
import Print from "../components/sidebar/print/print"
import SidebarRoot from "../components/sidebar/SidebarRoot/SidebarRoot"
import ToolsMenu from "../components/sidebar/toolsMenu/toolsMenu"
import {
    basemapQueryParamName,
    bearingQueryParamName,
    boundsQueryParamName,
    centerQueryParamName,
    languageQueryParamName,
    latQueryParamName,
    lngQueryParamName,
    pitchQueryParamName,
    sidebarStatusQueryParamName,
    visibleLayersQueryParamName,
    zoomQueryParamName,
} from "../constants/map/queryParams"
import MainMap from "../features/map/components/MainMap/MainMap"
import { Group } from "../model/app/Group"
import * as layerSelectorActions from "../reducers/layerSelector"
import * as mapActions from "../reducers/map"
import { getStyleConfig } from "../selectors/stylesSelectors"
import { useAppSelector } from "../store/hooks/useAppSelector"
import { makeVisibilityProperty } from "../utils/creators/styleProperties/visibilityPropertyCreator"
import { withMap } from "../utils/HOCs/withMap"
import { PortalDiv } from "../utils/portal"

type MapViewProps = {
    mainMap?: MapRef | undefined
    path: string
}

const MapView = ({ mainMap, path }: MapViewProps) => {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const queryParams = useMemo(() => new URLSearchParams(document.location.search), [])
    const [isAppInitialized, setIsAppInitialized] = useState(false)
    const app = useAppSelector(getSelectedApp)
    const styleConfig = useAppSelector(getStyleConfig)
    const toggledWidgetsSet = useAppSelector(getToggledWidgetsSet)
    const selectedApp = useAppSelector(state => state.apps.selectedApp)
    const appId = selectedApp?.id ?? ""

    const AISSubscriptionsData = useAppSelector(getAISSubscriptionData)
    const AISSubscriptionsInfo = useAppSelector(getAISSubscriptionInfo)

    const { getSearchParamValue } = useSearchParams()
    const lat = Number.parseFloat(getSearchParamValue(availableURLSearchParams.lat.key) ?? "0")
    const lng = Number.parseFloat(getSearchParamValue(availableURLSearchParams.lng.key) ?? "0")

    useEffect(() => {
        if (mainMap) {
            setIsAppInitialized(false)
            dispatch(clearMap())
            dispatch(printActions.resetMapFeatures())
            initApp()
        }
    }, [appId, mainMap])

    useEffect(() => {
        mainMap?.once("idle", event => {
            if (!event?.target) return

            if (lat && lng) {
                event?.target?.flyTo({
                    animate: false,
                    center: [lng, lat],
                    zoom: 7,
                })

                event?.target?.fire("click", {
                    lngLat: event?.target?.getCenter(),
                    point: event.target?.project(event.target?.getCenter()),
                } as MapMouseEvent)
            }
        })
    }, [mainMap, isAppInitialized])

    const isUpdated = useCallback((resourceId: string, modifiedDate: Date) => {
        const savedDate = new Date(localStorage.getItem(resourceId) as string)
        return modifiedDate > savedDate
    }, [])

    const checkCache = useCallback(
        (app: any) => {
            const maps = app.maps
            const modifiedDate = new Date(app.modifiedUtc)

            for (let map of maps) {
                if (isUpdated(map.id, modifiedDate)) {
                    caches.delete(map.id)
                    localStorage.setItem(map.id, app.modifiedUtc)
                }
            }
        },
        [isUpdated],
    )

    const initMapBounds = useCallback(
        (appMapBounds: any) => {
            const location = getMapLocationFromQueryParam()
            if (location) {
                mainMap?.flyTo({
                    animate: false,
                    center: [location.lon, location.lat],
                    zoom: 7,
                })
                return
            }

            const mapBounds = getMapBoundsFromQueryParam() || appMapBounds
            mainMap?.fitBounds(mapBounds, { animate: false, padding: 45, ...get3DInfoFromQueryParam() })
        },
        [mainMap],
    )

    const initMapLayout = useCallback(() => {
        const { bearing, pitch } = get3DInfoFromQueryParam()
        mainMap?.setPitch(pitch ?? mainMap?.getPitch())
        mainMap?.setBearing(bearing ?? mainMap?.getBearing())

        const zoom = parseFloat(queryParams.get(zoomQueryParamName) ?? "0")
        mainMap?.setZoom(zoom ?? mainMap.getZoom())

        const center = queryParams.get(centerQueryParamName)
        mainMap?.setCenter((center && JSON.parse(center)) ?? mainMap.getCenter())
    }, [mainMap, queryParams])

    const getInitialBasemap = useCallback(
        (basemaps: any) => {
            const basemapQueryParam = queryParams.get(basemapQueryParamName)

            if (basemapQueryParam === "None") {
                return { type: "none" }
            }

            if (basemapQueryParam) {
                return basemaps.find((basemap: any) => basemap.title === basemapQueryParam)
            }
            return basemaps[0]
        },
        [queryParams],
    )

    const getInitialLanguage = useCallback(
        (languages: any) => {
            const languageQueryParam = queryParams.get(languageQueryParamName)

            const paramLanguage = languages?.find((x: any) => x.code === languageQueryParam)
            if (paramLanguage) return paramLanguage.code

            const appDefaultLanguage = languages?.find((x: any) => x.default)
            if (appDefaultLanguage) return appDefaultLanguage.code

            return "en"
        },
        [queryParams],
    )

    const initApp = useCallback(() => {
        const app = selectedApp

        checkCache(app)

        addProjections(app.configJson.projections)

        initMapLayout()

        initMapBounds(app.mapBounds)

        addSources(app.maps, app.rasters)

        loadStyles(app)

        dispatch(mapActions.setRestrictedView(app.restrictedView))

        dispatch(sidebarActions.setSidebarOpen(getSidebarStatusFromQueryParam(app)))

        dispatch(
            setConfig({
                basemaps: app.basemaps || [],
                languages: app.languages,
                projections: app.configJson.projections,
            }),
        )

        const basemap = getInitialBasemap(app.basemaps)
        const language = getInitialLanguage(app.languages)

        dispatch(
            mapActions.initMapSettings({
                basemap,
                language,
            }),
        )

        setIsAppInitialized(true)
    }, [
        appId,
        checkCache,
        dispatch,
        selectedApp,
        getInitialBasemap,
        getInitialLanguage,
        history,
        initMapBounds,
        initMapLayout,
        isAppInitialized,
        path,
    ])

    const get3DInfoFromQueryParam = useCallback(() => {
        const pitch = parseInt(queryParams.get(pitchQueryParamName) ?? "")
        const bearing = parseInt(queryParams.get(bearingQueryParamName) ?? "")

        return {
            ...(!!pitch && { pitch }),
            ...(!!bearing && { bearing }),
        }
    }, [queryParams])

    const getMapBoundsFromQueryParam = useCallback(() => {
        const bounds = queryParams.get(boundsQueryParamName)
        return bounds && JSON.parse(bounds)
    }, [queryParams])

    const getMapLocationFromQueryParam = useCallback(() => {
        const lat = parseFloat(queryParams.get(latQueryParamName) || "0")
        const lng = parseFloat(queryParams.get(lngQueryParamName) || "0")

        if (!!lat && !!lng) {
            return {
                lat: lat,
                lon: lng,
            }
        }
        return null
    }, [queryParams])

    const getVisibleLayersFromQueryParam = useCallback(() => {
        const visibleLayersCsv = queryParams.get(visibleLayersQueryParamName)
        if (!visibleLayersCsv) return {}

        return visibleLayersCsv.split(",").reduce((map: Record<string, any>, obj: string) => {
            map[obj] = true
            return map
        }, {})
    }, [queryParams])

    const getSidebarStatusFromQueryParam = useCallback(
        (app: any) => {
            const sidebarStatus = queryParams.get(sidebarStatusQueryParamName)
            return sidebarStatus ? JSON.parse(sidebarStatus) : !app.sidebarCollapsed
        },
        [queryParams],
    )

    const loadStyles = useCallback(
        (app: any) => {
            let layers: Group[] = []
            let paintsMap: Record<string, any> = {}
            let layoutsMap: Record<string, any> = {}
            const zoomRangesMap: Record<string, any> = {}
            let visibleLayersIdsMap: Record<string, any> = getVisibleLayersFromQueryParam()

            const datasetsMap = app.maps.reduce((acc: Record<string, any>, map: any) => {
                map.datasets.forEach((dataset: any) => {
                    acc[dataset.id] = dataset
                })
                return acc
            }, {})

            let layerGroups: Group[] = app.configJson.layerGroups

            if (queryParams.has(visibleLayersQueryParamName)) {
                layerGroups.forGroupsRecursive(group => {
                    const hasVisibleLayer = group.layers.some(
                        layer => visibleLayersIdsMap[layer.resourceId] && !isGroup(layer),
                    )

                    group.options.collapsed = !hasVisibleLayer
                })
            }

            const layerVisibilityMap: Record<string, any> = {}
            const layerStylesMap: Record<string, any> = {}

            const currentZoomLevel = mainMap?.getZoom() || 1

            layerGroups.forLayersRecursive(layer => {
                layerVisibilityMap[layer.resourceId] = queryParams.has(visibleLayersQueryParamName)
                    ? !!visibleLayersIdsMap[layer.resourceId]
                    : layer.options.enabled

                layer.rowCount = datasetsMap[layer.resourceId]?.rowCount || 0

                let styleMinZoom = 24,
                    styleMaxZoom = 0

                layer.styles.forEach((style: any) => {
                    const layerId: string = style.styleId
                    if (style.minZoom < styleMinZoom) styleMinZoom = style.minZoom
                    if (style.maxZoom > styleMaxZoom) styleMaxZoom = style.maxZoom
                    layers.push({
                        // @ts-ignore
                        layerId,
                        resourceId: layer.resourceId,
                        sourceId: layer.sourceId,
                        sourceName: layer.sourceName,
                        type: style.type,
                    })
                    const zoomRange = { layerId, maxZoom: style.maxZoom, minZoom: style.minZoom }

                    zoomRangesMap[layerId] = zoomRange

                    const properties = JSON.parse(JSON.stringify(styleConfig[style.type]))
                    properties.forEach((property: any) => {
                        let styleProperty = style.properties.find((x: any) => x.name === property.name)
                        if (styleProperty) {
                            property.value = styleProperty.value
                            property.expressionType = styleProperty.expressionType || "none"
                        }
                    })

                    paintsMap[layerId] = {
                        layerId,
                        properties: properties.filter((x: any) => x.type === "paint"),
                    }

                    layoutsMap[layerId] = {
                        datasetId: layer.resourceId,
                        layerId,
                        properties: [
                            ...properties.filter((x: any) => x.type === "layout"),
                            makeVisibilityProperty(layerVisibilityMap[layer.resourceId]),
                        ],
                    }
                })
                layerStylesMap[layer.resourceId] = layer.styles
                delete layer.styles
                layer.minZoom = styleMinZoom
                layer.maxZoom = styleMaxZoom

                layer.isShown =
                    Math.floor(styleMinZoom) <= Math.floor(currentZoomLevel) &&
                    Math.floor(currentZoomLevel) <= Math.floor(styleMaxZoom)
            })

            dispatch(layerSelectorActions.setLayerData({ layerGroups, layerStylesMap, layerVisibilityMap }))
            dispatch(mapActions.initMapResources({ layers, layoutsMap, paintsMap, zoomRangesMap }))
        },
        [dispatch, getVisibleLayersFromQueryParam, mainMap, queryParams, styleConfig],
    )

    const addProjections = useCallback((projections: any[]) => {
        proj4.defs(projections.map(projection => [projection.crs, projection.value]))
    }, [])

    const addSources = useCallback(
        (maps: any[], rasters: any[]) => {
            const sources: any[] = []

            maps.forEach(map => {
                let shouldReset = localStorage.getItem("cache_reset" + map.id) === null
                if (shouldReset) {
                    caches.delete(map.id)
                    localStorage.setItem("cache_reset" + map.id, "reset")
                }
                sources.push({
                    bounds: polygonToBounds(map.bounds),
                    id: map.id,
                    maxZoom: map.maxZoom,
                    minZoom: map.minZoom,
                    type: "vector",
                })

                map.datasets.forEach((dataset: any) => {
                    if (!sources.some(s => s.id === dataset.id)) {
                        sources.push({
                            bounds: polygonToBounds(dataset.bounds),
                            id: dataset.id,
                            maxZoom: dataset.maxZoom,
                            minZoom: dataset.minZoom,
                            type: "vector",
                        })
                    }
                })
            })

            rasters.forEach(raster => {
                let shouldReset = localStorage.getItem("cache_reset" + raster.id) === null
                if (shouldReset) {
                    caches.delete(raster.id)
                    localStorage.setItem("cache_reset" + raster.id, "reset")
                }
                sources.push({
                    bounds: polygonToBounds(raster.bounds),
                    id: raster.id,
                    maxZoom: raster.maxZoom,
                    minZoom: raster.minZoom,
                    type: "raster",
                })
            })

            AISSubscriptionsInfo?.forEach(sub => {
                const source = mapSubToGeoJsonSource(sub.id, [])
                sources.push(source)
            })
            dispatch(mapActions.addSources(sources))
        },
        [AISSubscriptionsData, AISSubscriptionsInfo, dispatch],
    )

    const polygonToBounds = useCallback((poly: { coordinates: number[][][] }) => {
        const minLng = poly.coordinates[0][1][0]
        const minLat = poly.coordinates[0][1][1]

        const maxLng = poly.coordinates[0][3][0]
        const maxLat = poly.coordinates[0][3][1]

        return [minLng, maxLat, maxLng, minLat]
    }, [])

    const printEnabled = toggledWidgetsSet.has("print")

    return (
        <div className="main-grid main-view" id="grid">
            <div className="flex-container">
                {!app?.public && <HeaderButtons className="map-header-buttons" userMenuIsCollapsed />}

                <MainMap location={getMapLocationFromQueryParam()} />

                {printEnabled && <Print />}

                <PortalDiv />
            </div>

            <ToolsMenu />

            <SidebarRoot path={path} />
        </div>
    )
}

export default withMap(MapView)
