import { useContext } from "react"
import { useMsal } from "@azure/msal-react"
import { AuthenticationTokenProviderContext } from "../providers/AuthenticationTokenProvider/AuthenticationTokenProvider"

const useAuthentication = () => {
    const { accessToken } = useContext(AuthenticationTokenProviderContext)

    const { instance } = useMsal()
    const activeAccount = instance.getActiveAccount()

    const getUserEmail = () => (activeAccount?.idTokenClaims?.["email"] as string) ?? ""

    return {
        accessToken,
        getUserEmail,
        logoutRedirect: (): void => {
            instance.logoutRedirect()
        },
    }
}

export default useAuthentication
