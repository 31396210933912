import { useEffect, useRef, useState } from "react"
import { AspectRatio, AspectRatioTwoTone, FileCopyOutlined } from "@mui/icons-material"
import GpsFixedIcon from "@mui/icons-material/GpsFixed"
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material"
import proj4 from "proj4"
import { useSelector } from "react-redux"
import { useMap } from "@emblautec/react-map-gl"
import { DEFAULT_PROJECTION } from "features/mapTools/constants"
import { getProjections } from "features/mapTools/selectors"
import { setSelectedProjection } from "features/mapTools/slice"
import { Projection } from "model/app/Projection"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import toastr from "utils/customToastr"
import { useCoordinatesWidgetStyles } from "./styles"

const CoordinatesWidget = () => {
    const classes = useCoordinatesWidgetStyles()
    const projections = useSelector(getProjections)
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [pointerCoordinates, setPointerCoordinates] = useState<{ lat: number; lng: number } | undefined>()
    const [capturingMapCoordinates, setCapturingMapCoordinates] = useState(false)
    const projectionRef = useRef(DEFAULT_PROJECTION)
    const dispatch = useAppDispatch()
    const { current } = useMap()

    useEffect(() => {
        // TODO: fix this
        // @ts-ignore
        const updateCursorCoordinates = (e: mapboxgl.EventData & mapboxgl.MapMouseEvent) => {
            const coordinates = proj4(projectionRef.current.value, [e.lngLat.lng, e.lngLat.lat])
            setPointerCoordinates({ lat: coordinates[1], lng: coordinates[0] })
        }

        if (capturingMapCoordinates) {
            current?.off("mousemove", updateCursorCoordinates)
            current?.on("click", updateCursorCoordinates)
        } else {
            current?.off("click", updateCursorCoordinates)
            current?.on("mousemove", updateCursorCoordinates)
        }

        return () => {
            current?.off("mousemove", updateCursorCoordinates)
            current?.off("click", updateCursorCoordinates)
        }
    }, [capturingMapCoordinates])

    const copyCoordinatesToClipboard = () => {
        if (!pointerCoordinates) return

        const coordinatesToBeCopied = `${pointerCoordinates.lng.toFixed(7)},${pointerCoordinates.lat.toFixed(7)}`
        navigator.clipboard.writeText(coordinatesToBeCopied)
        toastr.success("Coordinates copied to clipboard.")
    }

    const onProjectionChanged = (projection: Projection) => {
        projectionRef.current = projection
        dispatch(setSelectedProjection(projection))
        setAnchorEl(null)
    }

    return (
        <div className={classes.root}>
            <div className={classes.coordinatesText}>X : {pointerCoordinates?.lng.toFixed(7) ?? "N/A"}</div>
            <div className={classes.coordinatesText}>Y : {pointerCoordinates?.lat.toFixed(7) ?? "N/A"}</div>
            <Tooltip title="CRS">
                <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
                    <GpsFixedIcon className={classes.iconStyle} />
                </IconButton>
            </Tooltip>
            <Tooltip title="Capture map coordinates of clicked point">
                <IconButton size="small" onClick={() => setCapturingMapCoordinates(!capturingMapCoordinates)}>
                    {capturingMapCoordinates ? (
                        <AspectRatioTwoTone className={classes.iconStyle} />
                    ) : (
                        <AspectRatio className={classes.iconStyle} />
                    )}
                </IconButton>
            </Tooltip>
            <Tooltip title={capturingMapCoordinates ? "Copy coordinates" : "Capture map coordinates before copy"}>
                <div>
                    <IconButton
                        className={classes.copyButton}
                        size="small"
                        disabled={!capturingMapCoordinates}
                        onClick={copyCoordinatesToClipboard}
                    >
                        <FileCopyOutlined className={classes.iconStyle} />
                    </IconButton>
                </div>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="simple-menu"
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {projections.map((item, index) => {
                    return (
                        <MenuItem
                            key={index}
                            className={item.name === projectionRef.current.name ? classes.selected : ""}
                            onClick={() => onProjectionChanged(item)}
                        >
                            <span className={classes.crsBox}>{item.crs}</span>
                            <div className={classes.crsName}> {item.name}</div>
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    )
}

export default CoordinatesWidget
