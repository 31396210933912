import { useEffect } from "react"
import OpenPanel from "@mui/icons-material/NavigateNext"
import { Box, CircularProgress, Typography } from "@mui/material"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import * as sidebarAndDrawerActions from "actions/sidebarAndDrawer"
import Logo from "app/components/Logo/Logo"
import { hideLogoQueryParamName } from "constants/map/queryParams"
import { AIS_PATH_SUFFIX, SETTINGS_PATH_SUFFIX } from "constants/routes"
import toolsDict, { layerSelectorTool } from "constants/tools"
import { AisRoute } from "features/ais"
import { useFetchIsAISPublicQuery } from "features/ais/api"
import { getTools } from "features/mapTools/selectors"
import Settings from "features/settings/Settings"
import { useGetColorsArrayQuery } from "features/utils/api"
import { setAreAISPlaybackBoatsOnMap, setSelectedArchiveSubscriptionId } from "reducers/ais"
import * as layerSelectorActions from "reducers/layerSelector"
import { getSelectedArchiveSubscriptionId } from "selectors/aisSelectors"
import { getSelectedApp } from "selectors/appsSelectors"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { getStylerLayerId } from "selectors/layerSelector"
import { getIsSidebarOpen } from "selectors/sidebarAndDrawer"
import { useMainDraw } from "utils/customHooks/map/useMainDraw"
import AppsPicker from "../AppsPicker/AppsPicker"
import LayerEditor from "../styler/layerEditor"
import { useStyles } from "./styles"

const SidebarRoot = ({ path }) => {
    const classes = useStyles()

    const sidebarState = useSelector(getIsSidebarOpen)
    const tools = useSelector(getTools)
    const featureFlags = useSelector(getFeatureFlags)
    const stylerLayerId = useSelector(getStylerLayerId)
    const currentApp = useSelector(getSelectedApp)
    const selectedArchiveSubscriptionId = useSelector(getSelectedArchiveSubscriptionId)

    const dispatch = useDispatch()

    const { data: colorsArray = [] } = useGetColorsArrayQuery()

    const { pathname, search } = useLocation()
    const searchParams = new URLSearchParams(search)
    const hideLogo = searchParams.get(hideLogoQueryParamName)

    const draw = useMainDraw()

    const { data: { isPublic: isAisPublic } = { isPublic: false } } = useFetchIsAISPublicQuery()

    useEffect(() => {
        dispatch(layerSelectorActions.setStylerLayerId(null))

        draw?.deleteAll()
    }, [currentApp])

    const aisTool = tools.find(x => x.name === "ais")

    useEffect(() => {
        if (!!aisTool && !!selectedArchiveSubscriptionId && !pathname.includes("ais")) {
            dispatch(setSelectedArchiveSubscriptionId(null))
            dispatch(setAreAISPlaybackBoatsOnMap(false))
        }
    }, [pathname])

    return (
        <div className="sidebar-wrapper">
            <div className={sidebarState ? "sidebar" : "sidebar hide"}>
                <div className="collapse-button" onClick={() => dispatch(sidebarAndDrawerActions.toggleSidebarOpen())}>
                    <OpenPanel
                        className={`collapse-button__icon ${sidebarState && `rotate180`}`}
                        style={{ fontSize: "1.7rem" }}
                    />
                </div>
                {currentApp?.public && hideLogo ? (
                    <></>
                ) : (
                    <AppBar className={classes.appBar} color="primary" position="static">
                        <Toolbar className={classes.toolbar}>
                            <Logo isPublic={currentApp?.public} />

                            {!currentApp?.public && <AppsPicker appName={currentApp?.name} />}
                        </Toolbar>
                    </AppBar>
                )}
                <div className={classes.root}>
                    {stylerLayerId !== null && <LayerEditor colorsArray={colorsArray} isDigitizeLayer={false} />}
                    {!currentApp?.name && (
                        <Box alignItems="center" display="flex" flexDirection="column" paddingTop={15}>
                            <CircularProgress size={50} />
                            <Typography align="center" variant="h6">
                                Getting your app ready
                            </Typography>
                        </Box>
                    )}
                    {currentApp?.name && (
                        <Switch>
                            <Route
                                component={layerSelectorTool.component}
                                exact
                                path={`${path}/${layerSelectorTool.routeSuffix}`}
                            />
                            {tools.map(tool => {
                                const correspondingTool = toolsDict[tool.name]

                                return correspondingTool ? (
                                    <Route
                                        key={tool.name}
                                        component={correspondingTool.component}
                                        path={`${path}/${correspondingTool.routeSuffix}`}
                                    />
                                ) : null
                            })}
                            {(featureFlags.AIS || isAisPublic) && !!aisTool && (
                                <Route component={AisRoute} path={`${path}/${AIS_PATH_SUFFIX}`} />
                            )}

                            <Route component={Settings} path={`${path}/${SETTINGS_PATH_SUFFIX}`} />

                            <Redirect to={`${path}/${layerSelectorTool.routeSuffix}`} />
                        </Switch>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SidebarRoot
