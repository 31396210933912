import React from "react"
import AddIcon from "@mui/icons-material/AddCircle"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import DownloadIcon from "@mui/icons-material/GetApp"
import PublishIcon from "@mui/icons-material/Publish"
import SaveIcon from "@mui/icons-material/Save"
import {
    Button,
    ButtonProps,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    DialogTitleProps,
    IconButton,
    IconButtonProps,
    Stack,
} from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import { CustomTypography } from "@windgis/shared"

type QADataTestProps = {
    "data-testid"?: string
}

const dialogTypes = {
    add: "add",
    apply: "apply",
    confirm: "confirm",
    create: "create",
    delete: "delete",
    info: "info",
    save: "save",
    upload: "upload",
    start: "start",
    update: "update",
} as const

export type CustomModalProps = {
    closeIconButtonProps?: IconButtonProps & QADataTestProps
    confirmButtonLoading?: boolean
    confirmButtonProps?: ButtonProps & QADataTestProps
    dialogTitle: React.ReactNode
    dialogTitleProps?: DialogTitleProps & QADataTestProps
    dialogType?: keyof typeof dialogTypes
    hideActionContainer?: boolean
    maxWidth?: number | string
    cancelButtonProps?: ButtonProps & QADataTestProps
    disableCancel?: boolean
    disabled?: boolean
    isOpen: boolean
    handleCancel?: () => void
    handleClose: () => void
    onConfirm?: (event: React.MouseEvent) => void
} & Omit<DialogProps, "open">

const confirmButtonText: Record<keyof typeof dialogTypes, string> = {
    [dialogTypes.add]: "Add",
    [dialogTypes.apply]: "Apply",
    [dialogTypes.confirm]: "Confirm",
    [dialogTypes.create]: "Create",
    [dialogTypes.delete]: "Delete",
    [dialogTypes.info]: "Understood",
    [dialogTypes.save]: "Save",
    [dialogTypes.start]: "Start",
    [dialogTypes.update]: "Update",
    [dialogTypes.upload]: "Upload",
}

const confirmButtonIcon: Record<keyof typeof dialogTypes, React.ReactNode> = {
    [dialogTypes.add]: <AddIcon fontSize="small" />,
    [dialogTypes.apply]: <CheckIcon fontSize="small" />,
    [dialogTypes.confirm]: null,
    [dialogTypes.create]: <AddIcon fontSize="small" />,
    [dialogTypes.delete]: <DeleteIcon fontSize="small" />,
    [dialogTypes.info]: null,
    [dialogTypes.save]: <SaveIcon fontSize="small" />,
    [dialogTypes.start]: <DownloadIcon fontSize="small" />,
    [dialogTypes.update]: <EditIcon fontSize="small" />,
    [dialogTypes.upload]: <PublishIcon fontSize="small" />,
}

const hideCancelButton: Record<keyof typeof dialogTypes, boolean> = {
    [dialogTypes.add]: false,
    [dialogTypes.apply]: false,
    [dialogTypes.confirm]: false,
    [dialogTypes.create]: false,
    [dialogTypes.delete]: false,
    [dialogTypes.info]: true,
    [dialogTypes.save]: false,
    [dialogTypes.start]: false,
    [dialogTypes.update]: false,
    [dialogTypes.upload]: false,
}

const CustomModal = ({
    children,
    closeIconButtonProps,
    confirmButtonLoading,
    confirmButtonProps,
    dialogTitle,
    dialogTitleProps,
    dialogType,
    hideActionContainer = false,
    maxWidth,
    PaperProps,
    cancelButtonProps,
    disableCancel = false,
    disabled,
    isOpen: isOpen,
    handleCancel,
    handleClose,
    onConfirm,
    ...dialogProps
}: CustomModalProps) => (
    <Dialog
        {...dialogProps}
        aria-labelledby="atlas-dialog create-group-modal"
        closeAfterTransition
        maxWidth={maxWidth ?? "sm"}
        open={isOpen}
        PaperProps={{ ...PaperProps, "data-testid": modalTestId }}
        slotProps={{ backdrop: { timeout: 500 } }}
        slots={{ backdrop: Backdrop }}
        onClose={handleClose}
    >
        {/* Modal title */}
        <DialogTitle>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
                <CustomTypography textWeight="bold" variant="h2" {...dialogTitleProps}>
                    {dialogTitle}
                </CustomTypography>

                <IconButton data-testid={closeButtonTestId} onClick={handleClose} {...closeIconButtonProps}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>

        {/* Modal content */}
        <DialogContent dividers>{children}</DialogContent>

        {/* Modal action buttons */}
        {!hideActionContainer && (
            <DialogActions>
                <>
                    {/* Confirmation button */}
                    {dialogType ? (
                        <Button
                            color="primary"
                            data-testid={confirmationButtonTestId}
                            variant="contained"
                            disabled={disabled || confirmButtonLoading}
                            startIcon={
                                confirmButtonLoading ? <CircularProgress size={14} /> : confirmButtonIcon[dialogType]
                            }
                            onClick={onConfirm}
                            {...confirmButtonProps}
                        >
                            {confirmButtonText[dialogType]}
                        </Button>
                    ) : null}

                    {/* Cancel button */}
                    {dialogType && hideCancelButton[dialogType] ? null : (
                        <Button
                            color="primary"
                            data-testid={cancelButtonTestId}
                            variant="text"
                            disabled={disableCancel}
                            onClick={() => {
                                handleCancel?.()
                                handleClose()
                            }}
                            {...cancelButtonProps}
                        >
                            <CustomTypography fontWeight="bold">Cancel</CustomTypography>
                        </Button>
                    )}
                </>
            </DialogActions>
        )}
    </Dialog>
)

export default CustomModal

const confirmationButtonTestId = "qa-custom-modal-confirmation-button"
const cancelButtonTestId = "qa-custom-modal-cancel-button"
const closeButtonTestId = "qa-custom-modal-close-button"
const modalTestId = "qa-modal"
