import React from "react"
import RemoveIcon from "@mui/icons-material/Close"
import Button from "@mui/material/Button"
import TitleTextField from "../common/TitleTextfield/TitleTextField"
import ColorPopover from "./colorPopover"

function ColorArrayProperty({
    editable,
    labels,
    title,
    value,
    isDefaultValue,
    isZoomDependent,
    onAdd,
    onClick,
    onPropertyChanged,
    onRemove,
    onSpecificPropertyChanged,
    onTitleChanged,
}) {
    const onColorChanged = (color, index) => {
        let newValue = [...value]

        newValue.splice(index, 1, `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)

        onPropertyChanged(newValue)
    }

    const onLabelChanged = (text, index) => {
        let newValue = [...labels]

        newValue.splice(index, 1, text)

        onSpecificPropertyChanged({ name: "label" }, newValue)
    }

    const onAddRow = () => {
        onAdd()
    }

    const onRemoveRow = index => {
        onRemove(index)
    }

    return (
        <div className="property" onClick={onClick}>
            {value.map((colorValue, index) => {
                return (
                    <div key={index} className="property-content item">
                        <TitleTextField
                            value={labels[index]}
                            isDefaultValue={isDefaultValue}
                            isReadOnly={!editable}
                            isZoomDependent={isZoomDependent}
                            onTitleChanged={title => onLabelChanged(title, index)}
                        />
                        {editable && (
                            <div className="remove-button" onClick={() => onRemoveRow(index)}>
                                <RemoveIcon />
                            </div>
                        )}

                        <ColorPopover color={colorValue} onColorChanged={color => onColorChanged(color, index)} />
                    </div>
                )
            })}
            {editable && (
                <Button className="add-row-button" variant="outlined" onClick={onAddRow}>
                    Add Row
                </Button>
            )}
        </div>
    )
}

export default ColorArrayProperty
