import { getColorsArrayEndpoint } from "@windgis/shared"
import baseAtlasApi from "store/baseAtlasApi"

export const utilsApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        getColorsArray: getColorsArrayEndpoint(build),
    }),
})

export const { useGetColorsArrayQuery } = utilsApi
