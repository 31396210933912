import React, { useMemo } from "react"
import ExpandLess from "@mui/icons-material/ExpandLess"
import { Collapse, Divider, Tooltip } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { useDispatch, useSelector } from "react-redux"
import { isGroup } from "@emblautec/recursive-array-extensions"
import { TruncatedTypography } from "@windgis/shared"
import { toggleGroupLayers } from "actions/globalActions"
import * as layerSelectorActions from "reducers/layerSelector"
import { getLayerVisibilityMap } from "selectors/layerSelector"
import Layer from "./layer"
import useGroupStyles from "./styles/groupStyles"

const Group = ({ depth = 0, group, layerHandlers }) => {
    const classes = useGroupStyles()

    const layerVisibilityMap = useSelector(getLayerVisibilityMap)

    const dispatch = useDispatch()

    const [groupTotalLayers, groupVisibleLayers] = useMemo(() => {
        let groupTotalLayers = 0
        let groupVisibleLayers = 0
        group.layers.forLayersRecursive(layer => {
            groupTotalLayers++
            if (layerVisibilityMap[layer.resourceId]) groupVisibleLayers++
        })
        return [groupTotalLayers, groupVisibleLayers]
    }, [layerVisibilityMap, group.layers])

    const toggleLayers = e => {
        if (e) e.stopPropagation()
        const nextDisplayValue = groupVisibleLayers !== groupTotalLayers ? "visible" : "none"

        const groupLayersMap = {}

        //process legend
        group.layers.forLayersRecursive(layer => {
            groupLayersMap[layer.resourceId] = true
        })

        dispatch(
            toggleGroupLayers({
                groupId: group.resourceId,
                groupLayersMap,
                newVisibility: nextDisplayValue === "visible",
            }),
        )
    }

    const handleCollapse = (e, group) => {
        e.stopPropagation()

        dispatch(
            layerSelectorActions.toggleGroupCollapse({
                groupId: group.resourceId,
                newCollapseValue: !group.options.collapsed,
            }),
        )
    }

    function renderGroup(depth) {
        if (depth === 0) {
            return (
                <div className={"group"} onClick={e => handleCollapse(e, group)}>
                    <div className={classes.grow}>
                        <TruncatedTypography
                            color="inherit"
                            fontWeight="bold"
                            minWidth={200}
                            textTransform="capitalize"
                            variant="body1"
                        >
                            {group.name}
                        </TruncatedTypography>
                    </div>
                    <div className={classes.nrOfLayers} onClick={toggleLayers}>
                        <Typography className={classes.layerCount}>
                            {groupVisibleLayers}/{groupTotalLayers}
                        </Typography>
                    </div>
                    <div className={classes.layerCountSeperator}></div>

                    <Tooltip title={group.options.collapsed ? "Expand" : "Collapse"}>
                        <IconButton
                            className={classes.collapseBtn}
                            size="large"
                            onClick={e => handleCollapse(e, group)}
                        >
                            <ExpandLess
                                className={group.options.collapsed ? classes.expandedRight : classes.collapsed}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div className={"sub-group"} onClick={e => handleCollapse(e, group)}>
                    <Tooltip title={group.options.collapsed ? "Expand" : "Collapse"}>
                        <IconButton
                            className={classes.innerCollapseBtn}
                            size="large"
                            onClick={e => handleCollapse(e, group)}
                        >
                            <ExpandLess
                                className={group.options.collapsed ? classes.expandedLeft : classes.collapsed}
                            />
                        </IconButton>
                    </Tooltip>

                    <div className={classes.grow}>
                        <TruncatedTypography color="inherit" minWidth={200} textTransform="capitalize" variant="body1">
                            {group.name}
                        </TruncatedTypography>
                    </div>
                    <div className={classes.nrOfLayers} onClick={toggleLayers}>
                        <Typography className={classes.layerCount}>
                            {groupVisibleLayers}/{groupTotalLayers}
                        </Typography>
                    </div>
                </div>
            )
        }
    }

    let groupElement = renderGroup(depth)

    return (
        <div className={depth === 0 ? classes.rootContainer : ""}>
            <div className={depth === 0 ? "group-root" : "sub-group-root"}>
                {groupElement}

                <Collapse in={!group.options.collapsed && group.layers.length !== 0} timeout={200} unmountOnExit>
                    {depth === 0 && <Divider />}
                    <div className={depth === 0 ? "" : classes.borderBox}>
                        <div className={depth === 0 ? classes.layerContainer : classes.innerLayerContainer}>
                            {group.layers.map(layer => {
                                return isGroup(layer) ? (
                                    <Group
                                        key={layer.resourceId}
                                        depth={depth + 1}
                                        group={layer}
                                        layerHandlers={layerHandlers}
                                    />
                                ) : (
                                    <Layer key={layer.resourceId} layer={layer} layerHandlers={layerHandlers} />
                                )
                            })}
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default Group
