import React from "react"
import { FilterNone, LibraryAddCheck } from "@mui/icons-material"
import CheckedIcon from "@mui/icons-material/CheckCircleOutline"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import * as printActions from "actions/print"
import config from "config"
import { NOTES_CHAR_LIMIT, TITLE_CHAR_LIMIT } from "constants/print/characterLimits"
import { getProjectForClient } from "features/core/selectors"
import { getPrintFeatures } from "selectors/printSelectors"

const useStyles = makeStyles(theme => ({
    formatSelector: {
        padding: 8,
        width: "80%",
    },
    header: {
        borderBottom: "2px solid #f5f7f8",
        padding: 10,
    },
    radioGroup: {
        justifyContent: "space-between",
        paddingLeft: 42,
    },
    root: {
        background: "#fff",
    },
    section: {
        borderBottom: "2px solid #ececec",
        padding: 16,
    },
    updateLogoLink: {
        display: "flex",
        marginLeft: 4,
    },
}))

const PrintFeatures = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const printFeatures = useSelector(getPrintFeatures)

    const params = useParams()
    const currentProject = useSelector(getProjectForClient(params))

    const selectedAll =
        printFeatures.showMapCopyright &&
        printFeatures.showMapDate &&
        printFeatures.showMapLogo &&
        printFeatures.showMapScalebar &&
        printFeatures.showMapTitle &&
        printFeatures.showMapNorthArrow &&
        printFeatures.showMapLegend

    const onSelectAll = () => {
        if (selectedAll) {
            onShowLegendToggle()
            onShowScalebarToggle()
            onShowCopyrightToggle()
            onShowDateToggle()
            onShowNorthArrowToggle()
            onShowLogoToggle()
            onShowTitleToggle()
            onShowNotesToggle()
        } else {
            !printFeatures.showMapLegend && onShowLegendToggle()
            !printFeatures.showMapScalebar && onShowScalebarToggle()
            !printFeatures.showMapCopyright && onShowCopyrightToggle()
            !printFeatures.showMapDate && onShowDateToggle()
            !printFeatures.showMapNorthArrow && onShowNorthArrowToggle()
            !printFeatures.showMapLogo && onShowLogoToggle()
            !printFeatures.showMapTitle && onShowTitleToggle()
            !printFeatures.showMapTitle && onShowNotesToggle()
        }
    }

    const onShowLegendToggle = () => {
        dispatch(printActions.toggleMapLegend())
    }

    const onShowScalebarToggle = () => {
        dispatch(printActions.toggleMapScalebar())
    }

    const onShowCopyrightToggle = () => {
        dispatch(printActions.toggleMapCopyright())
    }

    const onShowDateToggle = () => {
        dispatch(printActions.toggleMapDate())
    }

    const onDateFormatToggle = e => {
        dispatch(printActions.toggleMapDateFormat(e.target.value))
    }

    const onShowNorthArrowToggle = () => {
        dispatch(printActions.toggleMapNorthArrow())
    }

    const onShowLogoToggle = () => {
        if (!currentProject?.logoUrl) return

        dispatch(printActions.toggleMapLogo())
    }

    const onShowTitleToggle = () => {
        dispatch(printActions.toggleMapTitle())
    }

    const onShowNotesToggle = () => {
        dispatch(printActions.toggleMapNotes())
    }

    const getUpdateLogoUrl = () => {
        return `${config.coreAppUrl}clients/${params.clientId}/details/projects/list/(project:${params.projectId})`
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid alignItems="center" className={classes.header} container>
                    <Grid item xs={10}>
                        <Grid container justifyContent="flex-start">
                            <Typography variant="subtitle1">Additional Info</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip placement="bottom" title="Select All">
                            <IconButton aria-label="select-all" size="large" onClick={() => onSelectAll()}>
                                {selectedAll ? <LibraryAddCheck /> : <FilterNone />}
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className={classes.section} item xs={12}>
                <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapLegend} onChange={onShowLegendToggle} />
                            <Typography variant="body1">Legend</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapCopyright} onChange={onShowCopyrightToggle} />
                            <Typography variant="body1">Copyright</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapScalebar} onChange={onShowScalebarToggle} />
                            <Typography variant="body1">Scalebar</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapDate} onChange={onShowDateToggle} />
                            <Typography variant="body1">Date</Typography>
                        </Grid>
                    </Grid>

                    {printFeatures.showMapDate && (
                        <Grid item xs={12}>
                            <Grid alignItems="center" className={classes.radioGroup} container>
                                <FormControl component="fieldset">
                                    <FormLabel component="label">Date format</FormLabel>
                                    <RadioGroup
                                        aria-label="map-date-type"
                                        name="map-date"
                                        value={printFeatures.mapDateFormat}
                                        onChange={onDateFormatToggle}
                                    >
                                        <Grid container>
                                            <FormControlLabel
                                                control={<Radio checkedIcon={<CheckedIcon />} />}
                                                label="Local time"
                                                value="local"
                                            />
                                            <FormControlLabel
                                                control={<Radio checkedIcon={<CheckedIcon />} />}
                                                label="UTC time"
                                                value="utc"
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox
                                checked={printFeatures.showMapLogo}
                                disabled={!currentProject?.logoUrl}
                                onChange={onShowLogoToggle}
                            />
                            <Typography variant="body1">Logo</Typography>
                            {!currentProject?.logoUrl && (
                                <Tooltip title="No logo set for this project. Click here to set one">
                                    <a className={classes.updateLogoLink} href={getUpdateLogoUrl()} target="_blank">
                                        <OpenInNewIcon color="primary" fontSize="inherit" />
                                    </a>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapNorthArrow} onChange={onShowNorthArrowToggle} />
                            <Typography variant="body1">North Arrow</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapTitle} onChange={onShowTitleToggle} />
                            <Typography variant="body1">Map Title</Typography>
                        </Grid>
                    </Grid>
                    {printFeatures.showMapTitle && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                inputProps={{ maxLength: TITLE_CHAR_LIMIT }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {printFeatures.mapTitle.length} / {TITLE_CHAR_LIMIT}
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Map title"
                                size="small"
                                value={printFeatures?.mapTitle}
                                variant="outlined"
                                onChange={e => dispatch(printActions.onMapTitleChange(e.target.value))}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grid alignItems="center" container>
                            <Checkbox checked={printFeatures.showMapNotes} onChange={onShowNotesToggle} />
                            <Typography variant="body1">Notes</Typography>
                        </Grid>
                    </Grid>
                    {printFeatures.showMapNotes && (
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                inputProps={{ maxLength: NOTES_CHAR_LIMIT }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {printFeatures.mapNotes.length} / {NOTES_CHAR_LIMIT}
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Map notes"
                                size="small"
                                value={printFeatures?.mapNotes}
                                variant="outlined"
                                onChange={e => dispatch(printActions.onMapNotesChange(e.target.value))}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PrintFeatures
