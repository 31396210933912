import { FC } from "react"
import { Stack } from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import { Dayjs } from "dayjs"
import { DatePickerView } from "./types/DatePickerView"

type Props = {
    maxDate?: Dayjs
    maxTime?: Dayjs
    minDate?: Dayjs
    minTime?: Dayjs
    name?: string
    value: Dayjs
    views?: DatePickerView[]
    onChange: (value: Dayjs) => void
}

const CustomDateTimePicker: FC<Props> = ({
    maxDate,
    maxTime,
    minDate,
    minTime,
    name = "",
    value,
    views = ["year", "month", "day"],
    onChange,
}) => {
    return (
        <Stack alignItems="center" justifyContent="center">
            <StaticDatePicker
                displayStaticWrapperAs="desktop"
                maxDate={maxDate}
                minDate={minDate}
                slotProps={{
                    nextIconButton: {
                        sx: { "data-testid": `qa-${name}-date-picker-left-arrow` },
                    },
                    previousIconButton: {
                        sx: { "data-testid": `qa-${name}-date-picker-right-arrow` },
                    },
                }}
                value={value}
                views={views}
                onChange={(val, _) => onChange(val as Dayjs)}
            />
            <Stack alignItems="center" direction="row" paddingBottom="15px">
                <TimePicker
                    ampm={false}
                    maxTime={maxTime}
                    minTime={minTime}
                    minutesStep={1}
                    slotProps={{
                        actionBar: {
                            actions: ["accept"],
                            sx: {
                                display: "flex",
                                justifyContent: "center",
                                paddingBlock: 0,
                            },
                        },
                    }}
                    sx={{ maxWidth: "105px" }}
                    timeSteps={{ minutes: 1 }}
                    timezone="UTC"
                    value={value}
                    views={["hours", "minutes"]}
                    onChange={(newValue: Dayjs | null) => {
                        if (newValue) {
                            onChange(value.set("hour", newValue.hour()).set("minute", newValue.minute()))
                        }
                    }}
                />
            </Stack>
        </Stack>
    )
}

export default CustomDateTimePicker
