import { FCWC, createContext, useEffect } from "react"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { useMap } from "@emblautec/react-map-gl"
import { useDraw } from "utils/customHooks/map/useDraw"

type MainDrawContextValue = null | MapboxDraw

export const MainDrawContext = createContext<MainDrawContextValue>(null)

export const MainDrawProvider: FCWC = ({ children }) => {
    const draw = useDraw()
    const { mainMap } = useMap()

    useEffect(() => {
        if (!mainMap) return

        mainMap.addControl(draw)
    }, [mainMap])

    return <MainDrawContext.Provider value={draw}>{children}</MainDrawContext.Provider>
}
