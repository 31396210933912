import { useEffect, useState } from "react"
import { Alert, Box, CircularProgress } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import { Subscription } from "model/ais/Subscription"
import { setAreAISPlaybackBoatsOnMap, setBoatDetails } from "reducers/ais"
import { getAreAISPlaybackBoatsOnMap } from "selectors/aisSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import useLeftDistance from "../../hooks/useLeftDistance"
import { ArchiveMetadata } from "../../models/ArchiveMetadata"
import AisArchivePlayer from "./AisArchivePlayer"
import useArchiveAnimationsManager from "./hooks/useArchiveAnimationsManager"
import useSliderValueTransform from "./hooks/useSliderValueTransform"

type Props = {
    archiveMetadata: ArchiveMetadata
    subscription: Subscription
    onClose: () => void
}

const DATE_FORMAT = "DD MMMM YYYY HH:mm:ss [UTC]"
const LOADER_WIDTH = 40

const AisArchivePlayerContainer = ({ archiveMetadata, subscription, onClose }: Props) => {
    const leftDistance = useLeftDistance(LOADER_WIDTH)

    const initialIntervalStartDate = dayjs.utc(archiveMetadata.earliestDataTimestamp).second(0).millisecond(0)
    const initialIntervalEndDate = dayjs.utc(archiveMetadata.latestDataTimestamp).second(0).millisecond(0)
    const [filteringInterval, setFilteringInterval] = useState<[Dayjs, Dayjs]>([
        initialIntervalStartDate,
        initialIntervalEndDate,
    ])
    const [isDataOutOfRange, setIsDataOutOfRange] = useState(false)

    const areAISPlaybackBoatsOnMap = useAppSelector(getAreAISPlaybackBoatsOnMap)

    const {
        changePlaybackSpeed,
        currentDate,
        endDate,
        jumpToBucket,
        playbackSpeed,
        isFetching,
        isPlaying,
        shouldShowReplay,
        pausePlayback,
        startDate,
        startPlayback,
        stopPlayback,
    } = useArchiveAnimationsManager({
        archiveMetadata,
        filteringInterval,
        subscription,
    })

    const { dateToSliderValue, sliderValueToBucketIndex, sliderValueToDate, step } = useSliderValueTransform(
        startDate,
        endDate,
        initialIntervalStartDate,
        initialIntervalEndDate,
        playbackSpeed,
    )

    const dispatch = useAppDispatch()

    const onPlayToggle = () => {
        !areAISPlaybackBoatsOnMap && dispatch(setAreAISPlaybackBoatsOnMap(true))
        isPlaying ? pausePlayback() : startPlayback()
    }

    const onPlaybackClose = () => {
        dispatch(setAreAISPlaybackBoatsOnMap(false))
        onClose()
    }

    useEffect(() => {
        const now = dayjs.utc()
        const sevenDaysAgo = now.subtract(6, "days").startOf("day")
        const isSevenDaysAgoDisabled = sevenDaysAgo.isAfter(initialIntervalEndDate)
        if (!isSevenDaysAgoDisabled) {
            setFilteringInterval([sevenDaysAgo, now])
        } else {
            setFilteringInterval([
                dayjs.utc(archiveMetadata.earliestDataTimestamp).second(0).millisecond(0),
                dayjs.utc(archiveMetadata.latestDataTimestamp).second(0).millisecond(0),
            ])
        }
    }, [archiveMetadata])

    useEffect(() => {
        if (initialIntervalStartDate.isAfter(currentDate) || initialIntervalEndDate.isBefore(currentDate))
            setIsDataOutOfRange(true)
        else setIsDataOutOfRange(false)
    }, [currentDate])

    useEffect(
        () => () => {
            stopPlayback()
            dispatch(setBoatDetails({}))
        },
        [stopPlayback],
    )

    return (
        <div>
            {isFetching && (
                <Box bottom="50%" left={`calc(50% + ${leftDistance}px)`} position="fixed" zIndex={2}>
                    <CircularProgress />
                </Box>
            )}
            {isDataOutOfRange && (
                <Box mb={1}>
                    <Alert severity="info">Some data is missing for this timelapse re-play</Alert>
                </Box>
            )}
            <AisArchivePlayer
                endDate={initialIntervalEndDate}
                filteringInterval={filteringInterval}
                getLabelText={value => sliderValueToDate(value).utc().format(DATE_FORMAT)}
                label={currentDate?.utc().format(DATE_FORMAT) ?? ""}
                playbackSpeed={playbackSpeed}
                sliderValue={currentDate ? dateToSliderValue(currentDate) : 0}
                step={step}
                subscriptionName={subscription.name}
                isPlaying={isPlaying}
                shouldShowReplay={shouldShowReplay}
                startDate={initialIntervalStartDate}
                onClose={onPlaybackClose}
                onFilteringIntervalChange={(newFilteringInterval: [Dayjs, Dayjs]) => {
                    setFilteringInterval(newFilteringInterval)
                }}
                onPlaybackSpeedChange={changePlaybackSpeed}
                onPlayToggle={onPlayToggle}
                onReplay={() => {
                    jumpToBucket(0)
                    startPlayback()
                }}
                onSliderValueChange={value => {
                    jumpToBucket(sliderValueToBucketIndex(value))
                    dispatch(setAreAISPlaybackBoatsOnMap(true))
                }}
            />
        </div>
    )
}

export { AisArchivePlayerContainer }
