// @ts-nocheck
import { CoreModule } from "features/core/models/CoreModule"

const ANALYTICS_MODULE_ID: string = import.meta.env.VITE_ANALYTICS_MODULE_ID ?? ""
const B2C_CLIENT_ID: string = import.meta.env.VITE_B2C_CLIENT_ID ?? ""
const B2C_DOMAIN: string = import.meta.env.VITE_B2C_DOMAIN ?? ""
const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT ?? ""
const MAPBOX_APIKEY: string = import.meta.env.VITE_MAPBOX_APIKEY ?? ""
const MAPBOX_BASEMAP: string = import.meta.env.VITE_MAPBOX_BASEMAP ?? ""
const CORE_API_URL: string = import.meta.env.VITE_CORE_API_URL ?? ""
const CORE_LOCATION_URL: string = import.meta.env.VITE_CORE_LOCATION_URL ?? ""
const CORE_FAVICON_URL: string = import.meta.env.VITE_CORE_FAVICON_URL ?? ""
const LANDING_PAGE: string = import.meta.env.VITE_LANDING_PAGE ?? ""
const CRYPTO_PASS: string = import.meta.env.VITE_CRYPTO_PASS ?? ""
const CUSTOMER_NAME: string = import.meta.env.VITE_CUSTOMER_NAME ?? ""
const DOMAIN: string = import.meta.env.VITE_DOMAIN ?? ""
const MODULE_ID: string = import.meta.env.VITE_MODULE_ID ?? ""
const BUFFER_MAX_NUMBER_OF_WEB_WORKERS: number = Number(import.meta.env.VITE_BUFFER_MAX_NUMBER_OF_WEB_WORKERS ?? 4)
const MIN_BUFFER_RADIUS_IN_METERS: number = Number(import.meta.env.VITE_MIN_BUFFER_RADIUS_IN_METERS ?? 100)
const DPR_SHOWCASE_URL: string = import.meta.env.VITE_DPR_SHOWCASE_URL ?? ""

// development only
const IS_DEBUG: boolean = import.meta.env.VITE_IS_DEBUG === "true"
const API_URL: string = import.meta.env.VITE_API_URL ?? ""

const config = {
    analyticsModuleId: ANALYTICS_MODULE_ID,
    apiUrl: IS_DEBUG ? API_URL : `https://api.${CUSTOMER_NAME}.${DOMAIN}/api/`,
    azureB2C: {
        auth: {
            authority: `https://${B2C_DOMAIN}/lautecb2c${ENVIRONMENT}.onmicrosoft.com/b2c_1a_signin`, // Defaults to "https://login.microsoftonline.com/common"
            clientId: B2C_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
            knownAuthorities: [B2C_DOMAIN], // Mark your B2C tenant's domain as trusted.
            navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
            passwordResetAuthority: `https://${B2C_DOMAIN}/lautecb2c${ENVIRONMENT}.onmicrosoft.com/b2c_1a_passwordreset`,
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true,
        },
        system: {
            allowRedirectInIframe: true,
        },
    },
    baseLayers: {
        aerial: `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_APIKEY}`,
        street: MAPBOX_BASEMAP,
    },
    bufferOptions: {
        maxNumberOfWebWorkers: BUFFER_MAX_NUMBER_OF_WEB_WORKERS,
        minBufferRadiusInMeters: MIN_BUFFER_RADIUS_IN_METERS,
    },
    coreApiUrl: CORE_API_URL,
    coreAppUrl: CORE_LOCATION_URL,
    coreModule: {
        description: "Account Management",
        favIconUrl: CORE_FAVICON_URL,
        landingPageUrl: CORE_LOCATION_URL,
        locationUrl: CORE_LOCATION_URL,
        name: "Core",
        status: 1,
    } as CoreModule,
    cryptoUtilsPass: CRYPTO_PASS,
    dprShowcaseUrl: DPR_SHOWCASE_URL,
    forgetPasswordErrorCode: "AADB2C90118",
    landingUrl: LANDING_PAGE,
    loginRequest: {
        scopes: [`https://lautecb2c${ENVIRONMENT}.onmicrosoft.com/apis/default`],
    },
    mapboxApiKey: MAPBOX_APIKEY,
    moduleId: MODULE_ID,
    recaptchaKey: "6LebhkofAAAAANZTaiiDqYUdjX1u3e-2oBP6Fb90",
}

export default config
