import { useMemo } from "react"
import {
    IconAndTitleProps,
    LegendClasses,
    LegendSpacingOptions,
    LegendTypographyProps,
    Styling,
} from "features/common/models/legend"
import { StyleProperty } from "model/style/StyleProperty"
import { setFixedNumberOfDecimals } from "utils/legend/legendUtils"
import BasicLegendEntry from "../BasicLegendEntry/BasicLegendEntry"
import useIconAndTitlePropsGenerator from "../hooks/useIconAndTitlePropsGenerator"
import LegendIcon from "../LegendIcon/LegendIcon"

type LegendExpressionValuesProps = {
    expressionProperty: StyleProperty
    rightAlign?: boolean
    spacingOptions: LegendSpacingOptions
    styleClasses: LegendClasses
    styling: Styling
    verticalDirection?: boolean
}

const LegendExpressionValues = ({
    expressionProperty,
    rightAlign = false,
    spacingOptions,
    styleClasses,
    styling,
    verticalDirection = false,
}: LegendExpressionValuesProps) => {
    const { expressionPropertyCopy, longestValueLength } = useMemo(
        () => setFixedNumberOfDecimals(expressionProperty, rightAlign),
        [expressionProperty, rightAlign],
    )

    const iconAndTitleProps = useIconAndTitlePropsGenerator({
        expressionProperty: expressionPropertyCopy,
        rightAlign,
        styling,
    })

    const typographyProps: LegendTypographyProps | undefined = rightAlign
        ? { maxWidth: `${longestValueLength}ch`, textAlign: "right" }
        : undefined

    const xsSpacing: number = verticalDirection
        ? spacingOptions.multiLabelItemVerticalBreakpointSpacing
        : spacingOptions.multiLabelItemBreakpointSpacing

    if (!iconAndTitleProps) {
        return null
    }

    return (
        <>
            {iconAndTitleProps.map((props: IconAndTitleProps, index: number) => (
                <BasicLegendEntry
                    key={props.title + index}
                    classes={{
                        dividerClass: styleClasses.dividerSpacing,
                        entryContainerClass: styleClasses.multiLabelEntryContainer,
                        typographyClass: styleClasses.multiLabelText,
                    }}
                    icon={<LegendIcon styling={props.iconProps} />}
                    title={props.title}
                    typographyProps={typographyProps}
                    xsSpacing={xsSpacing}
                />
            ))}
        </>
    )
}

export default LegendExpressionValues
