import { useEffect, useState } from "react"
import { Close } from "@mui/icons-material"
import { Button, Divider, Grid, IconButton, LinearProgress, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { useMap } from "@emblautec/react-map-gl"
import { getPrintFeatures } from "selectors/printSelectors"
import { onPrint } from "utils/printUtils"
import MapPreview from "./MapPreview/MapPreview"
import PrintOptions from "./printOptions"
import PrintOutput from "./printOutput"
import { usePrintPreviewStyles } from "./styles/printPreviewStyles"

const PrintPreview = ({ handleClose }) => {
    const classes = usePrintPreviewStyles()
    const { printMap } = useMap()
    const paperSizes = {
        A3: {
            height: 16.5,
            width: 11.7,
        },
        A4: {
            height: 11.69291,
            width: 8.26772,
        },
    }
    const dpiOptions = [72, 150, 300]
    const printFeatures = useSelector(getPrintFeatures)
    const [printing, setPrinting] = useState(false)
    const [outputType, setOutputType] = useState("image")
    const [dpi, setDpi] = useState(300)
    const [outputSize, setOutputSize] = useState("A4")
    const [orientation, setOrientation] = useState("landscape")
    const [unitType, setUnitType] = useState("inch")
    const [width, setWidth] = useState(paperSizes.A4.width)
    const [height, setHeight] = useState(paperSizes.A4.height)

    useEffect(() => {
        let paperSize = paperSizes[outputSize]
        let newPaperSize = { ...paperSize }

        if (orientation === "landscape") {
            newPaperSize.height = paperSize.width
            newPaperSize.width = paperSize.height
        }
        setWidth(newPaperSize.width)
        setHeight(newPaperSize.height)
    }, [orientation, outputSize])

    const onPrintScrn = () => {
        setPrinting(true)
        onPrint(unitType, height, width, dpi, printMap, printFeatures, outputType, orientation, setPrinting)
    }

    return (
        <div className={classes.root}>
            <IconButton
                className={
                    orientation === "landscape" && printFeatures.showMapLegend
                        ? classes.closeButtonLandscape
                        : classes.closeButton
                }
                size="small"
                onClick={handleClose}
            >
                <Close />
            </IconButton>
            <div className={classes.settingsContainer}>
                <Grid container>
                    <Grid className={classes.header} item xs={12}>
                        <Grid className={classes.section} container>
                            <Grid item xs={9}>
                                <Grid container justifyContent="flex-start">
                                    <Typography variant="h6">Print Map</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Button color="primary" variant="contained" disabled={printing} onClick={onPrintScrn}>
                                    Print
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {printing && <LinearProgress />}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <PrintOutput outputType={outputType} setOutputType={setOutputType} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <PrintOptions
                                dpi={dpi}
                                dpiOptions={dpiOptions}
                                height={height}
                                orientation={orientation}
                                outputSize={outputSize}
                                unitType={unitType}
                                width={width}
                                setDpi={setDpi}
                                setHeight={setHeight}
                                setOrientation={setOrientation}
                                setOutputSize={setOutputSize}
                                setUnitType={setUnitType}
                                setWidth={setWidth}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.printPreviewContainer}>
                <div
                    className={`${
                        orientation === "portrait"
                            ? classes.mapPreviewPortraitContainer
                            : classes.mapPreviewLandscapeContainer
                    }`}
                    id="print-preview-container"
                >
                    <MapPreview dpi={dpi} orientation={orientation} />
                </div>
            </div>
        </div>
    )
}

export default PrintPreview
