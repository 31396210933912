import React from "react"
import Popover from "@mui/material/Popover"
import { ChromePicker } from "react-color"

function SimplePopover(props) {
    const [anchorEl, setAnchorEl] = React.useState(null)

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : null

    return (
        <div>
            <div
                className="color-container"
                style={{ backgroundColor: props.color }}
                onClick={!props.disabled && handleClick}
            ></div>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "center",
                }}
                id={id}
                open={open}
                PaperProps={{
                    style: { marginLeft: 8, overflowY: "hidden", userSelect: "none" },
                }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "center",
                }}
                onClose={handleClose}
            >
                <ChromePicker color={props.color} onChangeComplete={props.onColorChanged} />
            </Popover>
        </div>
    )
}

export default SimplePopover
