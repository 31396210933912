import makeStyles from "@mui/styles/makeStyles"

export const useFiltersListStyles = makeStyles({
    addButton: (props: { isButtonDisabled: boolean }) => ({
        cursor: props.isButtonDisabled ? "default" : "pointer",
        opacity: props.isButtonDisabled ? 0.5 : 1,
        position: "absolute",
        right: 0,
        top: "24px",
    }),
    filtersListContainer: {
        position: "relative",
    },
    filterTitle: {
        boxShadow: "5px 0 5px rgba(0, 0, 0, 0.2)",
        display: "flex",
        fontWeight: "bold",
        justifyContent: "center",
        margin: "4px 0 16px",
        padding: "4px",
    },
})
