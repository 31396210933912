import React, { useState } from "react"
import { LinearProgress } from "@mui/material"
import { useTheme } from "@mui/styles"
import { unwrapResult } from "@reduxjs/toolkit"
import { useDispatch, useSelector } from "react-redux"
import { CustomModal, CustomTypography } from "@windgis/shared"
import { downloadGeotiff } from "actions/layerSelector"
import useComponentCancelToken from "app/hooks/useComponentCancelToken"
import { getFetchingGeotiff } from "selectors/layerSelector"
import toastr from "utils/customToastr"
import { handleError } from "utils/networkErrorUtils"
import { useStyles } from "./styles"

const GeotiffDownloader = ({ layer, open, handleClose }) => {
    const classes = useStyles()
    const theme = useTheme()

    const [downloadProgressPercentage, setDownloadProgressPercentage] = useState(0)
    const [cancelToken, regenerateCancelToken] = useComponentCancelToken()

    const dispatch = useDispatch()
    const fetching = useSelector(getFetchingGeotiff)

    const onDownloadGeotiff = () => {
        const config = {
            cancelToken: cancelToken.token,
            onDownloadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setDownloadProgressPercentage(percentCompleted)
            },
        }

        const fileName = `${layer.name}.tif`
        const rasterId = layer.resourceId

        dispatch(downloadGeotiff({ config, fileName, rasterId }))
            .then(unwrapResult)
            .then(() => {
                toastr.success("Geotiff downloaded")
                handleClose()
                setDownloadProgressPercentage(0)
            })
            .catch(err => {
                handleError(err)
                setDownloadProgressPercentage(0)
            })
    }

    const handleCancel = () => {
        setDownloadProgressPercentage(0)
        cancelToken.cancel("Download cancelled")
        regenerateCancelToken()
    }

    return (
        <CustomModal
            dialogTitle={"Download"}
            dialogType={"start"}
            disableCancel={false}
            disabled={fetching}
            isOpen={open}
            handleCancel={handleCancel}
            handleClose={handleClose}
            onConfirm={onDownloadGeotiff}
        >
            {!fetching && (
                <CustomTypography
                    color={theme.customColors.appTextColorLight}
                    textWeight="semibold"
                    variant="subtitle1"
                >
                    {layer.name}.geotiff
                </CustomTypography>
            )}
            {fetching && (
                <div className="progress-page">
                    {downloadProgressPercentage === 0 && (
                        <div>
                            <CustomTypography className="text" id="transition-modal-title" variant="h6">
                                Generating File
                            </CustomTypography>
                            <LinearProgress className={classes.progressBar} variant="indeterminate" />
                        </div>
                    )}
                    {downloadProgressPercentage > 0 && (
                        <div className={classes.downloadContainer}>
                            <CustomTypography
                                color={theme.customColors.appTextColorLight}
                                textWeight="bold"
                                variant="h2"
                            >
                                Downloading {layer.name}
                            </CustomTypography>
                            <CustomTypography className={classes.percentage} variant="body1">
                                {downloadProgressPercentage}%
                            </CustomTypography>
                            <LinearProgress
                                className={classes.progressBar}
                                value={downloadProgressPercentage}
                                variant="determinate"
                            />
                        </div>
                    )}
                </div>
            )}
        </CustomModal>
    )
}

export default GeotiffDownloader
