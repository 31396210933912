import React from "react"
import { Tab, Tabs } from "@mui/material"
import withStyles from "@mui/styles/withStyles"

const StyledTabs = withStyles(theme => ({
    indicator: {
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
    },
    root: {
        backgroundColor: theme.customColors.appBgColorLight,
        borderBottom: `1px solid ${theme.customColors.borderColor}`,
        height: 54,
        minHeight: 54,
        padding: 0,
        width: "100%",
        zIndex: 1,
    },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
    labelIcon: {
        minHeight: "initial",
    },
    root: {
        "&:focus": {
            opacity: 1,
        },
        "& *": {
            marginBottom: "0 !important",
            marginRight: 8,
        },
        borderBottom: "2px solid transparent",
        flexDirection: "row",
        fontSize: 16,
        fontWeight: 600 + " ! important",
        height: 54,

        minWidth: "fit-content",

        paddingInline: theme.customSpacing.generalSidePadding,
    },
    selected: {
        borderBottomColor: theme.customColors.primaryColor,
        color: theme.customSpacing.appTextColor + "!important",
        fontWeight: "bold",
    },
    wrapper: {
        "& *": {
            marginBottom: "0 !important",
            marginRight: 8,
        },
        flexDirection: "row",
    },
}))(props => <Tab {...props} />)

export { StyledTab, StyledTabs }
