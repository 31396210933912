import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Divider, Grid } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import { LegendClasses, LegendSpacingOptions, Styling } from "features/common/models/legend"
import { useFetchDatasetFilteredDistinctColumnValuesQuery } from "features/datasets/api"
import { AppLayer } from "model/app/AppLayer"
import { DigitizeAppLayer } from "model/app/DigitizeAppLayer"
import { MapFilter } from "model/map/MapFilterType"
import { Style } from "model/style/Style"
import { StyleProperty } from "model/style/StyleProperty"
import {
    buildColorProperties,
    buildStyling,
    defaultSpacing,
    editFiltersList,
    extractStyledColumnName,
} from "utils/legend/legendUtils"
import BasicLegendEntry from "../BasicLegendEntry/BasicLegendEntry"
import LegendIcon from "../LegendIcon/LegendIcon"
import LegendExpressionValues from "./LegendExpressionValues"

type LegendEntryProps = {
    filters?: MapFilter[]
    layer: AppLayer | DigitizeAppLayer
    spacingOptions: LegendSpacingOptions
    styleClasses: LegendClasses
    styles: Style[]
}

type StylePropertyWithStyleType = { styleType: string } & StyleProperty

const LegendEntry = ({ filters, layer, spacingOptions = defaultSpacing, styleClasses, styles }: LegendEntryProps) => {
    const [layerDistinctValues, setLayerDistinctValues] = useState<{ [key: string]: string[] }>({})
    const [filterResetKey, setFilterResetKey] = useState(0)
    const prevDistinctStyledColumnValues = useRef<string[] | undefined>(undefined)

    const colorProperties = useMemo(
        () =>
            styles.map(style => ({
                ...style.properties.find(prop => prop.propertyType.includes("color")),
                styleType: style.type,
            })),
        [styles],
    )

    const styledColumnName = useMemo(() => {
        const dprStyle = styles.find(({ type }) => type === "box-chart" || type === "pie-chart")

        if (dprStyle) {
            // value = ["get", progress_column_name]
            return dprStyle.properties.find(({ name }) => name === "pie-chart-mask" || name === "box-chart-mask")
                ?.value[1]
        }

        return extractStyledColumnName(colorProperties)
    }, [colorProperties, styles])

    const editedFiltersList = useMemo(() => editFiltersList(filters), [filters])

    const shouldNotFetchDistinctColumnValues = useMemo(
        () => !editedFiltersList || editedFiltersList.length === 0 || !styledColumnName || styledColumnName === "",
        [editedFiltersList, styledColumnName],
    )

    const {
        data: distinctStyledColumnValues,
        isError,
        isLoading,
    } = useFetchDatasetFilteredDistinctColumnValuesQuery(
        {
            datasetId: layer.resourceId,
            filters: editedFiltersList,
            styledColumnName,
        },
        { skip: shouldNotFetchDistinctColumnValues },
    )

    const currentDistinctValues = useMemo(
        () => layerDistinctValues[layer.resourceId] || [],
        [layerDistinctValues, layer.resourceId],
    )

    const filteredColorProperties: StylePropertyWithStyleType[] = useMemo(
        () => buildColorProperties(colorProperties, currentDistinctValues),
        [colorProperties, currentDistinctValues],
    )

    const stylesCopy = useMemo(() => {
        const copy = JSON.parse(JSON.stringify(styles))
        filteredColorProperties.forEach((filteredProperty: StylePropertyWithStyleType) => {
            const styleToUpdate: Style = copy.find((style: StyleProperty) => style.type === filteredProperty.styleType)
            if (styleToUpdate) {
                const propertyIndex = styleToUpdate.properties.findIndex((prop: StyleProperty) =>
                    prop.propertyType.includes("color"),
                )
                if (propertyIndex !== -1) {
                    styleToUpdate.properties[propertyIndex] = { ...filteredProperty }
                }
            }
        })
        return copy
    }, [styles, filteredColorProperties])

    const styling: Styling = useMemo(() => buildStyling(stylesCopy), [stylesCopy])

    const expressionProperty = useMemo(
        () => filteredColorProperties.find((prop: StylePropertyWithStyleType) => prop.expressionType !== "none"),
        [filteredColorProperties],
    )

    const hasExpression = !!expressionProperty

    const handleFilterReset = useCallback(() => {
        setLayerDistinctValues(prevState => {
            const newState = { ...prevState }
            delete newState[layer.resourceId]
            return newState
        })
        prevDistinctStyledColumnValues.current = undefined
        setFilterResetKey(prev => prev + 1)
    }, [layer.resourceId])

    useEffect(() => {
        if (distinctStyledColumnValues && distinctStyledColumnValues !== prevDistinctStyledColumnValues.current) {
            setLayerDistinctValues(prevState => ({
                ...prevState,
                [layer.resourceId]: distinctStyledColumnValues,
            }))
            prevDistinctStyledColumnValues.current = distinctStyledColumnValues
        }

        if (editedFiltersList.length === 0) {
            handleFilterReset()
        }
    }, [distinctStyledColumnValues, editedFiltersList, layer.resourceId, handleFilterReset])

    if (isError || isLoading) {
        return <LoadingPlaceholder loading={isLoading} message="" spinnerSize={4} />
    }

    return (
        <Fragment key={`${layer.resourceId}-${filterResetKey}`}>
            <Grid item xs={spacingOptions.multiLabelEntryBreakpointSpacing}>
                <CustomTypography
                    className={styleClasses.multiLabelHeaderText}
                    textWeight="semibold"
                    variant="subtitle1"
                >
                    {layer.name}
                </CustomTypography>
            </Grid>

            {hasExpression && (
                <LegendExpressionValues
                    expressionProperty={expressionProperty}
                    rightAlign={layer.geometryType === "raster"}
                    spacingOptions={spacingOptions}
                    styleClasses={styleClasses}
                    styling={styling}
                    verticalDirection={layer.geometryType === "raster"}
                />
            )}

            {(styling.type === "pie-chart" || styling.type === "box-chart") && <LegendIcon styling={styling} />}

            {!hasExpression && styling.type !== "pie-chart" && styling.type !== "box-chart" && (
                <BasicLegendEntry
                    key={layer.resourceId}
                    classes={{
                        dividerClass: styleClasses.dividerSpacing,
                        entryContainerClass: styleClasses.singleLabelEntryContainer,
                        typographyClass: styleClasses.labelText,
                    }}
                    icon={<LegendIcon styling={styling} />}
                    title={layer.name}
                    xsSpacing={spacingOptions.singleEntryBreakpointSpacing}
                />
            )}

            <Grid item xs={spacingOptions.multiLabelEntryBreakpointSpacing}>
                <Divider className={styleClasses.dividerSpacing} />
            </Grid>
        </Fragment>
    )
}

export default LegendEntry
