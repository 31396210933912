import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { availableURLSearchParams, useSearchParams } from "@windgis/shared"
import { BoatDetails } from "features/ais/models/BoatDetails"
import { AppLayer } from "model/app/AppLayer"
import { setPickedArchiveData } from "reducers/ais"
import { getIsPlaybackPlaying, getSelectedArchiveSubscriptionId } from "selectors/aisSelectors"
import { getMapClickPos } from "selectors/mapSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import useOnMapClick, { OnClickProps } from "../../hooks/useOnMapClick"
import { getInfoboxes } from "../../selectors"
import DefaultInfobox from "../DefaultInfobox/DefaultInfobox"
import EsoxInfobox from "../EsoxInfobox/EsoxInfobox"
import { StyledPopup } from "./InfoboxPopup.styles"

const InfoboxPopup = () => {
    const { getSearchParamValue, updateSearchParams } = useSearchParams()

    const showZander = getSearchParamValue(availableURLSearchParams.showZander.key) === "true"

    const [selectedFeatures, setSelectedFeatures] = useState<mapboxgl.MapboxGeoJSONFeature[]>([])
    const [selectedRasters, setSelectedRasters] = useState<AppLayer[]>([])
    const [selectedArchive, setSelectedArchive] = useState<BoatDetails[]>([])
    const [sourceToNameMapping, setSourceToNameMapping] = useState<Record<string, string>>({})
    const [infoboxToUse, setInfoboxToUse] = useState("default")
    const [isInfoboxOpen, setIsInfoboxOpen] = useState(false)
    const infoboxes = useAppSelector(getInfoboxes)
    const selectedSubscriptionId = useAppSelector(getSelectedArchiveSubscriptionId)
    const isPlaybackPlaying = useAppSelector(getIsPlaybackPlaying)
    const dispatch = useAppDispatch()

    const mapClickPos = useAppSelector(getMapClickPos)
    const { appId } = useParams<{ appId: string }>()

    useEffect(() => {
        setIsInfoboxOpen(false)
    }, [appId])

    useEffect(() => {
        setIsInfoboxOpen(false)
    }, [selectedSubscriptionId, isPlaybackPlaying])

    const onMapClick = useCallback(
        ({ features, rasters, selectedArchiveData, sourceToNameMapping }: OnClickProps) => {
            const getInfoboxToUse = () => {
                if (rasters.length > 0 || selectedArchiveData.length > 0) return "default"

                const firstFeature = features[0]
                const datasetId = firstFeature.sourceLayer

                return (
                    infoboxes.find(
                        infobox =>
                            (!infobox.applicationId || infobox.applicationId === appId) &&
                            infobox.datasetId === datasetId,
                    )?.name ?? "default"
                )
            }

            setSelectedFeatures(features)
            setSelectedRasters(rasters)
            setSelectedArchive(selectedArchiveData)
            setSourceToNameMapping(sourceToNameMapping)

            setInfoboxToUse(getInfoboxToUse())
            setIsInfoboxOpen(true)
        },
        [appId, infoboxes, selectedSubscriptionId, mapClickPos],
    )

    const onNoData = useCallback(() => {
        setIsInfoboxOpen(false)
        updateSearchParams({
            remove: [
                availableURLSearchParams.lat.key,
                availableURLSearchParams.lng.key,
                availableURLSearchParams.showZander.key,
            ],
        })
    }, [])

    useOnMapClick(onMapClick, onNoData)

    if (!isInfoboxOpen || !mapClickPos) return null

    return (
        <StyledPopup
            latitude={mapClickPos.lat}
            longitude={mapClickPos.lng}
            maxWidth={"400px"}
            onClose={() => {
                setIsInfoboxOpen(false)
                dispatch(setPickedArchiveData([]))
                updateSearchParams({ remove: [availableURLSearchParams.lat.key, availableURLSearchParams.lng.key] })
            }}
        >
            {infoboxToUse === "esox" ? (
                <EsoxInfobox
                    landSeaMask={parseFloat(selectedFeatures[0]?.properties?.lsm)}
                    lat={parseFloat(selectedFeatures[0]?.properties?.latitude)}
                    lng={parseFloat(selectedFeatures[0]?.properties?.longitude)}
                    isZanderModalDefaultOpen={showZander}
                    onZanderModalClose={() => updateSearchParams({ remove: [availableURLSearchParams.showZander.key] })}
                />
            ) : (
                <DefaultInfobox
                    archiveBoats={selectedArchive}
                    features={selectedFeatures}
                    lngLat={mapClickPos}
                    rasters={selectedRasters}
                    sourceToNameMapping={sourceToNameMapping}
                    subscriptionId={selectedSubscriptionId}
                />
            )}
        </StyledPopup>
    )
}

export default InfoboxPopup
