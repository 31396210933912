import { Fragment, useEffect, useState } from "react"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked"
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap"
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material"
import { CollapsibleCard, VirtualizedList, useElementSize } from "@windgis/shared"
import { boatIcons, boatKeys } from "../../constants"
import { VesselUiModel } from "../../models/Vessel.ui.model"
import { StyledGroupTypeImage, StyledVesselImage } from "./VesselsDetails.styles"

export type VesselsDetailsProps = {
    areLiveVesselsVisible?: boolean
    followedVesselId?: string
    latestUpdate: string
    searchValue?: string
    type: keyof typeof boatIcons
    vessels: VesselUiModel[]
    onFollowVesselClick?: (vesselId: string, coordinates: { lat?: number; lon?: number }) => void
    onZoomInClick?: (coordinates: { lat?: number; lon?: number }) => void
}

function VesselsDetails({
    areLiveVesselsVisible,
    followedVesselId,
    latestUpdate,
    searchValue,
    type,
    vessels,
    onFollowVesselClick,
    onZoomInClick,
}: VesselsDetailsProps) {
    const [isToggled, setIsToggled] = useState(false)

    const [setCollapsibleCardRef, { width: collapsibleCardWidth }] = useElementSize()

    const vesselIcon = boatIcons[type] || boatIcons[boatKeys.OTHER]

    const vesselIconPath = `/icons/${vesselIcon}.svg`

    const vesselRowHeight = 59

    const vesselRowHeightWithDivider = vesselRowHeight + 1

    // show max 5 vessels at once
    const virtualizedListHeight =
        vessels.length > 5 ? 5 * vesselRowHeightWithDivider : vessels.length * vesselRowHeightWithDivider

    const scrollBarWidth = 6

    useEffect(() => {
        setIsToggled(!!searchValue)
    }, [searchValue])

    return (
        <CollapsibleCard
            Component={
                <Stack flexDirection="row" gap={1}>
                    <StyledGroupTypeImage alt={type} src={vesselIconPath} />
                    <Typography>
                        {type} ({vessels.length})
                    </Typography>
                </Stack>
            }
            headerPadding={1}
            padding={0}
            paddingBottom={0}
            ref={setCollapsibleCardRef}
            isToggled={isToggled}
            onToggleClick={() => setIsToggled(!isToggled)}
        >
            <VirtualizedList height={virtualizedListHeight} width={collapsibleCardWidth - scrollBarWidth}>
                {vessels.map((vessel, index) => (
                    <Fragment key={`${index}-${vessel.id}`}>
                        <Grid
                            alignItems="center"
                            container
                            flexDirection="row"
                            gap={1}
                            height={vesselRowHeight}
                            padding={theme => theme.spacing(1, 1.5)}
                        >
                            <Grid item xs="auto">
                                <StyledVesselImage alt={vessel.name} src={vesselIconPath} />
                            </Grid>

                            <Grid item xs>
                                <Typography>{vessel.name}</Typography>
                                <Typography>Last update {latestUpdate}</Typography>
                            </Grid>

                            <Grid item xs="auto">
                                {areLiveVesselsVisible ? (
                                    <IconButton onClick={() => onZoomInClick?.({ lat: vessel.lat, lon: vessel.lon })}>
                                        <ZoomInMapIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        onClick={() =>
                                            onFollowVesselClick?.(vessel.id, { lat: vessel.lat, lon: vessel.lon })
                                        }
                                    >
                                        {vessel.id === followedVesselId ? (
                                            <RadioButtonCheckedIcon color="primary" />
                                        ) : (
                                            <RadioButtonUncheckedIcon />
                                        )}
                                    </IconButton>
                                )}
                            </Grid>
                        </Grid>

                        {/* do not show divider for the last vessel */}
                        {index !== vessels.length - 1 && <Divider />}
                    </Fragment>
                ))}
            </VirtualizedList>
        </CollapsibleCard>
    )
}

export default VesselsDetails
