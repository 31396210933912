import axios from "axios"
import fileDownload from "js-file-download"
import * as yup from "yup"
import customToastr from "utils/customToastr"
import { MAX_HOUR, MIN_HOUR } from "./constants"
import { ZanderParameter } from "./models/ZanderParameter"
import { ZanderParameterTypeEnum } from "./models/ZanderParameterTypeEnum"
import { ZanderSheet } from "./models/ZanderSheet"
import sheetsSchema from "./validators/sheetsSchema"

yup.addMethod(yup.array, "unique", function (message, mapper = (a: any) => a) {
    return this.test("unique", message, function (list: any[]) {
        return list?.length === new Set(list?.map(mapper)).size
    })
})

export const parametersDisplayNames = {
    [ZanderParameterTypeEnum.WaveHeight]: "Significant wave height (Hs) [m]",
    [ZanderParameterTypeEnum.WavePeriod]: "Peak wave period (Tp) [s]",
    [ZanderParameterTypeEnum.WindSpeed10]: "Mean wind speed at 10m height [m/s]",
    [ZanderParameterTypeEnum.WindSpeed100]: "Mean wind speed at 100m height [m/s]",
}

const initialPercentiles = [50, 80]

const initialParameters: ZanderParameter[] = [
    {
        maxLimits: [],
        type: ZanderParameterTypeEnum.WindSpeed10,
    },
    {
        maxLimits: [14],
        type: ZanderParameterTypeEnum.WindSpeed100,
    },
    {
        maxLimits: [1.75, 2],
        type: ZanderParameterTypeEnum.WaveHeight,
    },
    {
        maxLimits: [],
        type: ZanderParameterTypeEnum.WavePeriod,
    },
]

const emptyParameters: ZanderParameter[] = [
    {
        maxLimits: [],
        type: ZanderParameterTypeEnum.WindSpeed10,
    },
    {
        maxLimits: [],
        type: ZanderParameterTypeEnum.WindSpeed100,
    },
    {
        maxLimits: [],
        type: ZanderParameterTypeEnum.WaveHeight,
    },
    {
        maxLimits: [],
        type: ZanderParameterTypeEnum.WavePeriod,
    },
]

export const initialSheet: ZanderSheet = {
    endHour: 24,
    parameters: initialParameters,
    percentiles: initialPercentiles,
    weatherWindowDurations: [3, 6, 12],
    startHour: 0,
}

export const emptySheet: ZanderSheet = {
    endHour: 24,
    parameters: emptyParameters,
    percentiles: [],
    weatherWindowDurations: [],
    startHour: 0,
}

export const sanitizeSheets = (sheets: ZanderSheet[]) =>
    sheets.map(sheet => ({
        ...sheet,
        parameters: sheet.parameters.filter(params => params.maxLimits.length > 0),
        percentiles: sheet.percentiles.map(percentile => percentile / 100),
    }))

export const validateSheets = (sheets: ZanderSheet[]) => {
    for (const sheet of sheets) {
        if (sheet.startHour === MIN_HOUR && sheet.endHour === MAX_HOUR) {
            continue
        }

        const maxLength = sheet.endHour - sheet.startHour

        if (sheet.weatherWindowDurations.some(duration => duration > maxLength)) {
            return false
        }
    }

    try {
        sheetsSchema.validateSync(sheets)
        return true
    } catch (error) {
        return false
    }
}

export const linkDownload = (link: string) => {
    const fileName = link.split("/").pop()?.split("?")[0]

    axios
        .get(link, { responseType: "blob" })
        .then(response => {
            fileDownload(response.data, fileName ?? "esoxDownload")
        })
        .catch(_ => customToastr.error("Failed to download the file"))
}
