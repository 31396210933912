import { IconLayer, TextLayer } from "deck.gl"
import config from "config"
import { utils } from "features/ais/components/AisArchivePlayer"
import { boatIcons, boatKeys } from "features/ais/constants"
import { BoatDetails } from "features/ais/models/BoatDetails"
import { BoatPositionWithStartTime } from "features/ais/models/BoatPositionWithStartTime"
import { SubscriptionArchiveData } from "features/ais/models/SubscriptionArchiveData"
import { ArchiveDisplayOptions } from "reducers/ais"
import { getArchiveData, getArchiveDisplayOptions, getBoatDetails } from "selectors/aisSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { getBoatOpacity, shouldDisplayBoat } from "../utils"

const BOAT_SIZE = 20
const INVALID_HEADING = 511

type Props = {
    archiveData: SubscriptionArchiveData
    boatDetails: Record<string, BoatDetails>
    displayOptions: ArchiveDisplayOptions
}

const subscriptionArchiveDataToIconLayer = ({ archiveData, boatDetails, displayOptions }: Props) => {
    const { boatsDictionary, currentBucket, previousBucket, skipTransition, subscriptionId } = archiveData

    const textDisplay = (bp: BoatPositionWithStartTime) => {
        let text = ""
        if (displayOptions.vesselName) text += boatDetails[bp.boatDetailsId]?.name ?? ""
        if (displayOptions.speed) {
            const speed = bp.speed.toFixed(1) ?? ""
            text += text === "" ? `${speed} kn` : `\n${speed} kn`
        }
        return text
    }

    const previousBucketDictionary: Record<string, BoatPositionWithStartTime> =
        previousBucket?.boatPositions.reduce((acc: Record<string, any>, bp) => {
            acc[utils.getBoatIdentifier(bp)] = bp

            return acc
        }, {}) ?? {}

    const dictionary = { ...boatsDictionary }

    for (const boatPosition of currentBucket.boatPositions) {
        dictionary[utils.getBoatIdentifier(boatPosition)] = { ...boatPosition, startTime: currentBucket.startDate }
    }

    const iconLayer = new IconLayer<BoatPositionWithStartTime>({
        data: Object.values(dictionary),
        // heading = 511 -> No heading data
        getAngle: bp => (bp.heading === null || bp.heading === INVALID_HEADING ? 360 - bp.course : 360 - bp.heading),
        // only the alpha value is relevant
        getColor: bp => [0, 0, 0, getBoatOpacity(bp, currentBucket.startDate, archiveData.maxBoatAge)],
        getIcon: bp => {
            const boatType = boatDetails[bp.boatDetailsId]?.aisTypeSummary ?? "Unspecified"
            return boatIcons[boatType] ?? boatIcons[boatKeys.OTHER]
        },
        getPosition: bp => [bp.lon, bp.lat],
        getSize: bp => (shouldDisplayBoat(bp, previousBucketDictionary, currentBucket.startDate) ? BOAT_SIZE : 0),
        iconAtlas: `${config.apiUrl}sprite/.png`,
        iconMapping: `${config.apiUrl}sprite/.json`,
        // the color part is ignored since we are using colored icons
        id: `${subscriptionId}-archive`,
        pickable: true,
        transitions: skipTransition
            ? undefined
            : {
                  getPosition: {
                      duration: archiveData.intervalBetweenLayersInMs,
                      type: "interpolation",
                  },
              },
    })

    const textLayer = new TextLayer({
        data: Object.values(dictionary),
        fontFamily: "'Nunito Sans', sans-serif",
        fontSettings: {
            cutoff: 0.24,
            fontSize: 26,
            radius: 20,
            sdf: true,
        },
        getColor: [0, 0, 0, 255],
        getPixelOffset: [15, 0],
        getPosition: (bp: { lat: number; lon: number }) => [bp.lon, bp.lat],
        getSize: bp => (shouldDisplayBoat(bp, previousBucketDictionary, currentBucket.startDate) ? 11 : 0),
        getText: bp => textDisplay(bp),
        getTextAnchor: "start",
        id: `${subscriptionId}-archive-text`,
        outlineColor: [255, 255, 255, 200],
        outlineWidth: 3,
        transitions: skipTransition
            ? undefined
            : {
                  getPosition: {
                      duration: archiveData.intervalBetweenLayersInMs,
                      type: "interpolation",
                  },
              },
    })

    return [iconLayer, textLayer]
}

const useAisArchiveLayers = () => {
    const archiveData = useAppSelector(getArchiveData)
    const boatDetails = useAppSelector(getBoatDetails)
    const displayOptions = useAppSelector(getArchiveDisplayOptions)
    return archiveData ? [...subscriptionArchiveDataToIconLayer({ archiveData, boatDetails, displayOptions })] : []
}

export default useAisArchiveLayers
