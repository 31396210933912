import React, { ComponentProps, FCWC, HTMLProps } from "react"
import { Button } from "@mui/material"
import clsx from "clsx"
import { Link } from "react-router-dom"
import { useStyles } from "./styles"

type Props = {
    className?: string
    innerRef?: any
    value?: string
} & ComponentProps<typeof Link>

const AnchorElement = React.forwardRef((props: HTMLProps<HTMLAnchorElement>, ref: any) => {
    return <a ref={ref} {...props}></a>
})

const ButtonLink: FCWC<Props> = ({ children, className, innerRef, ...props }) => {
    const classes = useStyles()
    const externalLink = props.to.toString().startsWith("http://") || props.to.toString().startsWith("https://")

    return externalLink ? (
        <Button className={clsx(classes.link, className ?? "")} component={AnchorElement} href={props.to.toString()}>
            {children}
        </Button>
    ) : (
        <Link className={clsx(classes.link, className ?? "")} ref={innerRef} {...props}>
            {children}
        </Link>
    )
}

export default React.forwardRef((props: Props, ref: any) => <ButtonLink {...props} innerRef={ref} />)
