import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { WritableDraft } from "immer/dist/types/types-external"
import { getApp } from "actions/apps"
import { BoatDetails } from "features/ais/models/BoatDetails"
import { BoatDetailsInfobox } from "features/ais/models/BoatDetailsInfobox"
import { SubscriptionArchiveData } from "features/ais/models/SubscriptionArchiveData"
import { getSubscriptions } from "../actions/ais"
import { resetProjectData } from "../actions/globalActions"
import { AIS } from "../components/sidebar/ais/utils"
import { Boat } from "../model/ais/Boat"
import { Subscription } from "../model/ais/Subscription"

export type SubscriptionDataEntry = {
    boats: Boat[]
    visibility: "none" | "visible"
}

export type ArchiveDisplayOptions = {
    mmsi: boolean
    speed: boolean
    vesselName: boolean
}

type SliceState = {
    archiveData: SubscriptionArchiveData | null
    archiveDisplayOptions: ArchiveDisplayOptions
    areAISPlaybackBoatsOnMap: boolean
    boatDetailsDictionary: Record<string, BoatDetails>
    loadingInfo: boolean
    pickedArchiveData: BoatDetailsInfobox[]
    selectedArchiveSubscriptionId: string | null
    selectedVesselHasNoData: boolean
    subscriptionData: Record<string, SubscriptionDataEntry>
    subscriptionInfo: Subscription[]
    isAisDefaultVisible: boolean
    isPlaybackPlaying: boolean
}

const initialState: SliceState = {
    archiveData: null,
    archiveDisplayOptions: {
        mmsi: false,
        speed: false,
        vesselName: true,
    },
    areAISPlaybackBoatsOnMap: false,
    boatDetailsDictionary: {},
    loadingInfo: false,
    pickedArchiveData: [],
    selectedArchiveSubscriptionId: null,
    selectedVesselHasNoData: false,
    subscriptionData: {},
    subscriptionInfo: [],
    isAisDefaultVisible: false,
    isPlaybackPlaying: false,
}

export const aisSlice = createSlice({
    extraReducers: builder =>
        builder
            .addCase(getSubscriptions.fulfilled, (state, { payload: subscriptions }) => {
                state.subscriptionInfo = subscriptions
                state.loadingInfo = false

                subscriptions.forEach(sub => {
                    state.subscriptionData[sub.id] = {
                        boats: state.subscriptionData[sub.id]?.boats ?? [],
                        visibility: state.isAisDefaultVisible ? "visible" : "none",
                    }
                })
            })
            .addCase(getSubscriptions.pending, state => {
                state.loadingInfo = true
            })
            .addCase(resetProjectData, () => initialState)
            .addCase(getApp.fulfilled, (state, { payload: { isAisDefaultVisible, ..._ } }) => {
                state.isAisDefaultVisible = isAisDefaultVisible
            }),
    initialState,
    name: AIS,
    reducers: {
        hideAllSubs: state => {
            hideAll(state)
        },
        updateArchiveDisplayOptions: (state, { payload }: PayloadAction<ArchiveDisplayOptions>) => {
            state.archiveDisplayOptions = payload
        },
        updateBoatDetails: ({ boatDetailsDictionary }, { payload: newBoatsDetails }: PayloadAction<BoatDetails[]>) => {
            newBoatsDetails.forEach((boatDetails: BoatDetails) => {
                if (!boatDetailsDictionary[boatDetails.id]) boatDetailsDictionary[boatDetails.id] = boatDetails
            })
        },
        setArchiveData: (
            state,
            { payload: subscriptionArchiveData }: PayloadAction<SubscriptionArchiveData | null>,
        ) => {
            state.archiveData = subscriptionArchiveData
        },
        setAreAISPlaybackBoatsOnMap: (state, { payload }) => {
            state.areAISPlaybackBoatsOnMap = payload
        },
        setBoatDetails: (state, { payload }: PayloadAction<Record<string, BoatDetails>>) => {
            state.boatDetailsDictionary = payload
        },
        setBoats: (state, { payload }) => {
            const subscriptionToUpdate = state.subscriptionInfo.find(sub => sub.id === payload.subscriptionId)

            if (!subscriptionToUpdate || !subscriptionToUpdate?.isEnabled) {
                return state
            }

            subscriptionToUpdate.latestUpdateUtc = payload.latestUpdateUtc[0]

            state.subscriptionData[payload.subscriptionId] = {
                ...state.subscriptionData[payload.subscriptionId],
                boats: payload.boats,
            }
        },
        setIsPlaybackPlaying: (state, { payload }) => {
            state.isPlaybackPlaying = payload
            if (payload) state.pickedArchiveData = []
        },
        setPickedArchiveData: (state, { payload }) => {
            state.pickedArchiveData = payload
        },
        setSelectedArchiveSubscriptionId: (state, { payload }) => {
            state.selectedArchiveSubscriptionId = payload
            if (payload === null) state.pickedArchiveData = []
        },
        setSelectedVesselHasNoData: (state, { payload }) => {
            state.selectedVesselHasNoData = payload
        },
        setSubVisibility: (state, { payload: { subscriptionId, isVisible } }) => {
            state.subscriptionData[subscriptionId].visibility = isVisible ? "visible" : "none"
        },
    },
})

const hideAll = (state: WritableDraft<SliceState>) => {
    Object.keys(state.subscriptionData).forEach(subId => {
        state.subscriptionData[subId].visibility = "none"
    })
}

export const {
    hideAllSubs,
    updateArchiveDisplayOptions,
    updateBoatDetails,
    setArchiveData,
    setAreAISPlaybackBoatsOnMap,
    setBoatDetails,
    setBoats,
    setIsPlaybackPlaying,
    setPickedArchiveData,
    setSelectedArchiveSubscriptionId,
    setSelectedVesselHasNoData,
    setSubVisibility,
} = aisSlice.actions

export default aisSlice.reducer
