import { useContext, useMemo } from "react"
import { MapHandlersContext } from "components/map/contexts/MapHandlersContext/MapHandlersContext"
import {
    HandlerDict,
    MapEvents,
    ScopedPopHandlerFT,
    ScopedPushHandlerFT,
    ScopedPushHandlersFT,
} from "components/map/contexts/MapHandlersContext/types"
import { MapIds } from "model/enums/MapIds"

type UseMapHandlersParams = {
    mapId: MapIds
}

export const useMapHandlers = ({ mapId }: UseMapHandlersParams) => {
    const { popHandler, pushHandler, pushHandlers, handlerStacksStore } = useContext(MapHandlersContext)

    // We take the handler stack and return a dict with the top
    // handler function for each handler type. In case we have an index out of
    // bounds or a undefined stack handler, it's ok to return undefined because
    // this represents the absence of a handler.
    const handlers = useMemo(() => {
        return Object.keys(handlerStacksStore[mapId]).reduce((acc: Record<any, any>, untypedEvent) => {
            const event = untypedEvent as MapEvents
            const handlerStack = handlerStacksStore[mapId][event]
            acc[event] = handlerStack?.[handlerStack.length - 1]
            return acc
        }, {}) as HandlerDict
    }, [handlerStacksStore[mapId]])

    const scopedPushHandler: ScopedPushHandlerFT = (...params) => pushHandler(mapId, ...params)
    const scopedPushHandlers: ScopedPushHandlersFT = (...params) => pushHandlers(mapId, ...params)

    const scopedPopHandler: ScopedPopHandlerFT = (...params) => popHandler(mapId, ...params)

    return { popHandler: scopedPopHandler, pushHandler: scopedPushHandler, pushHandlers: scopedPushHandlers, handlers }
}
