import { FC, useMemo, useRef } from "react"
import { AnySource, Layer, useMap } from "@emblautec/react-map-gl"
import { toMapboxFilters } from "components/map/utils/mapFilterUtils"
import { StyleType } from "model/enums/StyleType"
import { MapFilter } from "model/map/MapFilterType"
import { MapLayer as LayerType } from "model/map/MapLayer"
import PolygonLabelLayer from "../PolygonLabelLayer/PolygonLabelLayer"

type HOCProps = {
    filters?: MapFilter[]
    layoutProperties?: Object[]
    maxZoom: number
    minZoom: number
    paintProperties?: Object[]
    polygonLayerId?: string // This is passed for symbol styles, in case they need to be shown as polygon labels
    source?: AnySource | string // Can be passed by the Source wrapper
} & LayerType

// A small HOC for interacting with the map context. This enables us to use memo on the layer and
// improve performance
const withBeforeIdCheckHOC = (Component: FC<MapLayerProps>) => (props: HOCProps) => {
    const { current } = useMap()
    const { drawUnderId } = props

    const beforeId = useRef<string | undefined>(undefined)

    if (beforeId.current !== drawUnderId && drawUnderId && current?.getLayer(drawUnderId)) {
        beforeId.current = drawUnderId
    }

    return <Component {...props} beforeId={beforeId.current} />
}

type MapLayerProps = { beforeId?: string } & Omit<HOCProps, "drawBeforeId">

// Wrapper component for the @emblautec/react-map-gl Layer component. This will decide the order of the layers
// and if to render symbols as polygon layers

// Chose to pass individual values instead of the layer object for easier memo comparisons
const MapLayer: FC<MapLayerProps> = ({
    beforeId,
    filters,
    layoutProperties,
    maxZoom,
    minZoom,
    paintProperties,
    polygonLayerId,
    source,
    ...layerProps
}) => {
    const { layerId, resourceId, sourceId, sourceName, type } = layerProps

    const filter = useMemo(() => toMapboxFilters(filters), [filters])

    const paint = useMemo(
        () =>
            paintProperties?.reduce((acc: Record<string, string>, prop: any) => {
                if (prop.title === "Legend") return acc
                acc[prop.name] = prop.value
                return acc
            }, {}),
        [paintProperties],
    )

    const layout = useMemo(
        () =>
            layoutProperties?.reduce((acc: Record<string, string>, prop: any) => {
                acc[prop.name] = prop.value
                return acc
            }, {}),
        [layoutProperties],
    )

    if (type === StyleType.Symbol && polygonLayerId) {
        return (
            <PolygonLabelLayer
                key={layerId}
                beforeId={beforeId}
                layerId={layerId}
                layout={layout}
                maxZoom={maxZoom}
                minZoom={minZoom}
                polygonLayerId={polygonLayerId}
            />
        )
    }

    return (
        <Layer
            // This key is very important since it controls the instance of the component.
            // We have to remove the layer and add a new one when the type changes
            key={type.toString()}
            beforeId={beforeId}
            filter={filter}
            // -------------
            id={layerId}
            layout={layout}
            maxzoom={maxZoom}
            minzoom={minZoom}
            paint={paint}
            source={source || sourceId}
            // TODO: fix type
            type={type as any}
            {...(sourceName && { "source-layer": sourceName })}
            metadata={{ resourceId }}
        />
    )
}

export default withBeforeIdCheckHOC(MapLayer)
