import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import { zoomLevelValidator } from "../../../utils/validators/zoomLevelValidator.js"

const ZoomValueTextField = ({ value, onTitleChanged }) => {
    const [formValue, setFormValue] = useState(value)
    const [formError, setFormError] = useState()

    useEffect(() => {
        if (value !== formValue) processValue(value, true)
    }, [value])

    const processValue = (value, initing = false) => {
        const valueToProcess = value === "" ? undefined : value
        setFormValue(value)
        zoomLevelValidator
            .validate(valueToProcess)
            .then(() => {
                setFormError()
                if (!initing) onTitleChanged(value.toString())
            })
            .catch(err => {
                setFormError(err.errors[0])
            })
    }

    const onChange = e => {
        processValue(e.target.value)
    }

    return (
        <TextField
            className="textfield"
            error={!!formError}
            fullWidth
            helperText={formError}
            label="Zoom Level"
            value={formValue}
            variant="standard"
            onChange={onChange}
        />
    )
}

export default ZoomValueTextField
