import { DefaultTheme } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"

const legendSize = 26

const lineHeight = 6

const pointSize = 6

const lineDistanceToMiddle = (legendSize - lineHeight) / 2

const pointMarginOffset = 2.5 // In order to avoid weird overlaps

export const useStyles = makeStyles<
    DefaultTheme,
    { circleColor?: string; lineColor?: string; lineDashArray?: Array<number> }
>({
    circle: props => ({
        backgroundColor: props.circleColor,
        borderRadius: 100,
        height: pointSize,
        position: "absolute",
        width: pointSize,
    }),
    left: {
        bottom: 9,
        left: 0,
    },
    legend: {
        height: legendSize,
        position: "relative",
        width: legendSize,
    },
    line: props => ({
        height: lineHeight,
        left: !!props.circleColor ? pointMarginOffset : 0,
        position: "absolute",
        right: !!props.circleColor ? pointMarginOffset : 0,
        top: lineDistanceToMiddle,
    }),
    right: {
        bottom: 9,
        right: 0,
    },
})
