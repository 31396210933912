import dayjs from "dayjs"
import "dayjs/plugin/utc"
import { AisMapLayer } from "model/map/AisMapLayer"
import { GeoJsonSource } from "model/map/Source"

export const AIS = "AIS"
export const AIS_TITLE = "Vessel Position"
export const POSITION_RECEIVED_PROPERTY = "TIMESTAMP"
export const INVALID_DATE_STRING = "0001-01-01T00:00:00+00:00"
export const DATE_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2}|Z)/g

const zoomThreshold = 6

export const mapSubToGeoJsonSource = (id: string, boats: any[]): GeoJsonSource => {
    return {
        data: mapBoatsToGeoJson(boats),
        id,
        maxZoom: 24,
        type: "geojson",
    }
}

export const mapBoatsToGeoJson = (boats: any[]) => {
    return {
        features: boats.map(boat => mapBoatToFeature(boat)),
        type: "FeatureCollection",
    } as GeoJSON.FeatureCollection<GeoJSON.Point>
}

const mapBoatToFeature = (boat: any) => {
    const speed = Number(boat.SPEED) >= 0 ? ` ${Number(boat.SPEED)?.toFixed(1)} kn` : ""

    const elapsedTimeSinceLastPosition = dayjs().diff(dayjs(boat.TIMESTAMP ?? dayjs()), "minute")
    const shouldDisplayTime = boat.TIMESTAMP && elapsedTimeSinceLastPosition >= 5
    const hours = Math.floor(elapsedTimeSinceLastPosition / 60)
    const minutes = elapsedTimeSinceLastPosition % 60
    const formattedTime = shouldDisplayTime
        ? ` ${String(hours).padStart(2, "0")}h ${String(minutes).padStart(2, "0")}min`
        : ""

    let textField = `${boat.NAME || boat.MMSI}\n${formattedTime}\n${speed}`
    let iconOpacity = isShipOld(boat.TIMESTAMP) ? 0.3 : 1
    const iconImage = mapBoatTypeToColor(boat.AIS_TYPE_SUMMARY)

    //Marine Traffic returns 511 in the heading property for vessels with unknown heading.
    //In that case we show course instead
    const heading = boat.HEADING === "511" ? boat.COURSE : boat.HEADING

    return {
        geometry: {
            coordinates: [boat.LON, boat.LAT],
            type: "Point",
        },
        id: `${boat.SHIP_ID}-${boat.MMSI}`,
        properties: {
            ...boat,
            "icon-image": iconImage,
            "icon-opacity": iconOpacity,
            // These are used for the style
            "icon-rotate": parseInt(heading),
            "text-field": textField,
        },
        type: "Feature",
    }
}

const isShipOld = (timestamp: string) => {
    const ONE_MINUTE = 60 * 1000 /* ms */
    let shipTimeUtc = dayjs.utc(timestamp)
    let nowUtc = dayjs.utc()

    return nowUtc.diff(shipTimeUtc) > 30 * ONE_MINUTE
}

export const mapSubToLayer = (subId: string) => {
    return {
        layerId: subId,
        sourceId: subId,
        type: "symbol" as const,
    } as AisMapLayer
}

export const mapSubToLayout = (subId: string) => {
    return {
        layerId: subId,
        properties: [
            { name: "visibility", value: "none" },
            { name: "icon-image", value: ["get", "icon-image"] },
            { name: "icon-rotate", value: ["get", "icon-rotate"] },
            { name: "icon-rotation-alignment", value: "map" },
            {
                name: "icon-size",
                value: [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    // zoom is 5 (or less) -> icon size will be 0.5
                    5,
                    0.5,
                    // zoom is 10 (or greater) -> icon size will be 1
                    10,
                    1,
                ],
            },
            { name: "text-field", value: ["step", ["zoom"], "", zoomThreshold, ["get", "text-field"]] },
            { name: "text-variable-anchor", value: ["left", "right", "top", "bottom"] },
            { name: "text-radial-offset", value: 1 },
            { name: "text-justify", value: "auto" },
            { name: "text-size", value: 10 },

            { name: "icon-allow-overlap", value: true },
            { name: "text-optional", value: true },
        ],
    }
}

export const mapSubToPaint = (subId: string) => {
    return {
        layerId: subId,
        properties: [
            { name: "icon-opacity", value: ["get", "icon-opacity"] },
            { name: "text-halo-color", value: "white" },
            { name: "text-halo-blur", value: 0.5 },
            { name: "text-halo-width", value: 1 },
        ],
    }
}

export const mapSubToZoomRange = (subId: string) => {
    return {
        layerId: subId,
        maxZoom: 24,
        minZoom: 0,
    }
}

export const getTypeDescription = (type: string) => {
    const descriptions = {
        CustomArea: "Vessel Positions in a Custom Area",
        DynamicBoundingBox: "Vessel Positions in a Dynamic Bounding Box",
        DynamicFleet: "Vessel Positions of a Dynamic Fleet",
        LiveData: "Vessel Positions in a Predefined Bounding Box",
        Port: "Vessel Positions within a Port",
        PredefinedBoundingBox: "Vessel Positions in a Predefined Bounding Box",
        SingleVessel: "Single Vessel Positions",
        SingleVesselPositions: "Single vessel positions",
        StaticFleet: "Vessel Positions of a Static Fleet",
        VesselPositions: "Vessel positions",
        VesselPositionsAreaOfInterest: "Vessel positions in area of interest",
    } as const

    return descriptions[type as keyof typeof descriptions] as string
}

const mapBoatTypeToColor = (boatType: string) => {
    let boatIcon = "boat-15"

    const boatTypeToImage = {
        Cargo: `${boatIcon}-green`,
        Fishing: `${boatIcon}-orange`,
        "High-Speed Craft": `${boatIcon}-yellow`,
        "Navigation Aid": `navigational-aid`,
        Passenger: `${boatIcon}-blue`,
        "Pleasure Craft": `${boatIcon}-purple`,
        "Special Craft": `${boatIcon}-teal`,
        Tanker: `${boatIcon}-red`,
        Tug: `${boatIcon}-teal`,
    } as const

    return (boatTypeToImage[boatType as keyof typeof boatTypeToImage] as string) || boatIcon
}

export const getRenamedPropertyKey = (key: string) => {
    const values = {
        ais_type_summary: "AIS type summary",
        AIS_TYPE_SUMMARY: "AIS type summary",
        aisTypeSummary: "AIS type summary",
        AVG_SPEED: "Average speed",
        boatDetailsId: "Boat details ID",
        callSign: "Call sign",
        CALLSIGN: "Call sign",
        course: "Course",
        COURSE: "Course",
        createdAt: "Created at",
        current_port: "Current port",
        CURRENT_PORT: "Current port",
        current_port_country: "Current port country",
        CURRENT_PORT_COUNTRY: "Current port country",
        current_port_id: "Current port ID",
        CURRENT_PORT_ID: "Current port ID",
        current_port_unlocode: "Current port UN/LOCODE",
        CURRENT_PORT_UNLOCODE: "Current port UN/LOCODE",
        destination: "Destination",
        DESTINATION: "Destination",
        draught: "Draught",
        DRAUGHT: "Draught",
        dwt: "Deadweight tonnage",
        DWT: "Deadweight tonnage",
        eca: "ECA",
        ECA: "ECA",
        eta: "ETA",
        ETA: "ETA",
        flag: "Flag",
        FLAG: "Flag",
        grt: "Gross register tonnage",
        GRT: "Gross register tonnage",
        heading: "Heading",
        HEADING: "Heading",
        id: "ID",
        imo: "IMO",
        IMO: "IMO",
        last_port: "Last port",
        LAST_PORT: "Last port",
        last_port_country: "Last port country",
        LAST_PORT_COUNTRY: "Last port country",
        last_port_id: "Last port ID",
        LAST_PORT_ID: "Last port ID",
        last_port_time: "Last port Time",
        LAST_PORT_TIME: "Last port Time",
        last_port_unlocode: "Last port UN/LOCODE",
        LAST_PORT_UNLOCODE: "Last port UN/LOCODE",
        lat: "Latitude",
        LAT: "Latitude",
        length: "Length",
        LENGTH: "Length",
        locode: "LOCODE",
        LOCODE: "LOCODE",
        lon: "Longitude",
        LON: "Longitude",
        market: "Market",
        MAX_SPEED: "Maximum speed",
        mmsi: "MMSI",
        MMSI: "MMSI",
        name: "Name",
        NAME: "Name",
        NAVSTAT: "NAVSTAT",
        next_port_country: "Next port country",
        NEXT_PORT_COUNTRY: "Next port country",
        next_port_id: "Next port ID",
        NEXT_PORT_ID: "Next port ID",
        next_port_name: "Next port name",
        NEXT_PORT_NAME: "Next port name",
        next_port_unlocode: "Next port UN/LOCODE",
        NEXT_PORT_UNLOCODE: "Next port UN/LOCODE",
        ROT: "ROT",
        SHIP_ID: "Ship ID",
        speed: "Speed",
        SPEED: "Speed",
        SRC: "SRC",
        status: "Status",
        STATUS: "Status",
        subscription_id: "Subscription ID",
        TIMESTAMP: "Position Received (UTC)",
        type: "Type",
        TYPE: "Type",
        TYPE_DESCRIPTION: "Type description",
        type_name: "Type name",
        TYPE_NAME: "Type name",
        UTC_SECONDS: "UTC seconds",
        width: "Width",
        WIDTH: "Width",
        year_built: "Year of built",
        YEAR_BUILT: "Year of built",
        zone: "Zone",
        ZONE: "Zone",
    }

    // if key is not found in values, return the key itself
    return values[key as keyof typeof values] ?? key
}
