import PauseIcon from "@mui/icons-material/Pause"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import ReplayIcon from "@mui/icons-material/Replay"
import { Fab, Slider, Stack } from "@mui/material"

type Props = {
    getLabelText: (value: number) => string
    step: number
    value: number
    isPlaying: boolean
    shouldShowReplay: boolean
    onChange: (newValue: number) => void
    onPlayToggle: () => void
    onReplay: () => void
}

const ArchivePlayerControl = ({
    getLabelText,
    step,
    value,
    isPlaying,
    shouldShowReplay,
    onChange,
    onPlayToggle,
    onReplay,
}: Props) => (
    <Stack alignItems={"center"} direction="row" gap={2}>
        <Fab color="primary" size="small" onClick={shouldShowReplay ? onReplay : onPlayToggle}>
            {shouldShowReplay ? <ReplayIcon /> : isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </Fab>
        <Stack flex={1}>
            <Slider
                size="medium"
                step={step}
                value={value}
                valueLabelDisplay="auto"
                valueLabelFormat={getLabelText}
                onChange={(_, newValue: number | number[]) => onChange(newValue as number)}
            />
        </Stack>
    </Stack>
)

export default ArchivePlayerControl
