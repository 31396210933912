import { GuidMap } from "common/types/GuidMap"
import { AisMapLayer } from "model/map/AisMapLayer"
import { getAisLayers, getAisLayouts, getAisPaints, getAisZoomRanges } from "selectors/mapSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"

export type AisLayersData = {
    aisLayers: AisMapLayer[]
    aisLayoutsDict: GuidMap<{
        layerId: string
        properties: any[]
    }>
    aisPaintsDict: GuidMap<{
        layerId: string
        properties: any[]
    }>
    aisZoomRangesDict: GuidMap<{ layerId: string; maxZoom: number; minZoom: number }>
}

export const useAisLayers = () => {
    const aisLayers = useAppSelector(getAisLayers)
    const aisPaintsDict = useAppSelector(getAisPaints)
    const aisLayoutsDict = useAppSelector(getAisLayouts)
    const aisZoomRangesDict = useAppSelector(getAisZoomRanges)

    return { aisLayers, aisLayoutsDict, aisPaintsDict, aisZoomRangesDict }
}
