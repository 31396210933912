import { useEffect, useState } from "react"
import { MapStyleService } from "components/map/utils/basemapUtils"
import blankBasemapStyle from "constants/map/blankBasemapStyle"
import { Source } from "model/map/Source"
import { Basemap } from "model/mapComponent/Basemap"
import { handleError } from "../../networkErrorUtils"

type ExtraData = {
    layers: any[]
    layoutsDict: any
    paintsDict: any
    sources: Source[]
    zoomRangesDict: any
    isPublic: boolean
}

type Params = {
    basemap: any
    extraData?: ExtraData
}

export const useMapStyle = ({ basemap, extraData }: Params) => {
    const [mapStyle, setMapStyle] = useState<Basemap>(blankBasemapStyle)

    useEffect(() => {
        new MapStyleService(extraData)
            .getBasemapStyle(basemap!)
            .then(res => {
                setMapStyle(res)
            })
            .catch(handleError)
    }, [basemap])

    return { mapStyle, setMapStyle }
}
