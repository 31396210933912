import { useMemo, useState } from "react"
import BuildIcon from "@mui/icons-material/Build"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DownloadIcon from "@mui/icons-material/Download"
import { IconButton, Tooltip } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import axiosClient from "actions/apiClient"
import { iframeCopyToClipboardUrl } from "components/map/utils/iframeCopyToClipboardUrl"
import ZanderModal from "features/zander/components/ZanderModal/ZanderModal"
import { EXCEL_GENERATING_ID } from "features/zander/constants"
import useZander from "features/zander/hooks/useZander"
import { ZanderResponse } from "features/zander/models/ZanderReponse"
import { ZanderRequest } from "features/zander/models/ZanderRequest"
import { ZanderSheet } from "features/zander/models/ZanderSheet"
import { linkDownload } from "features/zander/utils"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import customToastr, { createManagedToastr } from "utils/customToastr"
import { useEraInfoboxStyles } from "./styles"

type Props = {
    landSeaMask: number
    lat: number
    lng: number
    isZanderModalDefaultOpen?: boolean
    onZanderModalClose?: () => void
}

const EsoxInfobox = ({ landSeaMask, lat, lng, isZanderModalDefaultOpen, onZanderModalClose }: Props) => {
    const classes = useEraInfoboxStyles()
    const [isZanderToolOpen, setIsZanderToolOpen] = useState(!!isZanderModalDefaultOpen)
    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch()
    const managedToastr = useMemo(() => createManagedToastr(dispatch), [])

    const connectionId = useZander(response => {
        setLoading(false)
        managedToastr.remove(EXCEL_GENERATING_ID)

        if (response.error) {
            customToastr.error(response.errorMessage)
            return
        }
        customToastr.info("Your download should be starting soon")
        for (const link of response.documentLinks) {
            linkDownload(link)
        }
    })

    const location = {
        lat: lat.toFixed(2),
        lng: lng.toFixed(2),
    }
    const onDownload = () => onZanderRequest([], true, true)

    const onDownloadMetaOnly = () => onZanderRequest([], true, false)

    const onCopyLink = () => {
        let queryParam = `lat=${location.lat}&lng=${location.lng}`

        iframeCopyToClipboardUrl(queryParam)

        customToastr.success("The text has been copied")
    }

    const onZanderRequest = (sheets: ZanderSheet[] = [], downloadData = false, downloadTool = false) => {
        if (!connectionId) {
            customToastr.error("Server connection lost")
            return
        }

        setLoading(true)

        const request: ZanderRequest = {
            connectionId,
            generateExcel: sheets.length !== 0,
            lat,
            long: lng,
            sheets,
            downloadData,
            downloadTool,
        }

        axiosClient
            .post<ZanderResponse>("zander", request)
            .then(response => {
                const zanderResponse = response.data

                if (zanderResponse.error) {
                    customToastr.error(zanderResponse.errorMessage)
                    setLoading(false)
                } else {
                    sheets.length !== 0 && managedToastr.info(EXCEL_GENERATING_ID, "Generating file")
                }
            })
            .catch(() => {
                setLoading(false)
                customToastr.error("Failed to download file")
            })
    }

    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <CustomTypography fontWeight="bold" variant="h3">
                        Download
                    </CustomTypography>
                </div>
                <div className={classes.content}>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1">Latitude</CustomTypography>
                        <CustomTypography variant="subtitle1"> {lat} </CustomTypography>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1">Longitude</CustomTypography>
                        <CustomTypography variant="subtitle1"> {lng} </CustomTypography>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography variant="subtitle1">Land-sea mask</CustomTypography>
                        <CustomTypography variant="subtitle1"> {landSeaMask} </CustomTypography>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography style={{ flexGrow: 1 }} variant="subtitle1">
                            Copy direct link to data point:
                        </CustomTypography>
                        <Tooltip key="copyTT" placement="left" title="Copy link">
                            <IconButton className={classes.squareBtn} color="secondary" onClick={onCopyLink}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography style={{ flexGrow: 1 }} variant="subtitle1">
                            Download metocean data + ESOX tool:
                        </CustomTypography>
                        <Tooltip key="copyTT" placement="left" title="Download">
                            <IconButton className={classes.squareBtn} color="secondary" onClick={onDownload}>
                                <DownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography sx={{ flexGrow: 1 }} variant="subtitle1">
                            Download metocean data
                        </CustomTypography>
                        <Tooltip key="copyTT" placement="left" title="Download">
                            <IconButton className={classes.squareBtn} color="secondary" onClick={onDownloadMetaOnly}>
                                <DownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.item}>
                        <CustomTypography sx={{ flexGrow: 1 }} variant="subtitle1">
                            ESOX Zander weather window statistics
                        </CustomTypography>
                        <Tooltip key="copyTT" placement="left" title="Zander Tool">
                            <IconButton
                                className={classes.squareBtn}
                                color="secondary"
                                onClick={() => setIsZanderToolOpen(true)}
                            >
                                <BuildIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <ZanderModal
                open={isZanderToolOpen}
                generateButtonDisabled={loading || !connectionId}
                onClose={() => {
                    onZanderModalClose?.()
                    setIsZanderToolOpen(false)
                }}
                onZanderRequest={onZanderRequest}
            />
        </>
    )
}

export default EsoxInfobox
