import { useCallback, useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { availableURLSearchParams } from "../utils/navigationUtils"

export type UsableSearchParamsKeys = keyof typeof availableURLSearchParams

export type SearchParamToSet = {
    searchParamKey: UsableSearchParamsKeys
    searchParamValue: string
}

export type UpdateSearchParams = {
    remove?: UsableSearchParamsKeys[]
    set?: SearchParamToSet[]
}

const useSearchParams = () => {
    const { search } = useLocation()

    const history = useHistory()

    const searchParams = useMemo(() => new URLSearchParams(search), [search])

    const currentSearchParams = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams])

    const setSearchParams = useCallback(
        (searchParams: Record<string, string>) =>
            history.replace({ search: new URLSearchParams(searchParams).toString() }),
        [history],
    )

    /**
     * Use this when you need access to a search param without updates
     * @param searchParamKey - search param you want to get
     * @param options - options to be taken into account when executing the function
     */
    const getSearchParamValue = (
        searchParamKey: UsableSearchParamsKeys,
        options: { includeDefaultValue?: boolean } | undefined = { includeDefaultValue: true },
    ) => {
        if (options?.includeDefaultValue) {
            return searchParams.get(searchParamKey) ?? availableURLSearchParams[searchParamKey].defaultValue
        }
        return searchParams.get(searchParamKey)
    }

    /**
     * Use this function to update search params
     * @param params - an object containing search params to delete and search params to set
     * @param params.remove - array of search params to remove
     * @param params.set - array of search params to set
     */
    const updateSearchParams = ({ remove: paramsToRemove, set: paramsToSet }: UpdateSearchParams) => {
        paramsToRemove?.forEach(param => {
            delete currentSearchParams[param]
        })

        paramsToSet?.forEach(({ searchParamKey, searchParamValue }) => {
            currentSearchParams[searchParamKey] = searchParamValue
        })

        setSearchParams(currentSearchParams)
    }

    return { getSearchParamValue, searchParams: currentSearchParams, updateSearchParams }
}

export default useSearchParams
