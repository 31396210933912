import { useMemo } from "react"
import { useSelector } from "react-redux"
import { AppLayer } from "model/app/AppLayer"
import { HelperLayersIds } from "model/enums/HelperLayers"
import { StyleType } from "model/enums/StyleType"
import { MapLayer } from "model/map/MapLayer"
import { Style } from "model/style/Style"
import { getLayerGroups, getLayerStylesMap } from "selectors/layerSelector"
import { getLayers } from "selectors/mapSelectors"

export const useOrderedMapLayers = () => {
    const unorderedLayers = useSelector(getLayers)
    const layerGroups = useSelector(getLayerGroups)
    const stylesDict = useSelector(getLayerStylesMap)

    const stylesBeforeIdDict = useMemo(() => {
        const stylesBeforeIdDict2: Record<string, string> = {}

        let symbolLastId: string = HelperLayersIds.SymbolStartLayer
        let geometricLastId: string = HelperLayersIds.GeometricStartLayer

        layerGroups.forLayersRecursive((layer: AppLayer) => {
            stylesDict[layer.resourceId]?.forEach((s: Style) => {
                if (s.type === StyleType.Symbol) {
                    stylesBeforeIdDict2[s.styleId] = symbolLastId
                    symbolLastId = s.styleId
                } else {
                    stylesBeforeIdDict2[s.styleId] = geometricLastId
                    geometricLastId = s.styleId
                }
            })
        })
        return stylesBeforeIdDict2
    }, [stylesDict, layerGroups])

    const orderedLayers = useMemo<MapLayer[]>(() => {
        return unorderedLayers.map((mapLayer: MapLayer) => ({
            ...mapLayer,
            drawUnderId: stylesBeforeIdDict[mapLayer.layerId],
        }))
    }, [unorderedLayers, stylesBeforeIdDict])

    return orderedLayers
}
