import { DefaultTheme } from "@mui/styles"
import makeStyles from "@mui/styles/makeStyles"

const legendSize = 24

export const useStyles = makeStyles<
    DefaultTheme,
    { circleColor?: string; circleStrokeColor?: string; circleStrokeWidth?: string }
>({
    circle: props => ({
        backgroundColor: props.circleColor,
        borderRadius: "50%",
        height: "100%",
        width: "100%",
    }),
    legend: {
        height: legendSize,
        position: "relative",
        width: legendSize,
    },
    stroke: props => ({
        // TODO: adjust this to support multiple stroke styles
        // props.circleStrokeColor is an categorized array that handles multiple stroke colors
        // borderColor: props.circleStrokeColor,
        borderStyle: "solid",
        borderWidth: props.circleStrokeWidth,
        boxSizing: "border-box",
    }),
})
