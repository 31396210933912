import { useState } from "react"
import { Grid, Menu, MenuItem, Slider, Typography } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import Download from "downloadjs"
import { useDispatch, useSelector } from "react-redux"
import { useMap } from "@emblautec/react-map-gl"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import { toggleWidgetWithArgs } from "features/mapTools/slice"
import { SourceType } from "model/enums/SourceType"
import { Widgets } from "model/enums/Widgets"
import { getSelectedAppBufferingFlag } from "selectors/appsSelectors"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { getLayerVisibility } from "selectors/layerSelector"
import { getSource } from "selectors/mapSelectors"
import customToastr from "utils/customToastr"
import GeotiffDownloader from "./GeotiffDownloader/GeotiffDownloader"
import useContextMenuStyles from "./styles/contextMenuStyles"

const CustomSlider = withStyles({
    valueLabel: {
        fontSize: 11,
    },
})(Slider)

const Context = ({
    anchorEl,
    closeCMenu,
    layer,
    layerHandlers,
    opacity,
    open,
    toggleCMenu,
    handleCommitOpacity,
    handleOpacity,
    onToggle,
}) => {
    const classes = useContextMenuStyles()

    const [downloadGeotiffOpen, setDownloadGeotiffOpen] = useState(false)

    const {
        handleOpenAttributeTable,
        handleOpenDownloadModal,
        handleOpenLayerMetadata,
        onClearFilters,
        onFilterLayer,
        onStyleLayer,
    } = layerHandlers
    const visible = useSelector(getLayerVisibility(layer.resourceId))
    const isPublic = useGetIsPublicApp()
    const featureFlags = useSelector(getFeatureFlags)
    const isBufferingEnabled = useSelector(getSelectedAppBufferingFlag)

    const source = useSelector(getSource(layer.sourceId))

    const { mainMap } = useMap()

    const dispatch = useDispatch()

    const zoomLayer = layer => {
        if (!layer.bounds?.coordinates?.length) {
            customToastr.warning("Layer has no bounds")
            return
        }

        if (!visible) {
            onToggle(layer)
        }
        let bounds = [layer.bounds.coordinates[0][0], layer.bounds.coordinates[0][2]]
        mainMap.fitBounds(bounds, { padding: 100 })
    }

    const onOpenGeotiffDownloader = () => {
        setDownloadGeotiffOpen(true)
        toggleCMenu()
    }

    const onCloseGeotiffDownloader = () => {
        setDownloadGeotiffOpen(false)
    }

    const onOpenBufferWidget = () => {
        dispatch(toggleWidgetWithArgs({ args: layer, widgetName: Widgets.Buffer }))
    }

    const onDownload = () => {
        layer.isBuffer ? downloadBuffer() : handleOpenDownloadModal(layer)
        toggleCMenu()
    }

    const downloadBuffer = () => {
        if (!source || !source.data) {
            customToastr.error("Failed to download the layer")
            return
        }

        const geojson = JSON.stringify(source.data)
        Download(geojson, "buffer.geojson")
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                className={classes.customMenuStyle}
                elevation={0}
                open={open}
                onClose={() => {
                    closeCMenu()
                }}
            >
                <MenuItem>
                    <Grid alignItems="center" container spacing={1}>
                        <Grid item>
                            <Typography variant="caption">0</Typography>
                        </Grid>
                        <Grid item xs>
                            <Grid alignItems="center" container>
                                <CustomSlider
                                    aria-label="Always visible"
                                    defaultValue={opacity}
                                    marks
                                    max={100}
                                    min={0}
                                    size="small"
                                    step={10}
                                    value={opacity}
                                    valueLabelDisplay="on"
                                    onChange={handleOpacity}
                                    onChangeCommitted={() => handleCommitOpacity(layer)}
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">100</Typography>
                        </Grid>
                    </Grid>
                </MenuItem>

                {zoomLayer && (
                    <MenuItem
                        onClick={() => {
                            zoomLayer(layer)
                            toggleCMenu()
                        }}
                    >
                        Zoom To Layer
                    </MenuItem>
                )}
                {!isPublic && (
                    <MenuItem
                        disabled={layer.options.loading}
                        onClick={() => {
                            onStyleLayer(layer)
                            toggleCMenu()
                        }}
                    >
                        Change Layer Style
                    </MenuItem>
                )}
                {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            onFilterLayer(layer)
                            toggleCMenu()
                        }}
                    >
                        Filter Layer
                    </MenuItem>
                )}
                {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            onClearFilters(layer)
                            toggleCMenu()
                        }}
                    >
                        Clear filters
                    </MenuItem>
                )}

                {layer.type !== SourceType.Raster && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            handleOpenLayerMetadata(layer)
                            toggleCMenu()
                        }}
                    >
                        View Layer Metadata
                    </MenuItem>
                )}

                {layer.type !== SourceType.Raster && !isPublic && !layer.isBuffer && (
                    <MenuItem
                        onClick={() => {
                            handleOpenAttributeTable(layer)
                            toggleCMenu()
                        }}
                    >
                        View Layer Attribute Table
                    </MenuItem>
                )}
                {layer.type !== SourceType.Raster && !layer.isBuffer && isBufferingEnabled && (
                    <MenuItem
                        onClick={() => {
                            onOpenBufferWidget()
                            toggleCMenu()
                        }}
                    >
                        Generate Buffer
                    </MenuItem>
                )}
                {layer.options.downloadable && !isPublic && <MenuItem onClick={onDownload}>Download Layer</MenuItem>}
                {layer.options.downloadableGeoTiff && !isPublic && (
                    <MenuItem onClick={onOpenGeotiffDownloader}>Download Geotiff</MenuItem>
                )}
            </Menu>

            <GeotiffDownloader layer={layer} open={downloadGeotiffOpen} handleClose={onCloseGeotiffDownloader} />
        </>
    )
}

export default Context
