import React, { FC, PropsWithChildren, useLayoutEffect, useRef, useState } from "react"
import { Tooltip } from "@mui/material"
import { CustomTypographyProps } from "../CustomTypography/CustomTypography"
import { StyledTruncatedTypography } from "./TruncatedTypography.styles"

export type TruncatedTypographyProps = CustomTypographyProps & PropsWithChildren<{ showTooltip?: boolean }>

const TruncatedTypography: FC<TruncatedTypographyProps> = ({ children, showTooltip = true, ...rest }) => {
    const ref = useRef<HTMLDivElement | null>(null)
    const [isTruncated, setIsTruncated] = useState(false)

    useLayoutEffect(() => {
        const typographyElement = ref.current
        setIsTruncated(Boolean(typographyElement && typographyElement.scrollWidth > typographyElement.clientWidth))
    }, [children])

    if (showTooltip && isTruncated) {
        return (
            <Tooltip title={children}>
                <StyledTruncatedTypography {...rest} ref={ref}>
                    {children}
                </StyledTruncatedTypography>
            </Tooltip>
        )
    }

    return (
        <StyledTruncatedTypography {...rest} ref={ref}>
            {children}
        </StyledTruncatedTypography>
    )
}

export default TruncatedTypography
