import { FC, useEffect, useState } from "react"
import { Box, FormControlLabel, Switch } from "@mui/material"
import { useParams } from "react-router-dom"
import InfoTextSection from "components/common/InfoTextSection/InfoTextSection"
import {
    applicationEmailNotificationOptIn,
    applicationEmailNotificationOptOut,
    getApplicationEmailNotifications,
} from "features/emailNotifications/actions"
import { NotificationOperation } from "features/emailNotifications/models/NotificationOperation"
import { ComponentProps } from "features/settings/Settings"
import toastr from "utils/customToastr"

type Params = {
    appId: string
}

type Notification = {
    description: string
    operation: NotificationOperation
    title: string
}

const notifications: Notification[] = [
    {
        description: "Subscribe to application publish notifications issued by admins.",
        operation: NotificationOperation.ApplicationPublish,
        title: "Application Publish",
    },
]

const EmailNotificationsSection: FC<ComponentProps> = ({ disabled }) => {
    const [emailNotifications, setEmailNotifications] = useState<Set<NotificationOperation>>(new Set())
    const [loading, setLoading] = useState<boolean>(false)

    const { appId } = useParams<Params>()

    useEffect(() => {
        setLoading(true)
        getApplicationEmailNotifications(appId)
            .then(res => {
                setEmailNotifications(
                    new Set(res.map((x: string) => NotificationOperation[x as keyof typeof NotificationOperation])),
                )
            })
            .finally(() => setLoading(false))
    }, [appId])

    const optIn = (operation: NotificationOperation) => {
        setLoading(true)

        applicationEmailNotificationOptIn({ appId, operation })
            .then(() => {
                setEmailNotifications(prev => new Set(prev.add(operation)))
                toastr.success("Successfully opted-in")
            })
            .catch(() => toastr.error("Failed to opt-in"))
            .finally(() => setLoading(false))
    }

    const optOut = (operation: NotificationOperation) => {
        setLoading(true)

        applicationEmailNotificationOptOut({ appId, operation })
            .then(() => {
                setEmailNotifications(prev => {
                    prev.delete(operation)
                    return new Set(prev)
                })
                toastr.success("Successfully opted-out")
            })
            .catch(() => toastr.error("Failed to opt-out"))
            .finally(() => setLoading(false))
    }

    const toggle = (operation: NotificationOperation) => {
        emailNotifications.has(operation) ? optOut(operation) : optIn(operation)
    }

    const getTestId = (title: string) => {
        return switchButtonTestId + title.toLocaleLowerCase().replaceAll(" ", "-")
    }

    return (
        <>
            {notifications.map(notification => (
                <div key={notification.title}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={emailNotifications.has(notification.operation)}
                                edge="end"
                                inputProps={{ "data-testid": getTestId(notification.title) } as DataTestIdProps}
                                onClick={() => toggle(notification.operation)}
                            />
                        }
                        label={<Box marginLeft={1}>{notification.title}</Box>}
                        disabled={loading || disabled}
                    />
                    <InfoTextSection
                        iconSize="extra-small"
                        paddingBottom={2}
                        paddingTop={0.5}
                        textVariant="body2"
                        textWeight="semibold"
                    >
                        {notification.description}
                    </InfoTextSection>
                </div>
            ))}
        </>
    )
}

export default EmailNotificationsSection

const switchButtonTestId = "qa-app-edit-view-settings-email-notifications-switch-"
type DataTestIdProps = {
    "data-testid"?: string
} & React.InputHTMLAttributes<HTMLInputElement>
