import { FC, useEffect, useMemo, useRef, useState } from "react"
import { Divider, Grid } from "@mui/material"
import { CollapsibleCard, CustomTypography } from "@windgis/shared"
import { getSelectedApp } from "selectors/appsSelectors"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import EmailNotificationsSection from "./components/EmailNotificationsSection"
import useStyles from "./styles"

const enum SectionId {
    emailNotifications,
}

export type ComponentProps = {
    disabled: boolean
}

type Section = {
    Component: FC<ComponentProps>
    id: SectionId
    scrollable: boolean
    title: string
    unmountOnExit?: boolean // default true
    disabled: boolean
    visible: boolean
}

const LAST_TOGGLED_SECTION_KEY = "LAST_TOGGLED_SECTION"

const Settings: FC = () => {
    const classes = useStyles()

    const [toggledSection, setToggledSection] = useState<SectionId | null>(null)

    const scrollToRef = useRef<null | HTMLDivElement>(null)

    const app = useAppSelector(getSelectedApp)
    const featureFlags = useAppSelector(getFeatureFlags)

    useEffect(() => {
        const lastToggledSection = parseInt(localStorage.getItem(LAST_TOGGLED_SECTION_KEY) ?? "")
        handleSectionToggle(!lastToggledSection ? SectionId.emailNotifications : lastToggledSection)
    }, [])

    useEffect(() => {
        const timeout = setTimeout(
            () => scrollToRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }),
            500,
        )
        return () => clearTimeout(timeout)
    }, [toggledSection])

    const handleSectionToggle = (sectionId: SectionId) => {
        localStorage.setItem(LAST_TOGGLED_SECTION_KEY, sectionId.toString())
        setToggledSection(toggledSection === sectionId ? null : sectionId)
    }

    //  TODO: REMOVE THE CONDITIONAL RENDER FROM toolsMenu.js WHEN OTHER SETTINGS BESIDE NOTIFICATIONS ARE ADDED
    const sections: Section[] = useMemo(
        () => [
            {
                Component: EmailNotificationsSection,
                id: SectionId.emailNotifications,
                scrollable: true,
                title: "Email Notifications",
                unmountOnExit: false,
                disabled: false,
                visible: featureFlags.NOTIFICATIONS,
            },
        ],
        [app.enabled, app.configJson.projections.length, app.languages.length, app.basemaps.length],
    )

    return (
        <Grid className={classes.root} container direction="column" wrap="nowrap">
            <Divider />
            <Grid className={classes.title} container justifyContent="flex-start">
                <CustomTypography variant="h6">Settings</CustomTypography>
            </Grid>
            <Divider />
            {sections.map(
                section =>
                    section.visible && (
                        <div
                            key={section.id}
                            className={classes.section}
                            ref={toggledSection === section.id ? scrollToRef : null}
                        >
                            <CollapsibleCard
                                maxHeight={section.scrollable ? 600 : undefined}
                                title={section.title}
                                unmountOnExit={section.unmountOnExit ?? true}
                                isToggled={toggledSection === section.id}
                                onToggleClick={() => handleSectionToggle(section.id)}
                            >
                                <section.Component disabled={section.disabled} />
                            </CollapsibleCard>
                        </div>
                    ),
            )}
        </Grid>
    )
}

export default Settings
