// Description: This file contains the constants used in the AIS feature.
// The order of the constants is important, because they are used in the same order in the UI.
/* eslint-disable */
export const boatKeys = {
    TUG: "Tug",
    SPECIAL_CRAFT: "Special Craft",
    FISHING: "Fishing",
    CARGO: "Cargo",
    HIGH_SPEED_CRAFT: "High-Speed Craft",
    PASSENGER: "Passenger",
    PLEASURE_CRAFT: "Pleasure Craft",
    TANKER: "Tanker",
    UNSPECIFIED: "Unspecified",
    OTHER: "Other",
    NAVIGATION_AID: "Navigation Aid",
} as const
/* eslint-enable */

export const boatAisTypeSummary = Object.values(boatKeys)

export type BoatAisTypeSummary = (typeof boatAisTypeSummary)[number]

export const boatIcons: Record<string, string> = {
    [boatKeys.CARGO]: "boat-15-green",
    [boatKeys.FISHING]: "boat-15-orange",
    [boatKeys.HIGH_SPEED_CRAFT]: "boat-15-yellow",
    [boatKeys.NAVIGATION_AID]: "navigational-aid",
    [boatKeys.OTHER]: "boat-15",
    [boatKeys.PASSENGER]: "boat-15-blue",
    [boatKeys.PLEASURE_CRAFT]: "boat-15-purple",
    [boatKeys.SPECIAL_CRAFT]: "boat-15-teal",
    [boatKeys.TANKER]: "boat-15-red",
    [boatKeys.TUG]: "boat-15-teal",
    [boatKeys.UNSPECIFIED]: "boat-15",
} as const

export const PLAYER_WIDTH = 560
