import React, { useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { getApp } from "actions/apps"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"

const AppProvider: React.FCWC = ({ children }) => {
    const { appId = "" } = useParams<{ appId: string | undefined }>()

    const isPublic = useGetIsPublicApp()

    const selectedApp = useAppSelector(state => state.apps.selectedApp)

    const history = useHistory()

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getApp({ appId, isPublic }))
            .unwrap()
            .then(appResponse => {
                if (!appResponse) history.replace("/")
            })
    }, [dispatch, appId])

    return (
        <LoadingPlaceholder loading={selectedApp === null} message="Getting app data">
            {children}
        </LoadingPlaceholder>
    )
}

export default AppProvider
