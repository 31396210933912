import React, { FC } from "react"
import AutoSizer from "react-virtualized-auto-sizer"
import { isGroup } from "@emblautec/recursive-array-extensions"
import { VirtualizedList } from "@windgis/shared"
import { Divider, Grid, InputAdornment, Tooltip, Typography } from "components/atoms"
import { CloseIcon, VisibilityOff } from "components/icons"
import DownloadLayer from "components/sidebar/layerSelector/downloadLayer"
import MetadataModal from "components/sidebar/layerSelector/Metadata/MetadataModal/MetadataModal"
import { AttributeTable } from "features/datasets/components/AttributeTable"
import { PortalComponent } from "utils/portal"
import Group from "../../components/sidebar/layerSelector/group"
import Layer from "../../components/sidebar/layerSelector/layer"
import { useLayerSelectorRoute } from "./hooks"
import {
    LayersContainer,
    StyledBaseContainer,
    StyledFullHeightContainer,
    StyledHideAllIconButton,
    StyledIconButton,
    StyledInput,
    StyledLinearProgress,
    StyledSearchContainer,
} from "./LayerSelectorRoute.styles"
import translations from "./translations.en.json"

const LayerSelectorRoute: FC = () => {
    const {
        clearSearch,
        featureIds,
        groups,
        layer,
        layerHandlers,
        openAttributeTable,
        openDownloadModal,
        openLayerMetadata,
        searchValue,
        isFetching,
        handleCloseAttributeTable,
        handleCloseDownloadModal,
        handleCloseLayerMetadata,
        handleHideAll,
        handleOpenDownloadModal,
        handleSearch,
    } = useLayerSelectorRoute()

    return (
        <StyledBaseContainer>
            <StyledFullHeightContainer>
                <Divider />

                <Grid alignItems="center" container flexGrow={1} justifyContent="flex-end">
                    <Grid item xs={10}>
                        <Grid container justifyContent="flex-start" paddingBlock={2} paddingInline={4}>
                            <Typography variant="h6">{translations.layerSelector.filtersRegion.title}</Typography>
                        </Grid>
                    </Grid>

                    <Grid item xs={2}>
                        <Grid container justifyContent="flex-end">
                            <Tooltip title={translations.layerSelector.filtersRegion.tooltip}>
                                <StyledHideAllIconButton size="large" onClick={handleHideAll}>
                                    <VisibilityOff />
                                </StyledHideAllIconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                {isFetching && <StyledLinearProgress color="primary" variant="indeterminate" />}

                <StyledSearchContainer>
                    <StyledInput
                        endAdornment={
                            <InputAdornment position="end">
                                {searchValue !== "" && (
                                    <StyledIconButton
                                        aria-label="toggle password visibility"
                                        size="large"
                                        onClick={clearSearch}
                                    >
                                        <CloseIcon />
                                    </StyledIconButton>
                                )}
                            </InputAdornment>
                        }
                        fullWidth
                        id="full-width"
                        inputProps={{ autoComplete: "off" }}
                        placeholder={translations.layerSelector.searchPlaceholder}
                        value={searchValue}
                        onChange={handleSearch}
                    />
                </StyledSearchContainer>

                <LayersContainer id="layer-menu">
                    <AutoSizer disableWidth={false}>
                        {({ height, width }) => (
                            <VirtualizedList height={height} width={width}>
                                {groups.map(group => (
                                    <div key={group.resourceId} className="virtual-item">
                                        {isGroup(group) ? (
                                            <Group group={group} layerHandlers={layerHandlers} />
                                        ) : (
                                            <Layer layer={group} layerHandlers={layerHandlers} />
                                        )}
                                    </div>
                                ))}
                            </VirtualizedList>
                        )}
                    </AutoSizer>
                </LayersContainer>
            </StyledFullHeightContainer>

            {!!(openAttributeTable && layer) && (
                <PortalComponent>
                    <AttributeTable
                        datasetId={layer.resourceId}
                        layerName={layer.name}
                        isDownloadable={layer.options.downloadable}
                        onClose={handleCloseAttributeTable}
                        onDownloadClick={ids => handleOpenDownloadModal(layer, ids)}
                    />
                </PortalComponent>
            )}

            {!!layer && (
                <DownloadLayer
                    featureIds={featureIds}
                    layer={layer}
                    open={openDownloadModal}
                    handleClose={handleCloseDownloadModal}
                />
            )}

            {!!layer && (
                <MetadataModal
                    datasetId={layer.resourceId}
                    datasetName={layer.name}
                    open={openLayerMetadata}
                    onClose={handleCloseLayerMetadata}
                />
            )}
        </StyledBaseContainer>
    )
}

export default LayerSelectorRoute
