import { useEffect, useRef, useState } from "react"
import {
    AccountInfo,
    BrowserAuthError,
    ClientAuthError,
    ClientAuthErrorMessage,
    InteractionRequiredAuthError,
} from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import dayjs from "dayjs"
import jwt_decode from "jwt-decode"

type JWTProperties = {
    exp: number
}

type UseAccessTokenProps = {
    scopes: string[]
    isPublic?: boolean
}

const getExpiresOnDate = (token: string) => {
    const jwtProperties: JWTProperties = jwt_decode(token)

    return dayjs(jwtProperties.exp * 1000).toDate()
}

const useAccessToken = ({ scopes, isPublic }: UseAccessTokenProps) => {
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined)

    const [error, setError] = useState<unknown | null>(null)

    const [isAccessTokenLoading, setIsAccessTokenLoading] = useState(true)

    const tokenExpiresOnRef = useRef<Date | null>(null)

    const { inProgress, instance: msalInstance } = useMsal()

    const acquireToken = async () => {
        try {
            const activeAccount = msalInstance.getActiveAccount()

            if (!activeAccount) {
                await msalInstance.acquireTokenRedirect({ scopes })
                return null
            }

            return await msalInstance.acquireTokenSilent({ account: activeAccount, scopes })
        } catch (error: unknown) {
            setError(error)
            throw error
        }
    }

    // If there is an error, we try to get a new access token with redirect
    useEffect(() => {
        if (inProgress !== "none" || !error) return

        console.error("Authentication error: ", error)
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
            msalInstance.acquireTokenRedirect({
                account: msalInstance.getActiveAccount() as AccountInfo,
                scopes,
            })
        }
        if (
            error instanceof ClientAuthError &&
            error.errorCode === ClientAuthErrorMessage.multipleMatchingTokens.code
        ) {
            localStorage.clear()
            msalInstance.acquireTokenRedirect({
                account: msalInstance.getActiveAccount() as AccountInfo,
                scopes,
            })
        }

        return () => {
            setError(null)
        }
    }, [error, inProgress])

    useEffect(() => {
        // If the user is in the public view, we don't need to get the access token
        if (isPublic) {
            setIsAccessTokenLoading(false)
            setAccessToken(undefined)
            return
        }

        if (inProgress !== "none") return

        // If the access token is not set or the token expiration date is not set, we get a new access token
        if (!accessToken || !tokenExpiresOnRef.current) {
            getNewAccessToken().then(() => setIsAccessTokenLoading(false))
            return
        }

        // msDifference is the time in milliseconds before the token expires
        const expiresInMs = tokenExpiresOnRef.current.getTime() - Date.now() - 30000 // 30 seconds before expiration

        if (expiresInMs > 0) {
            // We set a timeout to get a new access token before the current one expires
            const timeout = setTimeout(getNewAccessToken, expiresInMs)
            return () => clearTimeout(timeout) // Cleanup on unmount or token change
        }
    }, [accessToken, msalInstance, isPublic, inProgress]) // Triggers when the token is updated

    const getNewAccessToken = async () => {
        const response = await acquireToken()

        if (response !== null) {
            setAccessToken(response.accessToken)
            tokenExpiresOnRef.current = getExpiresOnDate(response.accessToken)
            return
        }

        setAccessToken(undefined)
        tokenExpiresOnRef.current = null
    }

    return { accessToken, isAccessTokenLoading }
}

export default useAccessToken
