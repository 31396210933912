// TODO: remove this once the export from the package is typed

import { Dayjs } from "dayjs"

export enum Operators {
    Between = "between",
    Contains = "contains",
    Equal = "==",
    GreaterThan = ">",
    GreaterThanOrEqualTo = ">=",
    LessThan = "<",
    LessThanOrEqualTo = "<=",
    NotEqual = "!=",
    Undefined = "",
}

export function getOperatorNameBySymbol(symbol: string): string | undefined {
    const entry = Object.entries(Operators).find(([, value]) => value === symbol)
    return entry ? entry[0] : undefined
}

export type StringOperators = Operators.Equal | Operators.NotEqual | Operators.Undefined | Operators.Contains
export type NumericOperators =
    | Operators.Equal
    | Operators.NotEqual
    | Operators.LessThan
    | Operators.LessThanOrEqualTo
    | Operators.GreaterThanOrEqualTo
    | Operators.GreaterThan
    | Operators.Between
    | Operators.Undefined
export type DateOperators =
    | Operators.Equal
    | Operators.NotEqual
    | Operators.LessThan
    | Operators.LessThanOrEqualTo
    | Operators.GreaterThanOrEqualTo
    | Operators.GreaterThan
    | Operators.Between
    | Operators.Undefined
export type UndefinedOperators = Operators.Undefined

export enum Types {
    CharacterVarying = "character varying",
    Date = "date",
    DoublePrecision = "double precision",
    Integer = "integer",
    String = "string",
    Undefined = "",
}

export function getTypeNameBySymbol(symbol: string): string | undefined {
    const entry = Object.entries(Types).find(([, value]) => value === symbol)
    return entry ? entry[0] : undefined
}

export type LiteralType = Types.CharacterVarying | Types.String
export type NumericType = Types.Integer | Types.DoublePrecision
export type DateType = Types.Date
export type UndefinedType = Types.Undefined

export const operatorsByType: Record<Types, Operators[]> = {
    [Types.CharacterVarying]: [Operators.Equal, Operators.NotEqual, Operators.Contains, Operators.Undefined],
    [Types.Date]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
        Operators.Undefined,
    ],
    [Types.DoublePrecision]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
        Operators.Undefined,
    ],
    [Types.Integer]: [
        Operators.Equal,
        Operators.NotEqual,
        Operators.LessThan,
        Operators.LessThanOrEqualTo,
        Operators.GreaterThanOrEqualTo,
        Operators.GreaterThan,
        Operators.Between,
        Operators.Undefined,
    ],
    [Types.String]: [Operators.Equal, Operators.NotEqual, Operators.Contains, Operators.Undefined],
    [Types.Undefined]: [Operators.Undefined],
}

export type LayerFilter = {
    applied: boolean
    fieldName: string
} & (
    | {
          operatorName: StringOperators
          type: LiteralType
          value: string
      }
    | {
          operatorName: NumericOperators
          secondValue?: number
          type: NumericType
          value: number
      }
    | {
          operatorName: DateOperators
          secondValue?: Dayjs
          type: DateType
          value: Dayjs
      }
    | {
          operatorName: UndefinedOperators
          type: UndefinedType
          value: string
      }
)
