import { Raster } from "@windgis/shared"
import baseAtlasApi from "store/baseAtlasApi"

export const rastersApi = baseAtlasApi.injectEndpoints({
    endpoints: build => ({
        fetchRasterById: build.query<Raster, string>({
            query: rasterId => ({ method: "GET", url: `raster/${rasterId}` }),
        }),
    }),
})

export const { useFetchRasterByIdQuery } = rastersApi
