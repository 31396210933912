import { FC } from "react"
import PublicIcon from "@mui/icons-material/Public"
import { CardActionArea, CardContent, Grid, Typography } from "@mui/material"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { TruncatedTypography } from "@windgis/shared"
import config from "config"
import { getClientId, getProjectId } from "features/core/selectors"
import { App } from "model/app/App"
import { useAppSelector } from "store/hooks/useAppSelector"
import { StyledCard, StyledCardMedia, StyledCustomChip, StyledGridItem } from "./AppCard.styles"

type Props = {
    app: App
    getPathname: () => string
    token: string
}

const AppCard: FC<Props> = ({ app, getPathname, token }) => {
    const clientId = useAppSelector(getClientId)
    const projectId = useAppSelector(getProjectId)

    const cardMediaProps = {
        alt: "Map snapshot",
        component: "img",
    }

    const getImageUrl = () => {
        return `${config.apiUrl}application/${app.id}/thumbnail?key=${token}&ClientId=${clientId}&ProjectId=${projectId}`
    }

    return (
        <StyledCard>
            <CardActionArea component={Link} data-testid={appCardId} to={getPathname()}>
                <StyledCardMedia {...cardMediaProps} image={getImageUrl()} />
                <CardContent>
                    <Grid container gap={1}>
                        <StyledGridItem item xs>
                            <TruncatedTypography component="h6" fontSize={18} fontWeight="bold" variant="h6">
                                {app.name}
                            </TruncatedTypography>
                            <Typography color="textSecondary" variant="body2">
                                Updated: {dayjs(app.modifiedUtc).fromNow()}
                            </Typography>
                        </StyledGridItem>

                        {app.public && (
                            <Grid item xs="auto">
                                <StyledCustomChip expanded={true} Icon={PublicIcon} label={"Public"} size="large" />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </CardActionArea>
        </StyledCard>
    )
}

export default AppCard

const appCardId = "qa-app-card"
