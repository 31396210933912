import { FC, HTMLAttributes, useCallback, useEffect, useState } from "react"
import {
    Autocomplete,
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    CircularProgress,
    InputAdornment,
    TextField,
    createFilterOptions,
    debounce,
} from "@mui/material"
import match from "autosuggest-highlight/match"
import parse from "autosuggest-highlight/parse"
import { LngLatBoundsLike, useMap } from "@emblautec/react-map-gl"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import { searchApplicationDatasetByColumnAsync } from "features/searchBar/actions"
import { SearchBar as SearchBarType } from "features/searchBar/models/SearchBar"
import { SearchResult } from "features/searchBar/models/SearchResult"
import { getSelectedAppSearchBar } from "selectors/appsSelectors"
import { useAppSelector } from "store/hooks/useAppSelector"
import { useStyles } from "./styles"

const SearchBar: FC = () => {
    const classes = useStyles()
    const { mainMap } = useMap()

    const [options, setOptions] = useState<SearchResult[]>([])
    const [loading, setLoading] = useState(false)

    const searchBar: SearchBarType = useAppSelector(getSelectedAppSearchBar)
    const isPublic = useGetIsPublicApp()

    useEffect(() => {
        searchBar && searchBackendCall("")
    }, [searchBar])

    const searchBackendCall = useCallback(
        debounce(searchText => {
            setLoading(true)
            searchApplicationDatasetByColumnAsync(searchBar.applicationId, searchText, isPublic).then(res => {
                setOptions(res.filter(x => !!x.value))
                setLoading(false)
            })
        }, 500),
        [searchBar],
    )

    const onInputChange = (_: any, newInputValue: string) => {
        searchBackendCall(newInputValue)
    }

    const zoomTo = (_: any, value: unknown) => {
        if (value) {
            const valueWithType = value as SearchResult
            let map = mainMap?.getMap()

            // boundingBox format: BOX(number number,number number)
            const boxCoord = valueWithType.boundingBox
                .slice(4, -1)
                .replaceAll(" ", ",")
                .split(",")
                .filter(x => !!x) // I've added this as a safety net
                .map(x => parseFloat(x))

            const bbox: LngLatBoundsLike = [
                [boxCoord[0], boxCoord[1]],
                [boxCoord[2], boxCoord[3]],
            ]

            map?.fitBounds(bbox, {
                padding: 200,
            })
        }
    }

    const filterOptions = createFilterOptions({
        matchFrom: "any",
        stringify: option => (option as SearchResult).value,
    })

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            className={classes.textField}
            InputProps={{
                ...params.InputProps,
                className: classes.input,
                ...(loading && {
                    endAdornment: (
                        <InputAdornment className={classes.circularProgress} position="end">
                            <CircularProgress size={15} />
                        </InputAdornment>
                    ),
                }),
            }}
            placeholder="Search"
        />
    )

    const renderOption = (
        props: HTMLAttributes<HTMLLIElement>,
        option: unknown,
        state: AutocompleteRenderOptionState,
    ) => {
        const optionWithType = option as SearchResult
        const matches = match(optionWithType.value, state.inputValue)
        const parts = parse(optionWithType.value, matches)
        return (
            <li {...props} key={optionWithType.value + props.id}>
                <div>
                    {parts.map((part, index) => (
                        <span
                            key={index}
                            style={{
                                fontWeight: part.highlight ? 700 : 400,
                            }}
                        >
                            {part.text}
                        </span>
                    ))}
                </div>
            </li>
        )
    }

    return (
        <div className={classes.content}>
            <Autocomplete
                filterOptions={filterOptions}
                forcePopupIcon
                freeSolo
                getOptionLabel={option => (option as SearchResult).value}
                loading={loading}
                options={options}
                disableClearable
                isOptionEqualToValue={(a, b) => (a as SearchResult).value == (b as SearchResult).value}
                renderInput={renderInput}
                renderOption={renderOption}
                onChange={zoomTo}
                onInputChange={onInputChange}
            />
        </div>
    )
}

export default SearchBar
