import React from "react"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import { Box, Stack } from "@mui/material"
import { Draggable } from "react-beautiful-dnd"
import ColorPopover from "../colorPopover"

const ColorContainer = ({ draggable = true, index, value, disabled = false, isDefaultValue, onPropertyChanged }) => {
    if (!draggable) {
        return (
            <Box
                sx={{
                    "& .color-container": {
                        "&:hover": {
                            opacity: 0.8,
                        },
                        border: "2px solid #e0e0e0",
                        borderRadius: "0 0 4px 4px",
                        cursor: "pointer",
                        height: 60,
                        width: 64,
                    },
                    cursor: "move",
                }}
            >
                <ColorPopover
                    color={value}
                    disabled={disabled}
                    onColorChanged={color =>
                        onPropertyChanged(`rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`)
                    }
                />
            </Box>
        )
    }

    return (
        <Draggable
            key={"draggable" + index}
            draggableId={`color-popover-${index}`}
            index={index}
            isDragDisabled={isDefaultValue}
        >
            {provided => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Stack>
                        <Box
                            sx={{
                                "& .MuiSvgIcon-root": {
                                    height: 16,
                                    transform: "rotate(90deg)",
                                    width: 16,
                                },
                                backgroundColor: "#e0e0e0",
                                borderRadius: "4px 4px 0 0",
                                cursor: "move",
                                height: 16,
                                textAlign: "center",
                            }}
                        >
                            <DragIndicatorIcon />
                        </Box>
                        <Box
                            sx={{
                                "& .color-container": {
                                    "&:hover": {
                                        opacity: 0.8,
                                    },
                                    border: "2px solid #e0e0e0",
                                    borderRadius: "0 0 4px 4px",
                                    cursor: "pointer",
                                    height: 44,
                                    width: 64,
                                },
                                cursor: "move",
                            }}
                        >
                            <ColorPopover
                                color={value}
                                disabled={disabled}
                                onColorChanged={color =>
                                    onPropertyChanged(
                                        `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`,
                                    )
                                }
                            />
                        </Box>
                    </Stack>
                </div>
            )}
        </Draggable>
    )
}

export default ColorContainer
