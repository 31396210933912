import { FC, useMemo } from "react"
import { AnySource, Layer } from "@emblautec/react-map-gl"
import { AisMapLayer as AisMapLayerType } from "model/map/AisMapLayer"

type Props = {
    layoutProperties?: Object[]
    maxZoom: number
    minZoom: number
    paintProperties?: Object[]
    source?: AnySource | string // Can be passed by the Source wrapper
} & AisMapLayerType

const AisMapLayer: FC<Props> = ({ layoutProperties, maxZoom, minZoom, paintProperties, source, ...layerProps }) => {
    const paint = useMemo(
        () =>
            paintProperties?.reduce((acc: Record<string, string>, prop: any) => {
                if (prop.title === "Legend") return acc
                acc[prop.name] = prop.value
                return acc
            }, {}),
        [paintProperties],
    )

    const layout = useMemo(
        () =>
            layoutProperties?.reduce((acc: Record<string, string>, prop: any) => {
                acc[prop.name] = prop.value
                return acc
            }, {}),
        [layoutProperties],
    )
    const { drawUnderId, layerId, sourceId, type } = layerProps

    return (
        <Layer
            // This key is very important since it controls the instance of the component.
            // We have to remove the layer and add a new one when the type changes
            key={type}
            beforeId={drawUnderId}
            // -------------
            id={layerId}
            layout={layout}
            maxzoom={maxZoom}
            minzoom={minZoom}
            paint={paint}
            source={source || sourceId}
            type={type}
        />
    )
}

export default AisMapLayer
