import { useMemo } from "react"
import { IconAndTitleProps, IconProps, Styling } from "features/common/models/legend"
import { StyleProperty } from "model/style/StyleProperty"

type Props = {
    expressionProperty: StyleProperty
    rightAlign: boolean
    styling: Styling
}

const useIconAndTitlePropsGenerator = ({ expressionProperty, styling }: Props) => {
    const valuesOffset = expressionProperty.expressionType === "match" ? 2 : 3

    const iconAndTitleProps: IconAndTitleProps[] | null = useMemo(() => {
        const props: IconAndTitleProps[] = []

        for (let i = valuesOffset; i < expressionProperty.value.length; i += 2) {
            const value = expressionProperty.value[i + 1]

            if (value === undefined) {
                if (styling.type === "raster") {
                    return null
                }

                const iconProp: IconProps = {
                    ...styling,
                    [`${styling.type}Color` as keyof Styling]: (
                        styling[`${styling.type}Color` as keyof Styling] as string
                    )[i],
                }

                if (styling.type === "fill" && Object.hasOwn(styling, "lineColor")) {
                    iconProp.lineColor =
                        typeof styling.lineColor === "string" ? styling.lineColor : styling.lineColor[i]
                }

                props.push({ iconProps: iconProp, title: "Others" })
            } else {
                const index = styling.type === "raster" ? i - 1 + valuesOffset : i + 1

                const iconProp: IconProps = {
                    ...styling,
                    [(styling.type + "Color") as keyof Styling]: (
                        styling[(styling.type + "Color") as keyof Styling] as string
                    )[index],
                }

                if (styling.type === "fill" && Object.hasOwn(styling, "lineColor")) {
                    iconProp.lineColor =
                        typeof styling.lineColor === "string" ? styling.lineColor : styling.lineColor[index]
                }

                if (styling.type === "fill" && Object.hasOwn(styling, "circleColor")) {
                    iconProp.circleColor =
                        typeof styling.circleColor === "string" ? styling.circleColor : styling.circleColor[index]
                }

                props.push({ iconProps: iconProp, title: expressionProperty.value[i] })
            }
        }

        return props
    }, [expressionProperty, styling, valuesOffset])

    return iconAndTitleProps
}

export default useIconAndTitlePropsGenerator
