import React, { useEffect, useState } from "react"
import { Add, AddCircle, ArrowBack, Cancel } from "@mui/icons-material"
import { Divider, Fab, Fade, Grid, IconButton, Paper, Popper, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import { displayInfoHelp } from "actions/digitize"
import { getDigitizeEditingStatus, getDigitizeStateInfoHelp } from "selectors/digitizeSelectors"
import DigitizeFeatures from "./digitizeFeatures"

const useStyles = makeStyles(theme => ({
    header: {
        height: 48,
        paddingLeft: 16,
        paddingRight: 16,
    },
    infoPanel: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1),
    },
    root: {
        backgroundColor: "#f5f5f5",
        width: "100%",
    },
    selectedLayerName: {
        paddingLeft: 6,
    },
}))

const LayerFeatures = ({
    deselectAllFeaturesAndLabels,
    selectedLayer,
    setIsStylerOpen,
    setSelectedLayer,
    renderDistanceMarkers,
    onDraw,
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [anchorInfo, setAnchorInfo] = useState(null)
    const digitizeInfoHelp = useSelector(getDigitizeStateInfoHelp)
    const editing = useSelector(getDigitizeEditingStatus)
    const [backButtonAttempt, setBackButtonAttempt] = useState(false)

    const handleDrawFeature = () => {
        onDraw(selectedLayer)
        setAnchorInfo(null)
        dispatch(displayInfoHelp(false))
    }

    const handleCloseInfo = () => {
        setAnchorInfo(null)
    }

    const handleBackButton = () => {
        if (editing) {
            let backButton = document.getElementById("digitize-button-back")
            setAnchorInfo(backButton)
            setBackButtonAttempt(true)
        } else {
            setIsStylerOpen(false)
            setSelectedLayer({})
            setBackButtonAttempt(false)
            setAnchorInfo(null)
        }
        deselectAllFeaturesAndLabels()
    }

    useEffect(() => {
        if (digitizeInfoHelp && selectedLayer.type === "point") {
            let infoButton = document.getElementById("digitize-button-add-data-info")
            setAnchorInfo(infoButton)
        }
    }, [])

    return (
        <Grid alignItems="center" className={classes.root} container>
            <Grid item xs={12}>
                <Grid alignItems="center" className={classes.header} container>
                    <Grid item xs={11}>
                        <Grid alignItems="center" container direction="row">
                            <IconButton id="digitize-button-back" size="small" onClick={handleBackButton}>
                                <ArrowBack />
                            </IconButton>
                            <Typography className={classes.selectedLayerName} variant="body1">
                                {selectedLayer.namePretty}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        {!digitizeInfoHelp ? (
                            <IconButton
                                aria-label="add-feature"
                                color="primary"
                                size="medium"
                                onClick={handleDrawFeature}
                            >
                                <AddCircle />
                            </IconButton>
                        ) : (
                            <Fab
                                aria-label="add-feature-info"
                                color="primary"
                                id="digitize-button-add-data-info"
                                size="small"
                                onClick={handleDrawFeature}
                            >
                                <Add />
                            </Fab>
                        )}
                    </Grid>
                    {digitizeInfoHelp && (
                        <Popper anchorEl={anchorInfo} open={Boolean(anchorInfo)} placement="right-end" transition>
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Paper className={classes.infoPanel}>
                                        <Typography>To add a new feature, click on the '+' button.</Typography>
                                        <IconButton size="small" onClick={handleCloseInfo}>
                                            <Cancel />
                                        </IconButton>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    )}
                </Grid>
                {backButtonAttempt && (
                    <Popper anchorEl={anchorInfo} open={Boolean(anchorInfo)} placement="right-end" transition>
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper className={classes.infoPanel}>
                                    <Typography>Finish adding the feature before going back.</Typography>
                                    <IconButton size="small" onClick={handleCloseInfo}>
                                        <Cancel />
                                    </IconButton>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                )}
                <Divider />
            </Grid>

            <Grid item xs={12}>
                <DigitizeFeatures
                    deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                    selectedLayer={selectedLayer}
                    setSelectedLayer={setSelectedLayer}
                    renderDistanceMarkers={renderDistanceMarkers}
                />
            </Grid>
        </Grid>
    )
}

export default LayerFeatures
