import { Grid } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import { useDispatch, useSelector } from "react-redux"
import * as digitizeActions from "actions/digitize"
import { closeWidgets } from "features/mapTools/slice"
import { getDigitizeEditingStatus, getDigitizeLayers } from "selectors/digitizeSelectors"
import { useMainDraw } from "utils/customHooks/map/useMainDraw"
import DigitizeLayer from "./digitizeLayer"
import LayerFeatures from "./digitizeLayerFeatures"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        width: "100%",
    },
}))

const DigitizeLayers = ({
    deselectAllFeaturesAndLabels,
    drawingDistanceMarkersArray,
    selectedLayer,
    resetDrawingState,
    setIsStylerOpen,
    setSelectedLayer,
    renderDistanceMarkers,
}) => {
    const classes = useStyles()

    const draw = useMainDraw()

    const editing = useSelector(getDigitizeEditingStatus)
    const digitizeLayers = useSelector(getDigitizeLayers)

    const dispatch = useDispatch()

    const onCloseWidgets = () => {
        dispatch(closeWidgets())
    }

    const onDraw = layer => {
        setSelectedLayer(layer)
        onCloseWidgets()
        dispatch(digitizeActions.editingFeatures(true))
        if (editing) {
            draw.trash()
        }
        switch (layer.type) {
            case "point":
                draw.changeMode("draw_point")
                break
            case "line":
                draw.changeMode("draw_line_string")
                break
            case "polygon":
                draw.changeMode("draw_polygon")
                break
            case "circle":
                draw.changeMode("drag_circle")
                break
            default:
                return null
        }
    }

    return (
        <Grid className={classes.root} container>
            {!!Object.keys(selectedLayer).length ? (
                <LayerFeatures
                    deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                    drawingDistanceMarkersArray={drawingDistanceMarkersArray}
                    selectedLayer={selectedLayer}
                    setIsStylerOpen={setIsStylerOpen}
                    setSelectedLayer={setSelectedLayer}
                    renderDistanceMarkers={renderDistanceMarkers}
                    onDraw={onDraw}
                />
            ) : (
                digitizeLayers.map(layer => (
                    <DigitizeLayer
                        key={layer.id}
                        deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                        layer={layer}
                        selectedLayer={selectedLayer}
                        resetDrawingState={resetDrawingState}
                        setIsStylerOpen={setIsStylerOpen}
                        setSelectedLayer={setSelectedLayer}
                        onDraw={onDraw}
                    />
                ))
            )}
        </Grid>
    )
}

export default DigitizeLayers
