import { createSlice } from "@reduxjs/toolkit"
import { resetProjectData } from "actions/globalActions"
import { getFeatureFlags } from "../actions/featureFlags"
import { featureFlags } from "../featureFlags"

type SliceState = {
    [featureFlags.AIS_ARCHIVE]: boolean
    [featureFlags.AIS]: boolean
    [featureFlags.DPR]: boolean
    [featureFlags.LAYER_FILTERS]: boolean
    [featureFlags.NOTIFICATIONS]: boolean
}

const initialState: SliceState = {
    [featureFlags.AIS]: false,
    [featureFlags.AIS_ARCHIVE]: false,
    [featureFlags.DPR]: false,
    [featureFlags.LAYER_FILTERS]: false,
    [featureFlags.NOTIFICATIONS]: false,
}

export const featureFlagsSlice = createSlice({
    extraReducers: builder =>
        builder
            .addCase(getFeatureFlags.fulfilled, (state, { payload: featureFlags }) => {
                Object.keys(state).forEach(feature => {
                    // did this to support features that are only defined on the frontend
                    const featureNotDefinedOnBackend = featureFlags[feature] === undefined
                    if (featureNotDefinedOnBackend) return

                    // @ts-ignore
                    state[feature] = featureFlags[feature]
                })
            })
            .addCase(resetProjectData, () => initialState),
    initialState,
    name: "featureFlags",
    reducers: {},
})

export const {} = featureFlagsSlice.actions

export default featureFlagsSlice.reducer
