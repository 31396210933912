import { Boat } from "../../model/ais/Boat"
import { BoatAisTypeSummary, boatAisTypeSummary, boatKeys } from "./constants"
import { BoatDetailsWithCoordinates } from "./models/BoatDetails"
import { VesselUiModel } from "./models/Vessel.ui.model"

export const removeEmptyBoatTypes = (boatRecord: Record<string, VesselUiModel[]>) => {
    return Object.fromEntries(Object.entries(boatRecord).filter(([, vessels]) => vessels.length > 0))
}

const getAISSummaryType = (aisTypeSummary?: string) => {
    if (!aisTypeSummary) return boatKeys.OTHER

    if (boatAisTypeSummary.includes(aisTypeSummary as BoatAisTypeSummary)) return aisTypeSummary

    return boatKeys.OTHER
}

export const transformBoatsToVesselsUiModel = (boats: Boat[]): VesselUiModel[] => {
    return boats.map((boat, index) => ({
        aisTypeSummary: getAISSummaryType(boat?.AIS_TYPE_SUMMARY),
        id: boat?.MMSI ?? index.toString(),
        lat: boat?.LAT ? parseFloat(boat.LAT) : undefined,
        lon: boat?.LON ? parseFloat(boat.LON) : undefined,
        name: boat?.NAME ?? boat.MMSI ?? `Vessel ${index + 1}`,
    }))
}

export const transformBoatDetailsToVesselsUiModel = (boatDetails: BoatDetailsWithCoordinates[]): VesselUiModel[] => {
    return boatDetails.map((boatDetail, index) => ({
        aisTypeSummary: getAISSummaryType(boatDetail?.aisTypeSummary),
        id: boatDetail?.id ?? `${boatDetail?.mmsi}-${index}`,
        lat: boatDetail?.lat,
        lon: boatDetail?.lon,
        name: boatDetail?.name ?? boatDetail.mmsi ?? `Vessel ${index + 1}`,
    }))
}

export const removeDuplicates = (vessels: VesselUiModel[]) => {
    return vessels.filter((vessel, index, self) => self.findIndex(v => v.id === vessel.id) === index)
}

export const sortVesselsAlphabetically = (vessels: VesselUiModel[]) => {
    return vessels.sort((a, b) => a.name.localeCompare(b.name))
}

export const filterVesselsBySearchValue = (vessels: VesselUiModel[], searchValue = "") => {
    if (!searchValue) {
        return vessels
    }

    return vessels.filter(vessel => vessel.name.toLowerCase().includes(searchValue.toLowerCase()))
}
