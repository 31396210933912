import { GenericLayer } from "./models/genericLayer.model"
import {
    filterRecurseWithReturn,
    filterRecurseWithReturnNoSelector,
    isChildOf,
    isGroup,
    recurse,
    recurseGroupsOne,
    recurseLayersOne,
    recurseOne,
    recurseParents,
    recurseWithReturn,
} from "./utilts"

/**Removes the first element whose id matches the one inputted.*/
Object.defineProperty(Array.prototype, "removeOneRecursive", {
    configurable: true,
    value: function (id: string) {
        return recurseLayersOne(this, id, (layers, index) => {
            return layers.splice(index, 1)[0]
        })
    },
})

/**Adds an element to the layers list in the first group whose id matches the inputted one.*/
Object.defineProperty(Array.prototype, "addChildRecursive", {
    configurable: true,
    value: function (id: string, layer: GenericLayer) {
        recurseGroupsOne(this, id, layers => {
            layers?.splice(0, 0, layer)
        })

        return this
    },
})

/**Adds an element before or after the element whose id matches the inputted one.*/
Object.defineProperty(Array.prototype, "addElementRecursive", {
    configurable: true,
    value: function (id: string, layer: GenericLayer, dropAbove: boolean) {
        recurseLayersOne(this, id, (layers, index) => {
            layers.splice(dropAbove ? index : index + 1, 0, layer)
        })

        return this
    },
})

/**Returns the element whose id matches the input id.*/
Object.defineProperty(Array.prototype, "getRecursive", {
    configurable: true,
    value: function (id: string) {
        return recurseOne(this, id, layer => layer)
    },
})

/**Applies a function to the first element that has an id matching the inputted one.*/
Object.defineProperty(Array.prototype, "forParentsRecursive", {
    configurable: true,
    value: function (id: string, func: Function) {
        return recurseParents(this, id, func)
    },
})

/**Applies a function to the first element that has an id matching the inputted one.*/
Object.defineProperty(Array.prototype, "forOneRecursive", {
    configurable: true,
    value: function <T>(id: string, func: (layer?: GenericLayer<T>, index?: number, parentGroupId?: string) => any) {
        return recurseOne(this, id, func)
    },
})

/**Applies a function to each layer without returning anything at the end.*/
Object.defineProperty(Array.prototype, "forLayersRecursive", {
    configurable: true,
    value: function (func: Function) {
        recurse(this, func, layer => !isGroup(layer))
    },
})

/**Applies a function to each layer without returning anything at the end.*/
Object.defineProperty(Array.prototype, "forLayersRecursive", {
    configurable: true,
    value: function (func: Function) {
        recurse(this, func, layer => !isGroup(layer))
    },
})

/**Applies a function to each group without returning anything at the end.*/
Object.defineProperty(Array.prototype, "forGroupsRecursive", {
    configurable: true,
    value: function (func: Function) {
        recurse(this, func, layer => isGroup(layer))
    },
})

/**Removed layers who do not respect the test function. */
Object.defineProperty(Array.prototype, "filterLayersRecursive", {
    configurable: true,
    value: function (func: Function) {
        return filterRecurseWithReturn(this, func, layer => !isGroup(layer))
    },
})

//It removes groups that do not conform to a condition. Note that conforming groups can also be
//removed if the parent group doesn't pass the condition test.
Object.defineProperty(Array.prototype, "filterGroupsRecursive", {
    configurable: true,
    value: function (func: Function) {
        return filterRecurseWithReturn(this, func, layer => isGroup(layer))
    },
})

/**Removes layers and groups that do not conform to the condition.*/
Object.defineProperty(Array.prototype, "filterLayersAndGroupsRecursive", {
    configurable: true,
    value: function (func: Function) {
        return filterRecurseWithReturnNoSelector(this, func)
    },
})

/**Takes a callback function and returns a new array with the newly mapped layers.*/
Object.defineProperty(Array.prototype, "mapLayersRecursive", {
    configurable: true,
    value: function (func: Function) {
        return recurseWithReturn(this, func, layer => !isGroup(layer))
    },
})

/**Takes a callback function and returns a new array with the newly mapped groups.*/
Object.defineProperty(Array.prototype, "mapGroupsRecursive", {
    configurable: true,
    value: function (func: Function) {
        return recurseWithReturn(this, func, layer => isGroup(layer))
    },
})

/**Checks if a layer is a child of a group.*/
Object.defineProperty(Array.prototype, "isChildOf", {
    configurable: true,
    value: function (childId: string, parentId: string) {
        return isChildOf(this, childId, parentId)
    },
})

/** Exporting the functions */
export { isGroup }
