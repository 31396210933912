import { useEffect, useRef, useState } from "react"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode"
import { AddCircle, GetApp, MoreHoriz, PlaylistAdd, Publish } from "@mui/icons-material"
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import { Button, Divider, Grid, ListItemIcon, ListItemText, SvgIcon, Tooltip, Typography } from "@mui/material"
import { kml } from "@tmcw/togeojson"
import turfArea from "@turf/area"
import turfCenter from "@turf/center"
import { lineString } from "@turf/helpers"
import turfLength from "@turf/length"
import turfMidpoint from "@turf/midpoint"
import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode } from "mapbox-gl-draw-circle"
import { useDispatch, useSelector } from "react-redux"
import shp from "shpjs"
import { v4 as generateUuid } from "uuid"
import { useMap } from "@emblautec/react-map-gl"
import { getIsLayerTypeRaster } from "@windgis/shared"
import * as digitizeActions from "actions/digitize"
import { digitizeTools } from "constants/digitizeTools"
import { STYLE_TYPES } from "constants/layers/styleTypes"
import { getWidgets } from "features/mapTools/selectors"
import { closeWidgets } from "features/mapTools/slice"
import { useGetColorsArrayQuery } from "features/utils/api"
import { MapIds } from "model/enums/MapIds"
import {
    getDigitizeEditingStatus,
    getDigitizeLayers,
    getNumberOfSelectedDigitizeLayersSelector,
    getSelectedFeature,
} from "selectors/digitizeSelectors"
import { getStyleConfig, getStylesForDigitizeLayerIdSelector } from "selectors/stylesSelectors"
import { useMainDraw } from "utils/customHooks/map/useMainDraw"
import { useMapHandlers } from "utils/customHooks/map/useMapHandlers"
import { useRefData } from "utils/customHooks/map/useRefData"
import toastr from "utils/customToastr"
import { digitizeLayerToStyle } from "utils/digitizeUtils"
import { digitizeIcons } from "utils/mapIcons"
import { StyledTab, StyledTabs } from "../../common/customTabs/customTabs"
import { deselectAllFeatures } from "../../map/utils/deselectAllFeatures"
import CsvDialog from "../components/csvDialog"
import StyleList from "../layerSelector/style/styleList"
import StyledMenu from "./components/styledMenu"
import StyledMenuItem from "./components/styledMenuItem"
import DownloadDialog from "./digitizeDownloadDialog"
import DigitizeLayers from "./digitizeLayers"
import { useDigitizeStyles } from "./styles/digitizeStyles"

let modes = {
    ...MapboxDraw.modes,
    direct_select: DirectMode,
    drag_circle: DragCircleMode,
    draw_circle: CircleMode,
    simple_select: SimpleSelectMode,
}
modes.static = StaticMode

let drawingDistanceLabels = {
    features: [],
    type: "FeatureCollection",
}

const STYLE_TAB = "style"

const Digitize = () => {
    const classes = useDigitizeStyles()

    const { mainMap } = useMap()
    const draw = useMainDraw()
    const { popHandler, pushHandlers } = useMapHandlers({ mapId: MapIds.MainMap })

    const [csvFile, setCsvFile] = useState(null)
    const [selectedFeatures, setSelectedFeatures] = useState([])
    //     const [loaded, setLoaded] = useState(false);
    //     const [isDroppingFile, setIsDroppingFile] = useState(false);
    const [open, setOpen] = useState(false)
    const [downloadAllLayers, setDownloadAllLayers] = useState(false)
    const [addDataMenuAnchor, setAddDataMenuAnchor] = useState(null)
    const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null)
    const [selectedLayer, _setSelectedLayer] = useState({})
    const selectedLayerRef = useRef(selectedLayer)
    const setSelectedLayer = newSelectedLayer => {
        selectedLayerRef.current = newSelectedLayer
        _setSelectedLayer(newSelectedLayer)
    }

    const [isStylerOpen, setIsStylerOpen] = useState(false)
    const [selectedFeatureMarkers, setSelectedFeatureMarkers] = useState([])

    const drawingDistanceMarkersArrayRef = useRef([])

    const editing = useSelector(getDigitizeEditingStatus)
    const selectedFeature = useSelector(getSelectedFeature)
    const widgets = useSelector(getWidgets)
    const digitizeLayers = useSelector(getDigitizeLayers)
    const numberOfSelectedLayers = useSelector(getNumberOfSelectedDigitizeLayersSelector)
    const styleConfig = useSelector(getStyleConfig)
    const styles = useSelector(getStylesForDigitizeLayerIdSelector(selectedLayer?.id))

    const { data: colorsArray = [] } = useGetColorsArrayQuery()

    const dispatch = useDispatch()

    const clickHandlerData = useRefData({
        draw,
    })

    const moveMouseHandlerData = useRefData({
        draw,
    })

    const [tab, setTab] = useState(STYLE_TAB)

    useEffect(() => {
        if (!draw) return

        resetDrawingState()
    }, [draw, widgets, editing])

    useEffect(() => {
        if (!draw || !mainMap) return
        draw.changeMode("simple_select")

        const digitizeHandlers = {
            onClick: onMapClick,
            onMouseMove: onCreatingFeature,
        }

        pushHandlers(digitizeHandlers)
        mainMap.on("draw.create", onFeatureCreated)
        mainMap.on("draw.update", onFeatureUpdate)
        mainMap.on("draw.selectionchange", onSelectionChanged)

        if (!mainMap.getLayer("digitize-drawn-line-measurement") && mainMap.isStyleLoaded()) {
            mainMap.getMap().addSource("digitize-drawn-line-measurement", {
                data: drawingDistanceLabels,
                type: "geojson",
            })

            mainMap.getMap().addLayer({
                id: "digitize-drawn-line-measurement",
                layout: {
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                    "symbol-placement": "point",
                    "text-anchor": "center",
                    "text-field": "{distance}\n{unit}",
                    "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                    "text-justify": "center",
                    "text-size": 13,
                },
                paint: {
                    "text-color": "#000000",
                },
                source: "digitize-drawn-line-measurement",
                type: "symbol",
            })
        }
        document.getElementById(MapIds.MainMap).addEventListener("keydown", onPressingEsc)

        return () => {
            if (mainMap) {
                setSelectedFeatureMarkers([])
                if (
                    draw.getMode() === "draw_line_string" ||
                    draw.getMode() === "draw_point" ||
                    draw.getMode() === "draw_polygon" ||
                    draw.getMode() === "drag_circle"
                ) {
                    draw.trash()
                }

                resetDrawingState()
                draw.changeMode("static")

                mainMap.off("draw.create", onFeatureCreated)
                mainMap.off("draw.update", onFeatureUpdate)
                mainMap.off("draw.selectionchange", onSelectionChanged)
                mainMap.off("mousemove", onCreatingFeature)

                if (mainMap.getLayer("digitize-drawn-line-measurement"))
                    mainMap.getMap().removeLayer("digitize-drawn-line-measurement")
                if (mainMap.getSource("digitize-drawn-line-measurement"))
                    mainMap.getMap().removeSource("digitize-drawn-line-measurement")
            }
            popHandler("onClick")
            popHandler("onMouseMove")

            dispatch(digitizeActions.selectFeature(null))
            dispatch(digitizeActions.editingFeatures(false))
            document.getElementById(MapIds.MainMap).removeEventListener("keydown", onPressingEsc)
        }
    }, [draw, mainMap])

    useEffect(() => {
        //Deselect all features
        if (!editing) {
            deselectAllFeaturesAndLabels()
        }
    }, [selectedLayer?.id, addDataMenuAnchor, actionsMenuAnchor])

    useEffect(() => {
        if (editing && selectedFeatures[0]) {
            let feature = selectedFeatures[0]
            let newLayer = { ...selectedLayer }
            if (!newLayer.features) {
                newLayer.features = []
                newLayer.features.push(feature.id)
                dispatch(digitizeActions.editLayer(newLayer))
            } else {
                newLayer.features.push(feature.id)
                dispatch(digitizeActions.editLayer(newLayer))
            }
            setSelectedLayer(newLayer)
            dispatch(digitizeActions.editingFeatures(false))
        }
    }, [selectedFeatures])

    useEffect(() => {
        if (!!selectedFeatureMarkers.length && selectedFeature === null) {
            selectedFeatureMarkers.forEach(marker => {
                marker.remove()
            })
        }
    }, [selectedFeature])

    const onPressingEsc = e => {
        if (e.key === "Escape") {
            resetDrawingState()
            dispatch(digitizeActions.editingFeatures(false))
        }
    }

    const deselectAllFeaturesAndLabels = () => {
        resetDrawingState()
        deselectAllFeatures(draw)
    }

    const resetDrawingState = () => {
        drawingDistanceMarkersArrayRef.current = []
        drawingDistanceLabels.features = []
        if (mainMap.getMap()) mainMap.getSource("digitize-drawn-line-measurement")?.setData(drawingDistanceLabels)
    }

    const handleClickActions = event => {
        setActionsMenuAnchor(event.currentTarget)
    }

    const handleCloseActions = () => {
        setActionsMenuAnchor(null)
    }

    const handleClickAddData = event => {
        setAddDataMenuAnchor(event.currentTarget)
    }

    const handleCloseAddData = () => {
        setAddDataMenuAnchor(null)
    }

    const onDigitizeStart = () => {
        mainMap.getMap().addSource("digitize-drawn-line-measurement", {
            data: drawingDistanceLabels,
            type: "geojson",
        })

        mainMap.getMap().addLayer({
            id: "digitize-drawn-line-measurement",
            layout: {
                "icon-allow-overlap": true,
                "icon-ignore-placement": true,
                "symbol-placement": "point",
                "text-allow-overlap": true,
                "text-anchor": "center",
                "text-field": "{distance}\n{unit}",
                "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                "text-justify": "center",
                "text-size": 13,
            },
            paint: {
                "text-color": "#000000",
            },
            source: "digitize-drawn-line-measurement",
            type: "symbol",
        })
    }

    const onFeatureCreated = e => {
        drawingDistanceMarkersArrayRef.current = []
        let createdFeature = e.features[0]
        if (createdFeature.properties.isCircle) {
            createdFeature.properties.name = "Circle"
        }

        createdFeature.properties.name = createdFeature.geometry.type
        createdFeature.properties.layerId = selectedLayerRef.current.id
        createdFeature.properties.layerName = selectedLayerRef.current.name
        dispatch(digitizeActions.addDrawnFeatures(e.features))
        dispatch(digitizeActions.selectFeature(createdFeature))
        draw.setFeatureProperty(createdFeature.id, "customStyle", true)
        draw.setFeatureProperty(createdFeature.id, "hidden", !selectedLayerRef.current.selected)

        selectedLayerRef.current.properties.forEach(property => {
            draw.setFeatureProperty(createdFeature.id, property.name, property.value)
        })
        draw.add(draw.get(createdFeature.id))

        createdFeature.geometry.type !== "Point" && renderDistanceMarkers(e)
    }

    const renderDistanceMarkers = (lines, helpLine = null) => {
        let drawnLinesFromParameter = []
        if (!!lines.features?.length) {
            if (lines.features[0].properties.isCircle) {
                drawnLinesFromParameter = []
            } else {
                if (lines.features[0].geometry.type === "Polygon") {
                    drawnLinesFromParameter = [...lines.features[0].geometry.coordinates[0]]
                } else {
                    drawnLinesFromParameter = [...lines.features[0].geometry.coordinates]
                }
            }
        } else {
            drawnLinesFromParameter = [...lines]
        }

        if (helpLine) {
            drawnLinesFromParameter[drawnLinesFromParameter.length] = helpLine
        }
        let polygonLines = []
        let unit = "kilometers"
        let unitShort = "km"
        polygonLines = drawnLinesFromParameter
        let points = []

        if (!mainMap.getLayer("digitize-drawn-line-measurement") && mainMap.isStyleLoaded()) {
            mainMap.getMap().addSource("digitize-drawn-line-measurement", {
                data: drawingDistanceLabels,
                type: "geojson",
            })

            mainMap.getMap().addLayer({
                id: "digitize-drawn-line-measurement",
                layout: {
                    "icon-allow-overlap": true,
                    "icon-ignore-placement": true,
                    "symbol-placement": "point",
                    "text-anchor": "center",
                    "text-field": "{distance}\n{unit}",
                    "text-font": ["Open Sans Regular", "Arial Unicode MS Regular"],
                    "text-justify": "center",
                    "text-size": 13,
                },
                paint: {
                    "text-color": "#000000",
                },
                source: "digitize-drawn-line-measurement",
                type: "symbol",
            })
        }

        if (polygonLines.length > 0) {
            for (let i = 0; i <= polygonLines.length - 2; i++) {
                let polygonLine = lineString([polygonLines[i], polygonLines[i + 1]], { name: `segment-${i + 1}` })
                if (polygonLine) {
                    let midPoint = turfMidpoint(polygonLines[i], polygonLines[i + 1])
                    let routeDistance = turfLength(polygonLine, { units: unit })
                    routeDistance = Number.parseFloat(routeDistance).toFixed(2)

                    points.push({
                        geometry: {
                            coordinates: midPoint.geometry.coordinates,
                            type: "Point",
                        },
                        properties: { distance: routeDistance, unit: unitShort },
                        type: "Feature",
                    })
                }
            }
            drawingDistanceLabels.features = points
            if (mainMap.getSource("digitize-drawn-line-measurement")) {
                mainMap.getSource("digitize-drawn-line-measurement").setData(drawingDistanceLabels)
            }
        } else {
            let circleCenter = turfCenter(lines.features[0])
            let circleArea = turfArea(lines.features[0]) / 1000000
            points.push({
                geometry: {
                    coordinates: circleCenter.geometry.coordinates,
                    type: "Point",
                },
                properties: { distance: circleArea.toFixed(2), unit: unitShort },
                type: "Feature",
            })
            drawingDistanceLabels.features = points
            if (mainMap.getSource("digitize-drawn-line-measurement")) {
                mainMap.getSource("digitize-drawn-line-measurement").setData(drawingDistanceLabels)
            }
        }
    }

    const onMapClick = e => {
        const { draw } = clickHandlerData.current
        if (
            draw.getMode() !== "static" &&
            draw.getMode() !== "simple_select" &&
            draw.getMode() !== "draw_point" &&
            draw.getMode() !== "direct_select" &&
            draw.getMode() !== "drag_circle"
        ) {
            const point = []
            point[0] = e.lngLat.lng
            point[1] = e.lngLat.lat
            drawingDistanceMarkersArrayRef.current.push(point)
        }
    }

    const onFeatureUpdate = e => {
        dispatch(digitizeActions.editFeature(e.features[0]))
        if (draw.getMode() === "direct_select" || draw.getMode() === "drag_circle") {
            e.features[0].geometry.type !== "Point" && renderDistanceMarkers(e)
        }
    }

    const onCreatingFeature = e => {
        const { draw } = moveMouseHandlerData.current
        if (
            draw.getMode() !== "static" &&
            draw.getMode() !== "simple_select" &&
            draw.getMode() !== "draw_point" &&
            draw.getMode() !== "drag_circle"
        ) {
            const currentMousePosition = e.lngLat
            if (
                drawingDistanceMarkersArrayRef.current.length > 0 &&
                !!Object.getOwnPropertyNames(currentMousePosition).length
            ) {
                renderDistanceMarkers(drawingDistanceMarkersArrayRef.current, [
                    currentMousePosition.lng,
                    currentMousePosition.lat,
                ])
            }
        }
    }

    const onSelectionChanged = e => {
        if (!e.features.length) {
            drawingDistanceMarkersArrayRef.current = []
            let emptyDrawingDistanceLabels = {
                features: [],
                type: "FeatureCollection",
            }
            mainMap.getSource("digitize-drawn-line-measurement") &&
                mainMap.getSource("digitize-drawn-line-measurement").setData(emptyDrawingDistanceLabels)
        } else {
            if (Array.isArray(e.features[0].geometry.coordinates[0])) {
                renderDistanceMarkers(e)
            }
        }
        setSelectedFeatureMarkers([])
        setSelectedFeatures(e.features)
    }

    const onAddLayer = (e, layer) => {
        e && e.stopPropagation()
        handleCloseAddData()

        let newLayer = { ...layer }
        newLayer.datasetId = generateUuid()
        newLayer.id = newLayer.datasetId + ":0"

        newLayer.type = layer.name

        const typeToStyleType = {
            circle: "fill",
            line: "line",
            point: "circle",
            polygon: "fill",
        }
        const styleType = typeToStyleType[newLayer.type]
        newLayer.styleType = styleType

        const properties = JSON.parse(JSON.stringify(styleConfig[styleType]))
        newLayer.properties = properties
        newLayer.layout = properties.filter(x => x.type === "layout")
        newLayer.paint = properties.filter(x => x.type === "paint")

        newLayer.editing = false
        newLayer.name = layer.name
        newLayer.styleCount = 1
        newLayer.selected = true
        newLayer.features = []
        dispatch(digitizeActions.addLayer(newLayer))
        dispatch(digitizeActions.editingFeatures(true))
        dispatch(digitizeActions.addDigitizeStyle(digitizeLayerToStyle(newLayer, 0)))
        // start feature creation by default when adding a new layer
        setSelectedLayer(newLayer)

        if (editing) {
            draw.trash()
        }
        onCloseWidgets()
        switch (newLayer.type) {
            case "point":
                draw.changeMode("draw_point")
                break
            case "line":
                draw.changeMode("draw_line_string")
                break
            case "polygon":
                draw.changeMode("draw_polygon")
                break
            case "circle":
                draw.changeMode("drag_circle")
                break
            default:
                return null
        }
    }

    const onCloseWidgets = () => {
        dispatch(closeWidgets())
    }

    const handleClickOpenDialog = downloadAllLayers => {
        setDownloadAllLayers(downloadAllLayers)
        setOpen(true)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    const selectAll = () => {
        dispatch(digitizeActions.toggleAllDigitizeLayers(true))

        digitizeLayers.forEach(layer => {
            layer.features.forEach(featureId => {
                draw.setFeatureProperty(featureId, "hidden", false)
                draw.add(draw.get(featureId))
            })
        })

        resetDrawingState()
    }

    const onFileSelected = e => {
        const uploadedFile = e.target.files[0]

        var fileSize = (uploadedFile.size / 1024 / 1024).toFixed(4) // MB
        if (fileSize > 10) {
            toastr.error("For performance reasons, the maximum upload size is of 10 Mb.")
            return
        }

        //This will allow us to upload the same file twice.
        document.getElementById("digitize-upload-features").value = ""
        loadFile(uploadedFile)
    }

    const handleCsvDialogClose = () => {
        setCsvFile(null)
    }

    const loadFile = file => {
        let fileNameSplit = file.name.split(".")
        let fileType = fileNameSplit[fileNameSplit.length - 1]
        if (fileType === "csv") {
            setCsvFile(file)
            return
        }

        new Promise(resolve => {
            let reader = new FileReader()
            reader.onload = () => {
                resolve(reader.result)
            }
            if (fileType === "zip") {
                reader.readAsArrayBuffer(file)
            } else {
                reader.readAsText(file)
            }
        }).then(res => {
            let geoJson
            switch (fileType) {
                case "kml":
                    try {
                        let parser = new DOMParser()
                        let xmlDoc = parser.parseFromString(res, "text/xml")
                        geoJson = kml(xmlDoc)
                    } catch {
                        alert("Invalid data!")
                    }
                    break
                case "geojson":
                    try {
                        geoJson = JSON.parse(res)
                    } catch {
                        alert("Invalid data!")
                    }
                    break
                case "zip":
                    try {
                        let parsedGeojson = shp.parseZip(res)
                        if (typeof parsedGeojson === "object" && parsedGeojson !== null) {
                            geoJson = parsedGeojson
                        } else {
                            let data = {
                                features: [],
                                type: "FeatureCollection",
                            }
                            parsedGeojson.forEach(featureCollection => {
                                featureCollection.features.forEach(feature => {
                                    feature.id = feature.properties.id
                                    data.features.push(feature)
                                })
                            })
                            geoJson = data
                        }
                    } catch (error) {
                        alert("Invalid data!")
                    }
                    break
                default:
                    break
            }
            loadGeoJson(geoJson)
        })
    }

    const loadGeoJson = geoJson => {
        if (!geoJson) return

        geoJson.features = geoJson.features.map(x => {
            return { ...x, id: generateUuid() }
        })
        draw.add(geoJson)
        let newlyCreatedLayers = []

        geoJson.features.forEach(feature => {
            if (
                !newlyCreatedLayers.some(layer =>
                    feature.geometry.type === "LineString"
                        ? layer.namePretty === "Line"
                        : feature.properties.isCircle
                          ? layer.namePretty === "Circle"
                          : layer.namePretty === feature.geometry.type,
                )
            ) {
                let layer = {}
                let geometry =
                    feature.geometry.type === "LineString"
                        ? "Line"
                        : feature.properties.isCircle
                          ? "Circle"
                          : feature.geometry.type
                let layerType = geometry

                const typeToStyleType = {
                    Circle: "fill",
                    Line: "line",
                    MultiLine: "line",
                    MultiPoint: "circle",
                    MultiPolygon: "fill",
                    Point: "circle",
                    Polygon: "fill",
                }
                const styleType = typeToStyleType[layerType]
                layer.styleType = styleType

                const properties = JSON.parse(JSON.stringify(styleConfig[styleType]))
                layer.properties = properties
                layer.layout = properties.filter(x => x.type === "layout")
                layer.paint = properties.filter(x => x.type === "paint")

                layer.name = layerType.toLowerCase()
                layer.type = layerType.toLowerCase()
                layer.namePretty = layerType
                layer.icon = `${layerType.toLowerCase()}Icon`
                layer.datasetId = generateUuid()
                layer.id = layer.datasetId + ":0"

                layer.editing = false
                layer.enabled = true
                layer.selected = true

                layer.features = []
                layer.styleCount = 1

                newlyCreatedLayers.push(layer)
            }
            let typeOfSelectedLayer =
                feature.geometry.type === "LineString"
                    ? "Line"
                    : feature.properties.isCircle
                      ? "Circle"
                      : feature.geometry.type
            let foundLayer = newlyCreatedLayers.find(layer => layer.namePretty === typeOfSelectedLayer)
            feature.properties.layerId = foundLayer.id
            feature.properties.layerName = foundLayer.name

            foundLayer.features.push(feature.id)
        })

        newlyCreatedLayers.forEach(newLayer => {
            dispatch(digitizeActions.addLayer(newLayer))
            dispatch(digitizeActions.addDigitizeStyle(digitizeLayerToStyle(newLayer, 0)))
        })
        dispatch(digitizeActions.addDrawnFeatures(geoJson.features))

        newlyCreatedLayers.forEach(newLayer => {
            newLayer.features.forEach(featureId => {
                draw.setFeatureProperty(featureId, "customStyle", true)
                draw.setFeatureProperty(featureId, "hidden", false)

                newLayer.properties.forEach(property => {
                    draw.setFeatureProperty(featureId, property.name, property.value)
                })
            })
        })
        let fileInput = document.getElementById("digitize-upload-features")
        fileInput.value = ""
    }

    const onPropertiesChanged = (properties, layer) => {
        const newLayer = {
            ...digitizeLayers.find(oldLayer => oldLayer.datasetId === layer.datasetId),
            properties,
        }

        dispatch(digitizeActions.updateDigitizeStyle({ layerId: layer.layerId, properties }))
        dispatch(digitizeActions.editLayer(newLayer))
        setSelectedLayer({ ...selectedLayer, properties })

        newLayer.features.forEach(featureId => {
            newLayer.properties.forEach(property => {
                draw.setFeatureProperty(featureId, property.name, property.value)
            })
            draw.add(draw.get(featureId))
        })
    }

    const addStyleToRasterLayer = layer => {
        const layerId = `${layer.datasetId}:${layer.styleCount}`
        const properties = styleConfig[STYLE_TYPES.raster]

        let styleLayer = {
            datasetId: layer.datasetId,
            layerId: layerId,
            layout: properties.filter(x => x.type === "layout"),
            name: layer.name,
            paint: properties.filter(x => x.type === "paint"),
            properties: properties,
            sourceId: layer.datasetId,
            sourceName: layer.name,
            type: "raster",
            visible: true,
        }
        layer.styleCount += 1
        dispatch(digitizeActions.editLayer(layer))
        dispatch(digitizeActions.addDigitizeStyle(styleLayer))
    }

    const addStyleToVectorLayer = layer => {
        let layerId = `${layer.datasetId}:${layer.styleCount}`
        let styleLayer = {
            datasetId: layer.datasetId,
            layerId: layerId,
            layout: layer.properties.filter(x => x.type === "layout"),
            name: layer.name,
            paint: layer.properties.filter(x => x.type === "paint"),
            properties: layer.properties,
            sourceId: layer.sourceId,
            sourceName: layer.name,
            type: layer.styleType,
        }

        layer.styleCount += 1
        dispatch(digitizeActions.editLayer(layer))
        dispatch(digitizeActions.addDigitizeStyle(styleLayer))
    }

    const onAddStyleToLayer = layer => {
        if (getIsLayerTypeRaster(layer.type)) {
            addStyleToRasterLayer(layer)
        } else {
            addStyleToVectorLayer(layer)
        }
    }

    const onRemoveStyleFromLayer = (layer, styleLayer) => {
        if (layer.styleCount > 1) {
            layer.styleCount -= 1
            const firstGoodStyle = styles.find(x => x.layerId !== styleLayer.layerId)
            layer.properties = firstGoodStyle.properties
            layer.paint = firstGoodStyle.paint
            layer.layout = firstGoodStyle.layout
            layer.features.forEach(featureId => {
                firstGoodStyle.properties.forEach(property => {
                    draw.setFeatureProperty(featureId, property.name, property.value)
                })
                draw.add(draw.get(featureId))
            })

            dispatch(digitizeActions.editLayer(layer))
            dispatch(digitizeActions.removeDigitizeStyle(styleLayer))
            dispatch(digitizeActions.reinitializeDigitizeStylesLayerId(layer))
        }
    }

    const onStyleTypeChanged = (type, styleLayer) => {}

    let styler =
        styles !== undefined ? (
            <StyleList
                colorsArray={colorsArray}
                layer={selectedLayer}
                styles={styles}
                isDigitizeLayer={true}
                onAddStyleToLayer={onAddStyleToLayer}
                onPropertiesChanged={onPropertiesChanged}
                onPropertyExpressionTypeChanged={onStyleTypeChanged} //Maybe render alternatives can be left out
                onRemoveStyleFromLayer={onRemoveStyleFromLayer}
                onTypeChanged={onStyleTypeChanged} //Maybe render alternatives can be left out, otherwise add geometryType to each layer/style
                onZoomSliderChange={() => {}} //No example implementation
            ></StyleList>
        ) : null

    return (
        <Grid className={classes.root} container direction="column">
            <Grid className={classes.content} item xs={12}>
                <Grid item xs={12}>
                    <Divider />
                    <Grid alignItems="center" className={classes.title} container justifyContent="space-between">
                        <Typography variant="h6">Digitize</Typography>
                        <input
                            accept=".kml, .geojson, .csv, .zip"
                            className={classes.input}
                            id="digitize-upload-features"
                            type="file"
                            onChange={onFileSelected}
                        />
                        <div>
                            <Tooltip className={classes.marginRight} title="Digitize actions">
                                <Button
                                    aria-label="Actions"
                                    color="inherit"
                                    size="small"
                                    variant="outlined"
                                    startIcon={<MoreHoriz />}
                                    onClick={handleClickActions}
                                >
                                    Actions
                                </Button>
                            </Tooltip>
                            <Button
                                aria-label="Add data"
                                color="primary"
                                size="small"
                                variant="contained"
                                startIcon={<AddCircle />}
                                onClick={handleClickAddData}
                            >
                                Add data
                            </Button>
                        </div>

                        <StyledMenu
                            anchorEl={addDataMenuAnchor}
                            id="digitize-add-data-menu"
                            keepMounted
                            open={Boolean(addDataMenuAnchor)}
                            onClose={handleCloseAddData}
                        >
                            {digitizeTools.map(tool => (
                                <Tooltip key={tool.name} title={`Add ${tool.namePretty} layer`}>
                                    <StyledMenuItem onClick={e => onAddLayer(e, tool)}>
                                        <ListItemIcon className={classes.digitizeToolsIcon}>
                                            <SvgIcon className={classes.buttonIcon}>{digitizeIcons[tool.icon]}</SvgIcon>
                                        </ListItemIcon>

                                        <ListItemText
                                            className={classes.digitizeToolsText}
                                            primary={`${tool.namePretty} layer`}
                                        />
                                    </StyledMenuItem>
                                </Tooltip>
                            ))}
                        </StyledMenu>
                        <StyledMenu
                            anchorEl={actionsMenuAnchor}
                            id="digitize-actions-menu"
                            keepMounted
                            open={Boolean(actionsMenuAnchor)}
                            onClose={handleCloseActions}
                        >
                            <label htmlFor="digitize-upload-features">
                                <Tooltip title="Upload features">
                                    <StyledMenuItem>
                                        <ListItemIcon className={classes.digitizeToolsIcon}>
                                            <Publish />
                                        </ListItemIcon>
                                        <ListItemText className={classes.digitizeToolsText}>Upload</ListItemText>
                                    </StyledMenuItem>
                                </Tooltip>
                            </label>
                            <Tooltip title="Download selected layers">
                                <StyledMenuItem
                                    disabled={!numberOfSelectedLayers}
                                    onClick={() => handleClickOpenDialog(false)}
                                >
                                    <ListItemIcon className={classes.digitizeToolsIcon}>
                                        <GetAppOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText className={classes.digitizeToolsText}>
                                        Download selected layers
                                    </ListItemText>
                                </StyledMenuItem>
                            </Tooltip>
                            <Tooltip title="Download all layers">
                                <StyledMenuItem
                                    disabled={!digitizeLayers.length}
                                    onClick={() => handleClickOpenDialog(true)}
                                >
                                    <ListItemIcon className={classes.digitizeToolsIcon}>
                                        <GetApp />
                                    </ListItemIcon>
                                    <ListItemText className={classes.digitizeToolsText}>Download all</ListItemText>
                                </StyledMenuItem>
                            </Tooltip>
                            <Tooltip title="Select all">
                                <StyledMenuItem onClick={selectAll}>
                                    <ListItemIcon className={classes.digitizeToolsIcon}>
                                        <PlaylistAdd />
                                    </ListItemIcon>
                                    <ListItemText className={classes.digitizeToolsText}>Select all</ListItemText>
                                </StyledMenuItem>
                            </Tooltip>
                        </StyledMenu>
                    </Grid>
                    <Divider />
                    <DownloadDialog open={open} downloadAllLayers={downloadAllLayers} onClose={handleCloseDialog} />
                </Grid>
                <Grid className={classes.layersContainer} item xs={12}>
                    <DigitizeLayers
                        deselectAllFeaturesAndLabels={deselectAllFeaturesAndLabels}
                        selectedLayer={selectedLayer}
                        resetDrawingState={resetDrawingState}
                        setIsStylerOpen={setIsStylerOpen}
                        setSelectedLayer={setSelectedLayer}
                        renderDistanceMarkers={renderDistanceMarkers}
                    />
                </Grid>
            </Grid>
            {isStylerOpen && (
                <div className="edit-view-container">
                    <StyledTabs TabIndicatorProps={<div />} value={tab}>
                        <StyledTab label={<div>Style</div>} value={STYLE_TAB} onClick={() => setTab(STYLE_TAB)} />
                        <div className="close-button">
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                    setIsStylerOpen(false)
                                }}
                            >
                                Close
                            </Button>
                        </div>
                    </StyledTabs>
                    <Divider />
                    {<div className={classes.stylerDivWrapper}>{styler}</div>}
                </div>
            )}
            <CsvDialog csvFile={csvFile} loadGeoJson={loadGeoJson} handleClose={handleCsvDialogClose} />
        </Grid>
    )
}

export default Digitize
