import { getOperatorNameBySymbol, getTypeNameBySymbol } from "model/map/LayerFilterType"

export const defaultSpacing = {
    multiLabelEntryBreakpointSpacing: 12,
    multiLabelItemBreakpointSpacing: 6,
    multiLabelItemVerticalBreakpointSpacing: 12,
    singleEntryBreakpointSpacing: 12,
}

export const extractStyledColumnName = colorProperties => {
    if (!colorProperties || colorProperties.length === 0) return null

    const colorProperty = colorProperties[0]
    const operation = colorProperty.value[0]
    const fieldName = operation == "interpolate" ? colorProperty.value[2][1] : colorProperty.value[1][1]

    return fieldName
}

export const editFiltersList = filters => {
    if (!filters || filters.length === 0) return []

    return filters.map(f => ({
        ...f,
        operatorName: getOperatorNameBySymbol(f.operatorName) ?? f.operatorName,
        type: getTypeNameBySymbol(f.type) ?? f.type,
    }))
}

export const buildColorProperties = (colorProperties, distinctValues) => {
    const dprStyle = colorProperties.find(({ styleType }) => styleType === "pie-chart" || styleType === "box-chart")

    if (dprStyle) {
        return distinctValues.length !== 0 ? colorProperties : []
    }

    if (!colorProperties || colorProperties.length === 0) return []

    const colorProperty = colorProperties[0]
    const operation = colorProperty.value[0]

    if (operation === "interpolate") {
        if (!distinctValues || distinctValues.length === 0) return colorProperties
        let newPropertiesArray = JSON.parse(JSON.stringify(colorProperties))
        let valuesArray = newPropertiesArray[0].value
        const valuesArrayNoSettings = valuesArray.slice(3)

        const newColors = distinctValues.reduce((acc, value) => {
            var smallestDistance = valuesArrayNoSettings[valuesArrayNoSettings.length - 2]
            var closestValueIndex
            var closestValue
            for (let i = 0; i < valuesArrayNoSettings.length; i += 2) {
                const v = valuesArrayNoSettings[i]
                var dist = Math.abs(v - value)
                if (dist < smallestDistance) {
                    smallestDistance = dist
                    closestValueIndex = i
                    closestValue = v
                }
            }
            const indexOfClosestValueInAcc = acc.indexOf(closestValue)
            if (indexOfClosestValueInAcc !== -1) return acc

            acc.push(closestValue)
            acc.push(valuesArrayNoSettings[closestValueIndex + 1])

            return acc
        }, [])

        valuesArray = [...valuesArray.slice(0, 3), ...newColors]

        newPropertiesArray[0].value = valuesArray

        return newPropertiesArray
    } else {
        if (!distinctValues || distinctValues.length === 0) return colorProperties
        let newPropertiesArray = JSON.parse(JSON.stringify(colorProperties))
        let valuesArray = newPropertiesArray[0].value

        const newColors = distinctValues.reduce((acc, value) => {
            acc.push(value)
            acc.push(valuesArray[valuesArray.indexOf(value) + 1])
            return acc
        }, [])

        valuesArray = [valuesArray[0], valuesArray[1], ...newColors, valuesArray[valuesArray.length - 1]]
        newPropertiesArray[0].value = valuesArray

        return newPropertiesArray
    }
}

const styleOrder = {
    "box-chart": 5,
    circle: 1,
    fill: 3,
    line: 2,
    "pie-chart": 6,
    raster: 4,
    symbol: 0,
}

const propertiesMapping = {
    "box-chart": {
        "box-chart-colors": "Colors",
        "box-chart-columns": "Columns",
        "box-chart-labels": "Labels",
        "box-chart-rows": "Rows",
        "box-chart-stroke-color": "StrokeColor",
        "box-chart-stroke-width": "StrokeWidth",
    },
    circle: {
        "circle-color": "Color",
        "circle-stroke-color": "StrokeColor",
        "circle-stroke-width": "StrokeWidth",
    },
    fill: {
        "fill-color": "Color",
    },
    line: {
        "line-color": "Color",
        "line-dasharray": "DashArray",
    },
    "pie-chart": {
        "pie-chart-center-size": "CenterSize",
        "pie-chart-colors": "Colors",
        "pie-chart-labels": "Labels",
        "pie-chart-size": "Size",
        "pie-chart-stroke-color": "StrokeColor",
        "pie-chart-stroke-width": "StrokeWidth",
    },
    raster: {
        "fill-color": "Color",
    },
    symbol: {
        "icon-color": "IconColor",
        "icon-image": "Image",
        "text-color": "Color",
    },
}

export const buildStyling = styles => {
    let styling = { type: "symbol" }

    for (let i = 0; i < styles.length; i++) {
        let style = styles[i]
        let propertyMapping = propertiesMapping[style.type]

        if (!propertyMapping) continue
        //Set the style type to the highest style type among the styles.
        //As an example: for a layer with a circle and fill style, select fill
        if (styleOrder[style.type] > styleOrder[styling.type]) {
            styling.type = style.type
        }

        for (let j = 0; j < style.properties.length; j++) {
            let property = style.properties[j]
            let styleName = style.type + propertyMapping[property.name]

            if (propertyMapping[property.name] && !styling.hasOwnProperty(styleName)) {
                styling[styleName] = property.value
            }
        }
    }

    return styling
}

export const setFixedNumberOfDecimals = (expressionProperty, rightAlign) => {
    let expressionPropertyCopy = { ...expressionProperty, value: [...expressionProperty.value] }
    const valuesOffset = expressionPropertyCopy.expressionType === "match" ? 2 : 3

    let longestValueLength = 0

    if (rightAlign) {
        let longestNumberOfDecimals = 0

        for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
            const expressionStringSplitted = expressionPropertyCopy.value[i].toString().split(".")

            if (!expressionStringSplitted[1] || expressionStringSplitted[1]?.length <= longestNumberOfDecimals) continue

            longestNumberOfDecimals = expressionStringSplitted[1]?.length
        }

        for (let i = valuesOffset; i < expressionPropertyCopy.value.length; i += 2) {
            const expressionVal = Number(expressionPropertyCopy.value[i])
            expressionPropertyCopy.value[i] = !!expressionVal
                ? expressionVal.toFixed(longestNumberOfDecimals)
                : expressionPropertyCopy.value[i]
            if (expressionPropertyCopy.value[i].length <= longestValueLength) continue

            longestValueLength = expressionPropertyCopy.value[i].toString().length
        }
    }
    return { expressionPropertyCopy, longestValueLength }
}
