import { FC, useContext, useState } from "react"
import { Close } from "@mui/icons-material"
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, IconButton, MenuItem, TextField } from "@mui/material"
import { Units } from "@turf/helpers"
import { useMap } from "@emblautec/react-map-gl"
import { CustomTypography } from "@windgis/shared"
import InfoTextSection from "components/common/InfoTextSection/InfoTextSection"
import ReadOnlyField from "components/common/readOnlyField/readOnlyField"
import config from "config"
import { BufferWWContext } from "features/buffer/components/BufferWWContextProvider"
import { allowedBufferUnits, mapMapboxFeaturesToTurfFeatures } from "features/buffer/components/utils"
import { toggleWidget } from "features/mapTools/slice"
import { AppLayer } from "model/app/AppLayer"
import { Widgets } from "model/enums/Widgets"
import { getLayerByResourceId } from "selectors/mapSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import customToastr from "utils/customToastr"
import { useBufferStyles } from "./styles"

type Props = {
    layer: AppLayer
}

const initialBufferRadius = 1
const initialUnit = "kilometers"

let allowedBufferKeys =
    config.bufferOptions.minBufferRadiusInMeters < 1000
        ? Object.keys(allowedBufferUnits)
        : Object.keys(allowedBufferUnits).filter(x => x !== "meters")

const Buffer: FC<Props> = ({ layer }) => {
    const classes = useBufferStyles()

    const [bufferRadius, setBufferRadius] = useState<number>(initialBufferRadius)
    const [bufferUnit, setBufferUnit] = useState<Units>(initialUnit)
    const [bufferDissolveResult, setBufferDissolveResult] = useState(false)

    const mapLayer = useAppSelector(getLayerByResourceId(layer.resourceId))

    const bufferWWContext = useContext(BufferWWContext)

    const dispatch = useAppDispatch()

    const { mainMap } = useMap()

    const onGenerateBuffer = () => {
        if (bufferRadius <= 0) {
            customToastr.error("Cannot generate a buffer with radius less than or equal to 0")
            return
        }

        const minBufferRadiusInMeters = config.bufferOptions.minBufferRadiusInMeters
        const bufferRadiusInMeters =
            allowedBufferUnits[bufferUnit as keyof typeof allowedBufferUnits].toMeters(bufferRadius)
        if (bufferRadiusInMeters < minBufferRadiusInMeters) {
            customToastr.error(
                `Generating buffers with radius smaller than ${minBufferRadiusInMeters} meters has been disabled`,
            )
            return
        }

        const mapScaleInMeters = calculateMapScale()
        if (mapScaleInMeters === null) {
            customToastr.error(`Buffer generation failed`)
            return
        }

        if (!mapLayer) {
            customToastr.error(`Layer is not initialized`)
            return
        }

        // @ts-ignore TODO: fix this
        const renderedFeatures = mainMap?.queryRenderedFeatures(undefined, { layers: [mapLayer.layerId] })
        if (!renderedFeatures || !renderedFeatures.length) {
            customToastr.warning(`Layer has no rendered features. Make sure the layer is visible on the map`)
            return
        }

        const maxScaleInMeters = 0.16 * bufferRadiusInMeters - 5
        if (mapScaleInMeters > maxScaleInMeters) {
            customToastr.warning(`Cannot generate buffer, please zoom in for better precision`)
            return
        }

        const mappedFeatures = mapMapboxFeaturesToTurfFeatures(renderedFeatures)

        bufferWWContext.generateBuffer(layer, mappedFeatures, bufferRadius, bufferUnit, bufferDissolveResult)

        dispatch(toggleWidget(Widgets.Buffer))
    }

    // scale of the map computed as the distance in meters/pixel in the center of the screen
    const calculateMapScale = () => {
        if (!mainMap) return null

        // x, y => coordinates (in pixels) for the center of the screen
        const y = mainMap.getCanvas().height / 2
        const x = mainMap.getCanvas().width / 2

        // unproject => Returns the geographical coordinates that correspond to the specified pixel coordinates
        // left => geographical coordinates for the center of the screen
        // right => geographical coordinates for the pixel next to the center of the screen
        const left = mainMap.unproject([x, y])
        const right = mainMap.unproject([x + 1, y])

        // scaleInMeters => scale of map in meters/pixel
        const scaleInMeters = left.distanceTo(right)

        return scaleInMeters
    }

    return (
        <div className={classes.root}>
            <Divider />
            <div className={classes.header}>
                <CustomTypography className={classes.headerText} variant="h6">
                    Generate Buffer
                </CustomTypography>
                <IconButton onClick={() => dispatch(toggleWidget(Widgets.Buffer))}>
                    <Close />
                </IconButton>
            </div>
            <Divider />
            <div className={classes.searchContainer}>
                <ReadOnlyField label="Layer" text={layer.name} />

                <CustomTypography sx={{ marginTop: 1 }} textWeight="bold" variant="body2">
                    Buffer Radius
                </CustomTypography>
                <FormGroup row>
                    <TextField
                        InputProps={{
                            inputProps: {
                                max: 1000,
                                min: 0,
                            },
                        }}
                        size="small"
                        sx={{ flexGrow: 1 }}
                        type="number"
                        value={bufferRadius}
                        variant="standard"
                        onChange={e => setBufferRadius(parseFloat(e.target.value))}
                    />
                    <TextField
                        select
                        size="small"
                        sx={{ marginLeft: 1 }}
                        value={bufferUnit}
                        variant="standard"
                        onChange={e => setBufferUnit(e.target.value as Units)}
                    >
                        {allowedBufferKeys.map(x => (
                            <MenuItem key={x} value={x}>
                                {allowedBufferUnits[x as keyof typeof allowedBufferUnits].key}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormGroup>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={bufferDissolveResult}
                            size="small"
                            sx={{ marginRight: 1 }}
                            onChange={(_, checked) => setBufferDissolveResult(checked)}
                        />
                    }
                    label="Dissolve result"
                    sx={{ marginTop: 1, marginX: 0 }}
                />

                <InfoTextSection
                    iconSize="extra-small"
                    sx={{ marginBottom: 1, marginTop: 2 }}
                    textVariant="body2"
                    textWeight="semibold"
                >
                    Only rendered features will be buffered
                </InfoTextSection>

                <Button color="primary" variant="contained" onClick={onGenerateBuffer}>
                    Generate
                </Button>
            </div>
        </div>
    )
}

export default Buffer
