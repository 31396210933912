import { FC, useState } from "react"
import ColorLensIcon from "@mui/icons-material/ColorLens"
import { Box, CircularProgress, Collapse, Stack, TableBody, Tooltip } from "@mui/material"
import { CollapsibleCard, CustomTypography, TruncatedTypography } from "@windgis/shared"
import { setSidebarOpen } from "actions/sidebarAndDrawer"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import { useBuildProperties } from "components/map/infoBoxes/hooks/useBuildProperties"
import { useFetchDprDatasetColumnsQuery } from "features/dprIntegration/api"
import {
    CollapsedExpandLess,
    ExpandedExpandLess,
    SquaredIconButton,
    StyledIconButton,
    StyledStack,
    StyledTable,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"
import { setStylerLayerId } from "reducers/layerSelector"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import copyButton from "utils/icons/copy-icon.svg"
import DprProperties from "../DprProperties/DprProperties"
import useDprLocationHistory from "../DprProperties/useDprLocationHistory"
import useInfoboxToUse from "../DprProperties/useInfoboxToUse"
import PropertiesDisplay from "../PropertiesDisplay"

type Props = {
    collapsed: boolean
    feature: mapboxgl.MapboxGeoJSONFeature
    featureIndex: number
    sourceToNameMapping: Record<string, string>
    hasMultipleFeatures: boolean
    toggleFeature: (index: number) => void
    onCopyClick: (index: number) => void
}

const VectorFeature: FC<Props> = ({
    collapsed,
    feature,
    featureIndex,
    sourceToNameMapping,
    hasMultipleFeatures,
    toggleFeature,
    onCopyClick,
}) => {
    const dispatch = useAppDispatch()
    const [datasetIsToggled, setDatasetIsToggled] = useState<boolean>(true)
    const [featureIsToggle, setFeatureIsToggle] = useState<boolean>(false)

    const infoboxToUse = useInfoboxToUse(feature.sourceLayer)
    const isPublic = useGetIsPublicApp()

    const {
        data: dprDatasetColumns = null,
        isError,
        isLoading: isDprDatasetColumnsLoading,
    } = useFetchDprDatasetColumnsQuery(
        { datasetId: feature.sourceLayer ?? "", isPublic },
        { skip: infoboxToUse !== "dpr" || !feature.sourceLayer },
    )

    const { properties, hasPropertiesToDisplay } = useBuildProperties({
        featureProperties: feature.properties,
        latestCompletedColumn: dprDatasetColumns?.latestCompletedColumn.prettyName,
        progressColumn: dprDatasetColumns?.progressColumn.prettyName,
    })

    const onEditLayerStyleClick = () => {
        dispatch(setSidebarOpen(true))
        // @ts-ignore TODO: fix this
        dispatch(setStylerLayerId(feature?.layer?.metadata?.resourceId ?? ""))
    }

    const { locationHistory, isLoading: isDprLocationHistoryLoading } = useDprLocationHistory({ feature })

    const dprMilestonesLength = locationHistory?.length

    const hasDprMilestones = !!dprMilestonesLength

    const isLoading = isDprDatasetColumnsLoading || isDprLocationHistoryLoading

    const VectorDetailsLayout = (
        <Stack direction="column" gap={1}>
            <CollapsibleCard
                headerPadding={[0, 1]}
                title="Dataset features"
                unmountOnExit
                isToggled={datasetIsToggled}
                toggleOnTheLeft
                onToggleClick={() => setDatasetIsToggled(!datasetIsToggled)}
            >
                {isLoading && (
                    <Stack alignItems={"center"} justifyContent={"center"} py={3}>
                        <CircularProgress size={25} />
                    </Stack>
                )}

                {!isLoading && (
                    <StyledTable>
                        <TableBody>
                            <PropertiesDisplay properties={properties} />
                        </TableBody>
                    </StyledTable>
                )}
            </CollapsibleCard>

            {isError && (
                <Box pl={4} py={2}>
                    <CustomTypography color="error" variant="body2">
                        Failed to fetch the DPR milestone information
                    </CustomTypography>
                </Box>
            )}

            {hasDprMilestones && (
                <CollapsibleCard
                    headerPadding={[0, 1]}
                    title={`DPR milestones (${dprMilestonesLength})`}
                    unmountOnExit
                    isToggled={featureIsToggle}
                    toggleOnTheLeft
                    onToggleClick={() => setFeatureIsToggle(!featureIsToggle)}
                >
                    <StyledTable>
                        <TableBody>
                            <DprProperties feature={feature} />
                        </TableBody>
                    </StyledTable>
                </CollapsibleCard>
            )}
        </Stack>
    )

    return (
        <div key={featureIndex}>
            <StyledStack>
                <Box boxSizing="border-box" display="flex" flexBasis={0} flexGrow={1} maxWidth="100%" overflow="hidden">
                    {hasMultipleFeatures && (
                        <Tooltip title={collapsed ? "Expand" : "Collapse"}>
                            <StyledIconButton size="small" onClick={_ => toggleFeature(featureIndex)}>
                                {collapsed ? <ExpandedExpandLess /> : <CollapsedExpandLess />}
                            </StyledIconButton>
                        </Tooltip>
                    )}
                    <TruncatedTypography fontWeight={700} variant="subtitle1">
                        {sourceToNameMapping[feature.sourceLayer || feature.source]}
                    </TruncatedTypography>
                </Box>
                <Box flex="0 0 auto">
                    {hasPropertiesToDisplay && (
                        <Tooltip arrow placement="bottom" title="Copy the layer's name and properties">
                            <SquaredIconButton size="small" onClick={() => onCopyClick(featureIndex)}>
                                <img alt="" src={copyButton} />
                            </SquaredIconButton>
                        </Tooltip>
                    )}

                    {!feature.properties?.MMSI && !isPublic && (
                        <Tooltip placement="bottom" title="Edit layer style">
                            <SquaredIconButton size="small" onClick={() => onEditLayerStyleClick()}>
                                <ColorLensIcon color="primary" />
                            </SquaredIconButton>
                        </Tooltip>
                    )}
                </Box>
            </StyledStack>

            {hasMultipleFeatures ? (
                <Collapse in={!collapsed} timeout={200} unmountOnExit>
                    {VectorDetailsLayout}
                </Collapse>
            ) : (
                VectorDetailsLayout
            )}
        </div>
    )
}

export default VectorFeature
