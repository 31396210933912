import { FC } from "react"
import { useParams, useRouteMatch } from "react-router-dom"
import config from "config"
import { BufferWWContextProvider } from "features/buffer/components/BufferWWContextProvider"
import MapView from "views/MapView"
import { AisProvider, AppProvider } from "../../providers"

const MainPublicView: FC = () => {
    const { appId } = useParams<{ appId: string }>()
    const { path } = useRouteMatch()

    return (
        <BufferWWContextProvider appId={appId} maxNumberOfWorkers={config.bufferOptions.maxNumberOfWebWorkers}>
            <AppProvider>
                <AisProvider>
                    <MapView path={path} />
                </AisProvider>
            </AppProvider>
        </BufferWWContextProvider>
    )
}

export default MainPublicView
