import { FCWC } from "react"
import { CircularProgress, LinearProgress, TypographyVariant } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import { useStyles } from "./styles"

type CommonProps = {
    loading: boolean
}

type ConditionalProps =
    | {
          linearProgress?: false
          message?: string
          spinnerSize?: number
          textVariant?: TypographyVariant
      }
    | {
          linearProgress: true
          message?: never
          spinnerSize?: never
          textVariant?: never
      }
type Props = CommonProps & ConditionalProps

const LoadingPlaceholder: FCWC<Props> = ({
    children,
    linearProgress = false,
    loading,
    message = "Loading",
    spinnerSize = 50,
    textVariant = "body1",
}) => {
    const { classes } = useStyles()
    if (linearProgress) {
        return (
            <>
                {loading && <LinearProgress />}
                {children}
            </>
        )
    }
    return loading ? (
        <div className={classes.container}>
            <CustomTypography className={classes.bottomMargin} variant={textVariant}>
                {message}
            </CustomTypography>
            <CircularProgress size={spinnerSize} />
        </div>
    ) : (
        <>{children}</>
    )
}

export default LoadingPlaceholder
