import { SyntheticEvent, useMemo } from "react"
import { Grid, Slider } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import DndReorderContainer from "components/DndReorderContainer/DndReorderContainer"
import { MAX_HOUR, MAX_INTERVAL_LENGTH, MIN_HOUR } from "features/zander/constants"
import { ZanderParameter as ParameterType } from "features/zander/models/ZanderParameter"
import { ZanderSheet as SheetType } from "features/zander/models/ZanderSheet"
import percentilesSchema from "features/zander/validators/percentilesSchema"
import weatherWindowsSchema from "features/zander/validators/weatherWindowsSchema"
import toastr from "utils/customToastr"
import LimitsCreator from "../LimitsCreator/LimitsCreator"
import ZanderParameter from "../ZanderParameter/ZanderParameter"
import useZanderSheetStyles from "./styles"

type Props = {
    sheet: SheetType
    onSheetChanged: (sheet: SheetType) => void
}

const ZanderSheet = ({ sheet, onSheetChanged }: Props) => {
    const classes = useZanderSheetStyles()

    const onWeatherWindowsChanged = (newWeatherWindows: number[]) => {
        onSheetChanged({ ...sheet, weatherWindowDurations: newWeatherWindows })
    }

    const onParameterChanged = (newParameter: ParameterType, index: number) => {
        const newParameters = sheet.parameters.map((parameter, i) => (index === i ? newParameter : parameter))

        onSheetChanged({ ...sheet, parameters: newParameters })
    }

    const onPercentilesChanged = (newPercentiles: number[]) => onSheetChanged({ ...sheet, percentiles: newPercentiles })

    const generateWeatherWindowsLimits = () => {
        if (sheet.startHour === MIN_HOUR && sheet.endHour === MAX_HOUR) {
            return sheet.weatherWindowDurations.map(duration => ({
                error: false,
                value: duration,
            }))
        }

        const intervalLength = sheet.endHour - sheet.startHour

        return sheet.weatherWindowDurations.map(duration => ({
            error: duration > intervalLength,
            value: duration,
        }))
    }

    const onHourIntervalChanged = (_: Event | SyntheticEvent<Element, Event>, newValue: number[]) => {
        const intervalLength = sheet.endHour - sheet.startHour
        const newIntervalLength = newValue[1] - newValue[0]

        if (intervalLength === MAX_INTERVAL_LENGTH && newIntervalLength !== MAX_INTERVAL_LENGTH) {
            toastr.info("Setting a time of day interval will limit the possible weather windows duration values")
        }

        onSheetChanged({
            ...sheet,
            endHour: newValue[1],
            startHour: newValue[0],
        })
    }

    const onParameterOrderChange = (index: number, newIndex: number, moveBelow: boolean) => {
        const parameter = sheet.parameters[index]
        const newParameters = sheet.parameters.filter((_, paramIndex) => index !== paramIndex)
        const insertPosition = newIndex - Number(index < newIndex) + Number(moveBelow)
        newParameters.splice(insertPosition, 0, parameter)
        onSheetChanged({ ...sheet, parameters: newParameters })
    }

    const marks = useMemo(
        () =>
            [...Array(25).keys()].map(i => ({
                label:
                    i % 8 === 0 ? (
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                            }}
                        >
                            <div style={{ border: "1px solid", height: 10, width: 0 }} />
                            {i}
                        </div>
                    ) : (
                        <div style={{ border: "1px solid", height: 10, opacity: "0.3", width: 0 }}></div>
                    ),
                value: i,
            })),
        [],
    )

    return (
        <div className={classes.sheet}>
            <Grid container direction="column">
                <CustomTypography fontWeight="bold" gutterBottom variant="h6">
                    Settings
                </CustomTypography>
                <div className={classes.sectionCard}>
                    <Grid className={classes.spacingBottom} item>
                        <CustomTypography fontWeight="bold" variant="body2">
                            Minimum weather window duration [h]
                        </CustomTypography>
                    </Grid>
                    <Grid item>
                        <LimitsCreator
                            limits={generateWeatherWindowsLimits()}
                            text="Save"
                            validationSchema={weatherWindowsSchema}
                            onLimitsChanged={onWeatherWindowsChanged}
                        />
                    </Grid>
                </div>
                <div className={classes.sectionCard}>
                    <Grid className={classes.spacingBottom} item>
                        <CustomTypography fontWeight="bold" variant="body2">
                            Time of day limitation (UTC)
                        </CustomTypography>
                    </Grid>
                    <Grid className={`${classes.spacingTop} ${classes.sliderContainer}`} item>
                        <div className={classes.slider}>
                            <Slider
                                marks={marks}
                                max={24}
                                min={0}
                                size="medium"
                                step={1}
                                value={[sheet.startHour, sheet.endHour]}
                                valueLabelDisplay="auto"
                                onChangeCommitted={(_, value) =>
                                    onHourIntervalChanged(_, Array.isArray(value) ? value : [value])
                                }
                            />
                        </div>
                    </Grid>
                </div>
                <div className={classes.sectionCard}>
                    <Grid className={classes.spacingBottom} item>
                        <CustomTypography fontWeight="bold" variant="body2">
                            Persistence exceedance percentiles [%]
                        </CustomTypography>
                    </Grid>
                    <Grid className={classes.percentiles} item>
                        <LimitsCreator
                            limits={sheet.percentiles.map(percentile => ({
                                error: false,
                                value: percentile,
                            }))}
                            text="Save"
                            validationSchema={percentilesSchema}
                            onLimitsChanged={onPercentilesChanged}
                        />
                    </Grid>
                </div>
                <CustomTypography fontWeight="bold" variant="h6">
                    Criteria
                </CustomTypography>
                <div>
                    {sheet.parameters.map((parameter, index) => (
                        <DndReorderContainer
                            key={index}
                            index={index}
                            type="zanderParameter"
                            onOrderChange={onParameterOrderChange}
                        >
                            <ZanderParameter
                                parameter={parameter}
                                onParameterChanged={newParameter => onParameterChanged(newParameter, index)}
                            />
                        </DndReorderContainer>
                    ))}
                </div>
            </Grid>
        </div>
    )
}

export default ZanderSheet
