import { Button, css, styled } from "@mui/material"

type StyledButtonProps = {
    isSelected?: boolean
}

export const StyledButton = styled(Button, { shouldForwardProp: prop => prop !== "isSelected" })<StyledButtonProps>(
    ({ theme, disabled = false, isSelected = false }) => css`
        display: flex;
        justify-content: flex-start;
        border-radius: 20px;
        border: 1px solid ${disabled ? theme.customColors.lightGray : theme.customColors.primaryColor};
        background-color: ${isSelected ? theme.customColors.primaryColor : "transparent"};
        color: ${isSelected ? theme.customColors.white : theme.customColors.primaryColor};
        padding-block: ${theme.spacing(0.5)};

        & svg {
            margin-right: ${theme.spacing(0.5)};
        }

        &:hover {
            background-color: ${theme.customColors.primaryColor};
            color: ${theme.customColors.white};
        }
    `,
)
