import { MapboxOverlay } from "@deck.gl/mapbox"
import { DeckProps } from "deck.gl"
import { MjolnirGestureEvent } from "mjolnir.js"
import { useControl } from "@emblautec/react-map-gl"
import { BoatPositionWithStartTime } from "features/ais/models/BoatPositionWithStartTime"
import { setPickedArchiveData } from "reducers/ais"
import { getBoatDetails, getIsPlaybackPlaying } from "selectors/aisSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import useAisArchiveLayers from "./hooks/useAisArchiveLayers"

const DeckGLOverlay = () => {
    const dispatch = useAppDispatch()
    const isPlaybackPlaying = useAppSelector(getIsPlaybackPlaying)
    const boatDetails = useAppSelector(getBoatDetails)

    const layers = useAisArchiveLayers()
    const props: DeckProps = { layers }

    const onDeckGLClick = (event: MjolnirGestureEvent) => {
        const { x, y } = event.offsetCenter
        const pickedObjects = overlay.pickMultipleObjects({ x, y })
        !isPlaybackPlaying &&
            dispatch(
                setPickedArchiveData(
                    pickedObjects.map(obj => ({
                        ...boatDetails[(obj.object as BoatPositionWithStartTime).boatDetailsId],
                        ...(obj.object as BoatPositionWithStartTime),
                    })),
                ),
            )
    }

    // @ts-ignore TODO: fix this
    const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props))
    overlay.setProps({
        ...props,
        onClick: (_, event) => onDeckGLClick(event),
    })

    return null
}

export default DeckGLOverlay
