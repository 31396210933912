import { ReactNode } from "react"
import { Box, Grid } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import { LegendTypographyProps } from "features/common/models/legend"

type Props = {
    classes: {
        dividerClass: string
        entryContainerClass: string
        typographyClass: string
    }
    icon: ReactNode
    title: string
    typographyProps?: LegendTypographyProps
    xsSpacing: number
}

const BasicLegendEntry = ({ classes, icon, title, typographyProps, xsSpacing }: Props) => {
    return (
        <Grid item xs={xsSpacing}>
            <Box className={classes.entryContainerClass}>
                <Box alignItems="center" display="flex" pr={1}>
                    {icon}
                </Box>
                <CustomTypography
                    className={classes.typographyClass}
                    overflow="hidden"
                    textOverflow="ellipsis"
                    textWeight="semibold"
                    variant="subtitle1"
                    {...typographyProps}
                >
                    {title}
                </CustomTypography>
            </Box>
        </Grid>
    )
}

export default BasicLegendEntry
