import React, { Component } from "react"
import Tooltip from "@mui/material/Tooltip"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import * as datasetActions from "actions/atlasClient"
import { GeometryIcons } from "utils/customIcons"
import PropertyButton from "./propertyButton"
import StyleProperty from "./styleProperty"

const styles = () => ({
    root: {},
    selectValue: {
        alignItems: "center",
        display: "flex",
        fontSize: 16,
    },
})

let transformDict = {
    boolean: x => x,
    color: x => x,
    float: x => parseFloat(x),
    "multi-color": x => x,
    number: x => parseInt(x),
    numberArray: x => x.map(y => parseFloat(y)),
    select: x => x,
    text: x => x,
}

let layerTypes = {
    circle: {
        icon: GeometryIcons.point,
        name: "circle",
        namePretty: " Draw as Points",
    },
    fill: {
        icon: GeometryIcons.polygon,
        name: "fill",
        namePretty: "Draw as Polygons",
    },
    fillExtrusion: {
        icon: GeometryIcons.extrusion,
        name: "fill-extrusion",
        namePretty: " Draw as 3D",
    },
    line: {
        icon: GeometryIcons.line,
        name: "line",
        namePretty: "Draw as Lines",
    },
    symbol: {
        icon: GeometryIcons.symbol,
        name: "symbol",
        namePretty: "Draw as Labels",
    },
}

class LayerStyle extends Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: [{ name: "a", type: "" }],
            selectedProperty: props.layer.properties[0],
            tab: "style",
        }
    }

    onPropertySelected = property => {
        this.setState({
            selectedProperty: property,
        })
    }

    componentDidMount() {
        if (this.props.isDigitizeLayer) return

        this.props.getDatasetColumns(this.props.layer.datasetId).then(res => {
            this.setState({
                columns: res.result,
            })
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.layer.layerId !== prevProps.layer.layerId && !this.props.isDigitizeLayer) {
            this.props
                .getDatasetColumns(this.props.layer.datasetId)
                .then(res => {
                    this.setState({
                        columns: res.result,
                        selectedProperty: this.props.layer.properties[0],
                    })
                })
                .catch(err => this.setState({ selectedProperty: this.props.layer.properties[0] }))
        }
        if (this.props.layer.type !== prevProps.layer.type) {
            this.setState({
                selectedProperty: this.props.layer.properties[0],
            })
        }
    }

    onChangeType = type => {
        this.setState({
            selectedType: type,
        })

        this.props.onTypeChanged(type)
    }

    onChangePropertyType = (prop, type, value) => {
        let newProperties = this.props.layer.properties.map(item => {
            if (item.name === prop) {
                return {
                    ...item,
                    propertyType: type,
                    value: value,
                }
            }
            return item
        })

        this.props.onPropertiesChanged(newProperties)
    }

    onChangePropertyExpressionType = (prop, type, value) => {
        let newProperties = this.props.layer.properties.map(item => {
            if (item.name === prop) {
                return {
                    ...item,
                    expressionType: type,
                    value: value,
                }
            }
            return item
        })

        this.props.onPropertiesChanged(newProperties)
        this.updateMap(newProperties)
        this.setState({
            selectedProperty: {
                ...this.state.selectedProperty,
                expressionType: type,
                value: value,
            },
        })
    }

    onPaintPropertyChanged = (property, value) => {
        let newProperties = this.props.layer.properties.map(item => {
            if (item.name === property.name) {
                return {
                    ...item,
                    value: property.expressionType !== "none" ? value : transformDict[property.propertyType](value),
                }
            }
            return item
        })
        this.setState({
            properties: newProperties,
            selectedProperty: {
                ...this.state.selectedProperty,
                value: value,
            },
        })

        this.updateMap(newProperties)
    }

    updateMap(properties) {
        this.props.onPropertiesChanged(properties)
    }

    onChangeTab = tab => {
        this.setState({
            tab: tab,
        })
    }

    onChangeLayerName = e => {
        this.props.onNameChanged(e.target.value)
    }

    getRenderTypes = geometryType => {
        let fill = layerTypes["fill"]
        let line = layerTypes["line"]
        let circle = layerTypes["circle"]
        let fillExtrusion = layerTypes["fillExtrusion"]
        let symbol = layerTypes["symbol"]

        switch (geometryType) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return [fill, line, circle, fillExtrusion, symbol]
            case "POINT":
            case "MULTIPOINT":
                return [circle, symbol]
            case "LINESTRING":
            case "MULTILINESTRING":
                return [line, circle, symbol]
            default:
                return []
        }
    }

    renderProperty(item, index) {
        return (
            <PropertyButton
                key={index}
                expressionType={item.expressionType}
                selected={this.state.selectedProperty.name === item.name}
                title={item.title}
                type={item.propertyType}
                value={item.value.toString()}
                onClick={() => this.onPropertySelected(item)}
            />
        )
    }

    render() {
        let properties = this.props.layer.properties.map((item, index) => this.renderProperty(item, index))

        let supportedRenderTypes = this.getRenderTypes(this.props.layer.geometryType)

        let layertypeEls = supportedRenderTypes.map((type, index) => {
            return (
                <Tooltip key={index} title={type.namePretty}>
                    <div
                        className={this.props.layer.type === type.name ? "type active" : "type"}
                        onClick={() => this.onChangeType(type.name)}
                    >
                        {type.icon}
                    </div>
                </Tooltip>
            )
        })

        return (
            <div className="style">
                <div className="style-content">
                    <div className="data-tab">
                        <div className="section-container">
                            <div className="types">{layertypeEls}</div>
                        </div>
                    </div>
                    <div className="style-tab">
                        <div className="style-properties">{properties}</div>

                        {this.state.selectedProperty.name !== "" && (
                            <div className="property-container">
                                <StyleProperty
                                    colorArrays={this.props.colorArrays}
                                    columns={this.state.columns}
                                    datasetId={this.props.layer.datasetId}
                                    layerType={this.props.layer.type}
                                    property={this.state.selectedProperty}
                                    isDigitizeLayer={this.props.isDigitizeLayer}
                                    onPropertyChanged={(property, value) =>
                                        this.onPaintPropertyChanged(property, value)
                                    }
                                    onPropertyExpressionTypeChanged={(type, value) =>
                                        this.onChangePropertyExpressionType(
                                            this.state.selectedProperty.name,
                                            type,
                                            value,
                                        )
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mapState: state.map,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getDatasetColumns: id => dispatch(datasetActions.getDatasetColumns(id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LayerStyle))
