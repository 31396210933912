import { useEffect } from "react"
import useSignalR from "utils/customHooks/useSignalR"
import { ZANDER_EVENT, ZANDER_HUB } from "../constants"
import { ZanderResponse } from "../models/ZanderReponse"

const useZander = (handler: (response: ZanderResponse) => void) => {
    const { addEvent, connectionId, removeEvent, isConnectionStarted } = useSignalR(ZANDER_HUB)

    useEffect(() => {
        if (isConnectionStarted) {
            addEvent(ZANDER_EVENT, handler)
        }

        return () => removeEvent(ZANDER_EVENT)
    }, [isConnectionStarted])

    return connectionId
}

export default useZander
