import React, { Component } from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline"
import RasterIcon from "@mui/icons-material/Wallpaper"
import { Button, Slider } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { toastr } from "react-redux-toastr"
import { getColor } from "@windgis/shared"
import { LAYER_TYPES } from "../constants/layers/layerTypes"
import { withStylerContext } from "../HOCs/withStylerContext"
import { GeometryIcons } from "../utils/customIcons"
import BoxChartCanvas from "./BoxChartCanvas/BoxChartWithLabelsCanvas"
import PieChartCanvas from "./PieChartCanvas/PieChartWithLabelsCanvas"
import StyleProperty from "./styleProperty"
import PropertyButton from "./StylePropertyButton/StylePropertyButton"

const transformDict = {
    boolean: x => x,
    color: x => x,
    "color-array": x => x,
    column: x => x,
    float: x => parseFloat(x),
    icon: x => x,
    "multi-color": x => x,
    number: x => parseInt(x),
    numberArray: x => x.map(y => parseFloat(y)),
    select: x => x,
    "string-array": x => x,
    text: x => x,
}

const layerTypes = {
    boxChart: {
        icon: <DashboardIcon />,
        name: "box-chart",
        namePretty: "Draw as Box Chart",
    },
    circle: {
        icon: GeometryIcons.point,
        name: "circle",
        namePretty: "Draw as Points",
    },
    fill: {
        icon: GeometryIcons.polygon,
        name: "fill",
        namePretty: "Draw as Polygons",
    },
    fillExtrusion: {
        icon: GeometryIcons.extrusion,
        name: "fill-extrusion",
        namePretty: "Draw as 3D",
    },
    line: {
        icon: GeometryIcons.line,
        name: "line",
        namePretty: "Draw as Lines",
    },
    pieChart: {
        icon: <PieChartOutlineIcon />,
        name: "pie-chart",
        namePretty: "Draw as Pie Chart",
    },
    raster: {
        icon: <RasterIcon />,
        name: "raster",
        namePretty: "Draw as Raster",
    },
    symbol: {
        icon: GeometryIcons.symbol,
        name: "symbol",
        namePretty: "Draw as Labels",
    },
}

class Style extends Component {
    constructor(props) {
        super(props)
        ;(this.propertiesWithoutDprMask = this.computePropertiesWithoutDprMask()),
            (this.state = {
                selectedProperty: this.mergeProperty(this.propertiesWithoutDprMask[0]),
                selectedType: this.props.style.type,
            })
        this.onPaintPropertyChanged = this.onPaintPropertyChanged.bind(this)
        this.onAddColorRow = this.onAddColorRow.bind(this)
        this.onRemoveColorRow = this.onRemoveColorRow.bind(this)
    }

    computePropertiesWithoutDprMask() {
        return this.props.stylerContext.isDprLayer
            ? this.props.style.properties.filter(p => p.name !== "pie-chart-mask" && p.name !== "box-chart-mask")
            : this.props.style.properties
    }

    onPropertySelected(property) {
        this.setState({
            selectedProperty: this.mergeProperty(property),
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.style !== prevProps.style || this.props.style.type !== prevProps.style.type) {
            this.propertiesWithoutDprMask = this.computePropertiesWithoutDprMask()
            this.setState({
                selectedProperty: this.mergeProperty(
                    this.propertiesWithoutDprMask.find(p => p.name === this.state.selectedProperty.name) ??
                        this.propertiesWithoutDprMask[0],
                ),
                selectedType: this.props.style.type,
            })
        }
    }

    mergeProperty(dbProperty) {
        let property = this.props.stylerContext.styleConfig[this.props.style.type].find(x => x.name === dbProperty.name)

        return {
            ...property,
            expressionType: dbProperty.expressionType,
            value: dbProperty.value,
        }
    }

    onChangeType(type) {
        if (type === this.state.selectedType) return

        this.setState({
            selectedProperty: this.props.style.properties[0],
            selectedType: type,
        })

        this.props.onTypeChanged(type)
    }

    onChangePropertyType(prop, type, value) {
        let newProperties = this.props.style.properties.map(item => {
            if (item.name === prop) {
                return {
                    ...item,
                    propertyType: type,
                    value: value,
                }
            }
            return item
        })

        this.props.onPropertiesChanged(newProperties)
    }

    onChangePropertyExpressionType(prop, type, value) {
        const newProperties = this.props.style.properties.map(item => {
            if (item.name === prop) {
                return {
                    ...item,
                    expressionType: type,
                    value: value,
                }
            }
            return item
        })

        this.updateMap(newProperties)
        this.setState({
            selectedProperty: {
                ...this.state.selectedProperty,
                expressionType: type,
                value: value,
            },
        })
    }

    onPaintPropertyChanged(property, value) {
        const newProperties = this.props.style.properties.map(item => {
            if (item.name === property.name) {
                return {
                    ...item,
                    value: property.expressionType !== "none" ? value : transformDict[property.propertyType](value),
                }
            } else if (
                //Ugly hack to make colors and labels be updatable from the same style property
                (item.name === "pie-chart-labels" && property.name === "label") ||
                (item.name === "box-chart-labels" && property.name === "label")
            ) {
                return {
                    ...item,
                    value: value,
                }
            }

            return item
        })

        this.setState({
            properties: newProperties,
            selectedProperty: {
                ...this.state.selectedProperty,
                value: value,
            },
        })

        this.updateMap(newProperties)
    }

    onAddColorRow() {
        const colorsProperty = this.props.style.properties.find(
            item => item.name === "pie-chart-colors" || item.name === "box-chart-colors",
        )
        const labelsProperty = this.props.style.properties.find(
            item => item.name === "pie-chart-labels" || item.name === "box-chart-labels",
        )

        const newColors = [
            ...colorsProperty.value,
            getColor(this.props.stylerContext.colorsArray, colorsProperty?.value.length ?? 0),
        ]
        const newLabels = [...labelsProperty.value, ""]

        const newProperties = this.props.style.properties.map(item => {
            if (item.name === colorsProperty.name) {
                return {
                    ...item,
                    value: newColors,
                }
            } else if (item.name === labelsProperty.name) {
                return {
                    ...item,
                    value: newLabels,
                }
            }
            return item
        })

        this.setState({
            properties: newProperties,
            selectedProperty: {
                ...this.state.selectedProperty,
                value: newLabels,
            },
        })

        this.updateMap(newProperties)
    }

    onRemoveColorRow(index) {
        const colorsProperty = this.props.style.properties.find(
            item => item.name === "pie-chart-colors" || item.name === "box-chart-colors",
        )
        const labelsProperty = this.props.style.properties.find(
            item => item.name === "pie-chart-labels" || item.name === "box-chart-labels",
        )

        const newColors = colorsProperty.value.filter((_, i) => i !== index)
        const newLabels = labelsProperty.value.filter((_, i) => i !== index)

        const newProperties = this.props.style.properties.map(item => {
            if (item.name === colorsProperty.name) {
                return {
                    ...item,
                    value: newColors,
                }
            } else if (item.name === labelsProperty.name) {
                return {
                    ...item,
                    value: newLabels,
                }
            }
            return item
        })

        this.setState({
            properties: newProperties,
            selectedProperty: {
                ...this.state.selectedProperty,
                value: newLabels,
            },
        })

        this.updateMap(newProperties)
    }

    onCopyStyle() {
        navigator.clipboard.writeText(JSON.stringify(this.props.style))
        toastr.success("Success", `Style copied`)
    }

    onZoomChange(_, newValue, activeThumb) {
        if (!Array.isArray(newValue)) {
            return
        }

        let newZoomRange = [this.props.style.minZoom, this.props.style.maxZoom]

        if (activeThumb === 0) {
            newZoomRange = [Math.min(newValue[0], newZoomRange[1] - 1), newZoomRange[1]]
        } else {
            newZoomRange = [newZoomRange[0], Math.max(newValue[1], newZoomRange[0] + 1)]
        }

        this.props.onZoomSliderChange(newZoomRange)
    }

    handlePasteDprStyle(currentStyleProperties, newStyle) {
        var newProperties = []

        newStyle.properties = newStyle.properties.filter(
            prop =>
                prop.name !== "pie-chart-labels" &&
                prop.name !== "pie-chart-mask" &&
                prop.name !== "box-chart-labels" &&
                prop.name !== "box-chart-mask",
        )

        const pastedColors = newStyle.properties.find(
            prop => prop.name === "pie-chart-colors" || prop.name === "box-chart-colors",
        )
        const currentColors = currentStyleProperties.find(
            prop => prop.name === "pie-chart-colors" || prop.name === "box-chart-colors",
        )

        let mergedColors = []
        if (pastedColors && currentColors) {
            mergedColors =
                pastedColors.value.length < currentColors.value.length
                    ? pastedColors.value.concat(
                          currentColors.value.slice(pastedColors.value.length, currentColors.value.length),
                      )
                    : pastedColors.value.slice(0, currentColors.value.length)
        }

        const newPropertiesWithoutColors = newStyle.properties.filter(
            prop => prop.name !== "pie-chart-colors" && prop.name !== "box-chart-colors",
        )

        if (newStyle.type === "pie-chart") {
            newProperties.push(currentStyleProperties.find(prop => prop.name === "pie-chart-mask"))
            newProperties.push({ ...currentColors, value: mergedColors })
            newProperties.push(currentStyleProperties.find(prop => prop.name === "pie-chart-labels"))
        } else if (newStyle.type === "box-chart") {
            newProperties.push(currentStyleProperties.find(prop => prop.name === "box-chart-mask"))
            newProperties.push({ ...currentColors, value: mergedColors })
            newProperties.push(currentStyleProperties.find(prop => prop.name === "box-chart-labels"))
            newPropertiesWithoutColors.forEach(prop => {
                if (prop.name === "box-chart-columns")
                    prop.value = currentStyleProperties.find(prop => prop.name === "box-chart-columns").value
                if (prop.name === "box-chart-rows")
                    prop.value = currentStyleProperties.find(prop => prop.name === "box-chart-rows").value
            })
        }

        newProperties = newProperties.concat(newPropertiesWithoutColors)

        return newProperties
    }

    onPasteStyle() {
        let currentStyle = this.props.style
        navigator.clipboard.readText().then(text => {
            let style = JSON.parse(text)

            let supportedStyles = this.getSupportedLayerStyles().map(x => x.name)

            if (!supportedStyles.includes(style.type)) {
                toastr.error("Error", `You cannot style a ${this.props.style.type} style as a ${style.type}`)
                return
            }

            let currentStyleWithTheNewType =
                style.type !== currentStyle.type ? this.props.onTypeChanged(style.type) : currentStyle
            let currentStylePropertiesWithTheNewType = currentStyleWithTheNewType.properties
                ? currentStyleWithTheNewType.properties
                : currentStyleWithTheNewType

            let newProperties = []
            if (this.props.stylerContext.isDprLayer && (style.type === "pie-chart" || style.type === "box-chart")) {
                newProperties = this.handlePasteDprStyle(currentStylePropertiesWithTheNewType, style)
            }

            if (newProperties.length === 0) newProperties = style.properties

            this.setState({
                properties: newProperties,
                selectedProperty: newProperties[0],
            })

            this.props.onZoomSliderChange([style.minZoom, style.maxZoom])
            this.updateMap(newProperties)
        })
    }

    updateMap(properties) {
        this.props.onPropertiesChanged(properties)
    }

    onChangeLayerName(e) {
        this.props.onNameChanged(e.target.value)
    }

    getSupportedLayerStyles() {
        return this.props.style.type === LAYER_TYPES.symbol
            ? [layerTypes["symbol"]]
            : this.getRenderTypes(this.props.stylerContext.layer.geometryType)
    }

    getRenderTypes(geometryType) {
        const fill = layerTypes["fill"]
        const line = layerTypes["line"]
        const circle = layerTypes["circle"]
        const pieChart = layerTypes["pieChart"]
        const boxChart = layerTypes["boxChart"]
        const fillExtrusion = layerTypes["fillExtrusion"]
        const raster = layerTypes["raster"]

        switch (geometryType) {
            case "POLYGON":
            case "MULTIPOLYGON":
                return [fill, line, circle, fillExtrusion]
            case "POINT":
            case "MULTIPOINT":
                return [circle, boxChart, pieChart]
            case "LINESTRING":
            case "MULTILINESTRING":
                return [line, circle]
            case "raster":
                return [raster]
            default:
                return []
        }
    }

    renderProperty(property, index) {
        const value = transformDict[property.propertyType](property.value).toString()
        //Hide property button for chart labels, since they are controlled by the color-array property
        if (property.name === "pie-chart-labels" || property.name === "box-chart-labels") {
            return null
        }

        return (
            <PropertyButton
                key={index}
                property={property}
                selected={this.state.selectedProperty.name === property.name}
                value={value}
                onClick={() => this.onPropertySelected(property)}
            />
        )
    }

    render() {
        if (!this.state.selectedProperty) this.setState({ selectedProperty: this.propertiesWithoutDprMask[0] })
        const properties = this.propertiesWithoutDprMask.map((item, index) => this.renderProperty(item, index))
        const supportedRenderTypes = this.getSupportedLayerStyles()

        const layerTypeEls = supportedRenderTypes.map((type, index) => {
            return (
                <Tooltip key={index} title={type.namePretty}>
                    <div
                        className={this.props.style.type === type.name ? "type active" : "type"}
                        onClick={() => this.onChangeType(type.name)}
                    >
                        {type.icon}
                    </div>
                </Tooltip>
            )
        })

        const StyleProps = this.props.style.properties.reduce((a, b) => {
            a[b.name] = b.value
            return a
        }, {})

        return (
            <div className="style">
                <div className="style-content">
                    <div className="data-tab">
                        <div className="section-container">
                            <div className="types">{layerTypeEls}</div>
                        </div>
                        <div className="section-container">
                            <div className="slider-container">
                                <Slider
                                    marks
                                    max={24}
                                    min={this.props.stylerContext.minZoomLimit || 0}
                                    value={[this.props.style.minZoom, this.props.style.maxZoom]}
                                    valueLabelDisplay="auto"
                                    disableSwap
                                    onChange={this.onZoomChange.bind(this)}
                                />
                                <div className="slider-footer">
                                    <span>{this.props.stylerContext.minZoomLimit || 0}</span>
                                    <span>{24}</span>
                                </div>
                            </div>
                        </div>

                        <div className="section-container">
                            <Button className="copy-button" variant="outlined" onClick={this.onCopyStyle.bind(this)}>
                                Copy
                            </Button>
                            <Button className="paste-button" variant="outlined" onClick={this.onPasteStyle.bind(this)}>
                                Paste
                            </Button>
                        </div>
                    </div>
                    <div className="style-tab">
                        <div className="style-properties">{properties}</div>

                        {this.state.selectedProperty.name !== "" && (
                            <div className="property-container">
                                <StyleProperty
                                    layerType={this.props.style.type}
                                    properties={this.propertiesWithoutDprMask}
                                    property={this.state.selectedProperty}
                                    styleId={this.props.style.styleId}
                                    onAddColorRow={this.onAddColorRow}
                                    onPropertyChanged={this.onPaintPropertyChanged}
                                    onPropertyExpressionTypeChanged={(type, value) =>
                                        this.onChangePropertyExpressionType(
                                            this.state.selectedProperty.name,
                                            type,
                                            value,
                                        )
                                    }
                                    onRemoveColorRow={this.onRemoveColorRow}
                                />
                            </div>
                        )}
                    </div>
                    {this.props.style.type == "pie-chart" && (
                        <PieChartCanvas
                            centerFillPercentage={StyleProps["pie-chart-center-size"] / StyleProps["pie-chart-size"]}
                            colors={StyleProps["pie-chart-colors"]}
                            height={250}
                            labels={StyleProps["pie-chart-labels"]}
                            style={{
                                fontSize: 12,
                                strokeColor: StyleProps["pie-chart-stroke-color"],
                                strokeWidth: StyleProps["pie-chart-stroke-width"],
                            }}
                            width={398}
                        />
                    )}
                    {this.props.style.type == "box-chart" && (
                        <BoxChartCanvas
                            colors={StyleProps["box-chart-colors"]}
                            columns={StyleProps["box-chart-columns"]}
                            height={160}
                            labels={StyleProps["box-chart-labels"]}
                            rows={StyleProps["box-chart-rows"]}
                            style={{
                                fontSize: 12,
                                strokeColor: StyleProps["box-chart-stroke-color"],
                                strokeWidth: StyleProps["box-chart-stroke-width"],
                            }}
                            width={398}
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default withStylerContext(Style)
