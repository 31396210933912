import { FC } from "react"
import ReduxToastr from "react-redux-toastr"
import { Redirect, Route, Switch } from "react-router-dom"
import "@emblautec/mapbox-gl/dist/mapbox-gl.css"
import "@emblautec/recursive-array-extensions"
import ErrorView from "views/error/ErrorView"
import LandingView from "views/landing/LandingView"
import MainPublicView from "views/main/MainPublicView"
import MainView from "views/main/MainView"
import SignOutView from "views/SignOutView/SignOutView"
import "./App.css"
import Bootstrap from "./Bootstrap"
import PublicBootstrap from "./PublicBootstrap"
import "./scss/main.scss"

const App: FC = () => (
    <div className="App">
        <Switch>
            <Route exact path="/:clientId/:projectId/error">
                <ErrorView />
            </Route>
            <Route path="/:clientId/:projectId/public/:appId/map">
                <PublicBootstrap>
                    <MainPublicView />
                </PublicBootstrap>
            </Route>
            <Route path="/:clientId/:projectId">
                <Bootstrap>
                    <MainView />
                </Bootstrap>
            </Route>
            <Route exact path="/sign-out">
                <SignOutView />
            </Route>
            <Route exact path="/">
                <LandingView />
            </Route>
            <Redirect to="/" />
        </Switch>
        <ReduxToastr
            closeOnToastrClick
            newestOnTop={true}
            position="top-right"
            progressBar
            timeOut={4000}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
        />
    </div>
)

export default App
