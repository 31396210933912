import makeStyles from "@mui/styles/makeStyles"

export const useLayerFilterEditorStyles = makeStyles({
    filterContainer: {
        display: "filter",
        flexDirection: "column",
        overflow: "auto",
        padding: "20px",
        width: "100%",
    },
    filters: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "80px",
    },
    filtersContainer: {
        display: "flex",
        minWidth: "fit-content",
        overflowY: "auto",
    },
    filtersList: {
        display: "flex",
        overflow: "auto",
        width: "100%",
    },
})
