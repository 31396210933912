import { useState } from "react"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import { AppBar, Button, Dialog, Divider, Grid, Typography } from "@mui/material"
import SwipeableViews from "react-swipeable-views"
import { MAX_SHEETS } from "features/zander/constants"
import { ZanderSheet as ZanderSheetModel } from "features/zander/models/ZanderSheet"
import { emptySheet, initialSheet, sanitizeSheets, validateSheets } from "features/zander/utils"
import ZanderSheet from "./components/ZanderSheet/ZanderSheet"
import ZanderTabs from "./components/ZanderTabs/ZanderTabs"
import { useZanderModalStyles } from "./styles"

type Props = {
    open: boolean
    generateButtonDisabled: boolean
    onClose: () => void
    onZanderRequest: (sheets: ZanderSheetModel[]) => void
}

const ZanderModal = ({ open, generateButtonDisabled, onClose, onZanderRequest }: Props) => {
    const classes = useZanderModalStyles()
    const [activeSheet, setActiveSheet] = useState(0)
    const [sheets, setSheets] = useState([{ ...initialSheet }])
    const [honeypot, setHoneypot] = useState("")

    const onSheetAdd = () => {
        setActiveSheet(sheets.length)
        setSheets([...sheets, { ...emptySheet }])
    }

    const onClearSheet = () => {
        sheets.splice(activeSheet, 1, { ...emptySheet })
        setSheets([...sheets])
    }

    const onDialogClose = () => {
        onClose()
        setActiveSheet(0)
        setSheets([{ ...initialSheet }])
    }

    const onGenerateExcel = () => {
        if (honeypot !== "") return

        onZanderRequest(sanitizeSheets(sheets))
    }

    const onTabClose = (index: number) => {
        const length = sheets.length
        const currentSheets = sheets

        currentSheets.splice(index, 1)
        setSheets([...currentSheets])

        if (activeSheet === length - 1) {
            setActiveSheet(index - 1)
        }
    }

    return (
        <Dialog className={classes.zanderDialog} open={open} onClose={onDialogClose}>
            <Grid alignItems="center" container justifyContent="space-between" px={2} py={1} wrap="nowrap">
                <Grid item>
                    <Typography variant="h5">ESOX Zander</Typography>
                </Grid>
                <Grid display="flex" gap={1} item>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={sheets.length >= MAX_SHEETS}
                        startIcon={<AddCircleIcon />}
                        onClick={onSheetAdd}
                    >
                        Add table
                    </Button>
                    <Button color="primary" variant="text" onClick={onClearSheet}>
                        Clear table
                    </Button>
                </Grid>
            </Grid>
            <Divider />
            <AppBar className={classes.tabsWrapper} color="inherit" position="static">
                <ZanderTabs
                    activeSheet={activeSheet}
                    sheets={sheets}
                    onChange={(index: number) => setActiveSheet(index)}
                    onClose={onTabClose}
                />
            </AppBar>
            <SwipeableViews index={activeSheet}>
                {sheets.map((sheet, index) => (
                    <div key={index} hidden={index !== activeSheet}>
                        <ZanderSheet
                            key={index}
                            sheet={sheet}
                            onSheetChanged={newSheet =>
                                setSheets(sheets.map((sheet, i) => (index === i ? newSheet : sheet)))
                            }
                        />
                    </div>
                ))}
            </SwipeableViews>
            <input
                autoComplete="off"
                className={classes.honeypot}
                type="text"
                onChange={e => setHoneypot(e.target.value)}
            />
            <Divider />
            <div className={classes.zanderDialogActions}>
                <Button
                    className={classes.spacing}
                    color="primary"
                    size="medium"
                    variant="contained"
                    disabled={generateButtonDisabled || !validateSheets(sheets)}
                    onClick={onGenerateExcel}
                >
                    Generate excel file
                </Button>
                <Button color="primary" size="medium" variant="text" onClick={onDialogClose}>
                    Cancel
                </Button>
            </div>
        </Dialog>
    )
}

export default ZanderModal
