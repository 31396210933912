import React, { Component } from "react"
import AddIcon from "@mui/icons-material/Add"
import { Divider } from "@mui/material"
import withStyles from "@mui/styles/withStyles"
import { connect } from "react-redux"
import Style from "./layerStyle"
import LayerType from "./layerType"

const styles = () => ({
    root: {},
    selectValue: {
        alignItems: "center",
        display: "flex",
        fontSize: 16,
    },
})

class LayerStyleEditor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: [],
            selectedStyle: this.props.styles.length ? 0 : null,
        }
    }

    componentDidMount() {}

    componentDidUpdate(prevProps, props) {
        if (this.props.layer && this.props.layer.datasetId !== prevProps.layer.datasetId) {
        }
        if (this.props.layer && this.props.styles.length !== prevProps.styles.length) {
            this.setState({
                selectedStyle: this.props.styles.length ? 0 : null,
            })
        }
    }

    onSelectStyle = index => {
        this.setState({
            selectedStyle: index,
        })
    }

    onAddStyle = () => {}

    render() {
        let selectedStyleIndex = this.state.selectedStyle

        let styles = this.props.styles.map((style, index) => {
            return (
                <div key={index} onClick={() => this.onSelectStyle(index)}>
                    <LayerType
                        expressionType={style.expressionType}
                        layerProperties={style.properties}
                        selected={index === selectedStyleIndex}
                        type={style.type}
                        onRemove={() => this.props.onRemoveStyleFromLayer(this.props.layer, style)}
                    ></LayerType>
                </div>
            )
        })

        let selectedStyle = this.props.styles[selectedStyleIndex]
        let style = selectedStyle ? (
            <Style
                colorsArray={this.props.colorsArray}
                layer={selectedStyle}
                minZoom={this.props.layer.minZoom}
                isDigitizeLayer={this.props.isDigitizeLayer}
                onPropertiesChanged={properties => this.props.onPropertiesChanged(properties, selectedStyle)}
                onPropertyExpressionTypeChanged={type =>
                    this.props.onPropertyExpressionTypeChanged(type, selectedStyle, selectedStyleIndex)
                }
                onTypeChanged={type => this.props.onTypeChanged(type, selectedStyle, selectedStyleIndex)}
                onZoomSliderChange={zoom => this.props.onZoomSliderChange(selectedStyle, zoom)}
            />
        ) : null

        return (
            <div className="style-list">
                <div className="styles-container">
                    <div className="styles">
                        {styles}
                        <div className="add-button" onClick={() => this.props.onAddStyleToLayer(this.props.layer)}>
                            <AddIcon />
                        </div>
                    </div>
                    <Divider orientation="vertical"></Divider>
                </div>

                <div className="style-container">{style}</div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        mapState: state.map,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LayerStyleEditor))
