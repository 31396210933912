export type ColorPalette = {
    colorTable: ColorTableEntry[]
    type: ColorPaletteType
}

export type ColorTableEntry = {
    alpha: number
    color: string
    id: string
    label: string
    value: number
}

export enum ColorPaletteType {
    DISCRETE = "DISCRETE",
    INTERPOLATED = "INTERPOLATED",
    NONE = "NONE",
}
