import React, { useEffect, useMemo, useState } from "react"
import { Divider } from "@mui/material"
import { StylerContextProvider } from "../../contexts/StylerContext"
import Style from "../style"
import StylesList from "../StylesList/StylesList"

const LayerStyleEditor = ({
    colorsArray,
    distinctColumnValues,
    layer,
    minMaxColumnValues,
    minZoomLimit,
    selectedDatasetColumn,
    hasCpt,
    isDprLayer,
    setSelectedDatasetColumn,
    onPropertiesChanged,
    onPropertyChanged,
    ...props
}) => {
    const styles = layer.styles

    const [selectedStyleId, setSelectedStyleId] = useState(styles?.[0].styleId)

    useEffect(() => {
        if (layer.resourceId) setSelectedStyleId(styles?.[0].styleId)
    }, [layer?.resourceId, styles.length])

    const onSelectStyle = styleId => {
        setSelectedStyleId(styleId)
    }

    const selectedStyle = styles.find(style => style.styleId === selectedStyleId)
    const style = selectedStyle && (
        <Style
            style={selectedStyle}
            onPropertiesChanged={properties => onPropertiesChanged(selectedStyle, properties)}
            onPropertyExpressionTypeChanged={type => props.onPropertyExpressionTypeChanged(type, selectedStyle)}
            onTypeChanged={type => props.onTypeChanged(type, selectedStyle)}
            onZoomSliderChange={zoom => props.onZoomSliderChange(selectedStyle, zoom)}
        />
    )

    const contextValues = {
        // global info
        colorsArray,
        // column related properties
        columns: props.columns,
        distinctColumnValues,
        layer,
        minMaxColumnValues,
        minZoomLimit,
        selectedDatasetColumn,
        styleConfig: props.styleConfig,
        hasCpt,
        isDprLayer,
        setSelectedDatasetColumn,
        onPropertiesChanged,
        //handlers
        onPropertyChanged,
    }

    return (
        <div className="style-list">
            <StylerContextProvider stylerProps={contextValues}>
                <div className="styles-container">
                    <div className="styles">
                        <StylesList
                            selectedStyleId={selectedStyleId}
                            onAddStyleToLayer={props.onAddStyleToLayer}
                            onRemoveStyleFromLayer={props.onRemoveStyleFromLayer}
                            onSelectStyle={onSelectStyle}
                            onStyleDrop={props.onStyleDrop}
                        />
                    </div>
                    <Divider orientation="vertical" />
                </div>
                <div className="style-container">{style}</div>
            </StylerContextProvider>
        </div>
    )
}

export default LayerStyleEditor
