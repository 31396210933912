import { Redirect, Route, Switch } from "react-router-dom"
import config from "config"
import { APPS_VIEW_PATH, ROOT_MAP_VIEW_PATH } from "constants/routes"
import { BufferWWContextProvider } from "features/buffer/components/BufferWWContextProvider"
import AppsView from "views/AppsView"
import MapView from "views/MapView"
import { AisProvider, AppProvider } from "../../providers"

const MainView = () => (
    <Switch>
        <Route component={AppsView} exact path={APPS_VIEW_PATH} />
        <Route
            path={ROOT_MAP_VIEW_PATH}
            render={matchProps => (
                <BufferWWContextProvider
                    appId={matchProps.match.params.appId}
                    maxNumberOfWorkers={config.bufferOptions.maxNumberOfWebWorkers}
                >
                    <AppProvider>
                        <AisProvider>
                            <MapView path={matchProps.match.path} />
                        </AisProvider>
                    </AppProvider>
                </BufferWWContextProvider>
            )}
        />
        <Redirect to={APPS_VIEW_PATH} />
    </Switch>
)

export default MainView
