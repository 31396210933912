import { ChangeEventHandler, useEffect, useMemo, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import { Divider, InputAdornment, TextField, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import Header from "app/components/Header/Header"
import HeaderButtons from "app/components/HeaderButtons/HeaderButtons"
import useAuthentication from "app/hooks/useAuthentication"
import AppCard from "components/AppCard/AppCard"
import { App } from "model/app/App"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"
import * as AtlasActions from "../actions/atlasClient"
import ErrorPlaceholder from "../components/common/ErrorPlaceholder/ErrorPlaceholder"
import LoadingPlaceholder from "../components/common/LoadingPlaceholder/LoadingPlaceholder"
import { getAppsError, getAppsSelector, getLoadingApps } from "../selectors/appsSelectors"
import { useAppViewStyles } from "./styles/appsViewStyles"

const AppsView = () => {
    const classes = useAppViewStyles()

    const [searchString, setSearchString] = useState("")

    const apps: App[] = useAppSelector(getAppsSelector)
    const appsLoading = useAppSelector(getLoadingApps)
    const hasErrorOccured = useAppSelector(getAppsError)

    const { accessToken } = useAuthentication()

    const history = useHistory()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (apps.length === 1) history.push(getPathname(apps[0]))
    }, [apps])

    const getPathname = (app: App) => {
        const publicRoute = app.public ? "public/" : ""
        return `${publicRoute}${app.id}/map/layer-selector`
    }

    const sortByModified = (app1: App, app2: App) => {
        return new Date(app2.modifiedUtc).getTime() - new Date(app1.modifiedUtc).getTime()
    }

    const onSearchChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = e => {
        setSearchString(e.target.value)
    }

    const filteredAndSortedApps = useMemo(
        () =>
            apps
                .filter(app => app.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase()))
                .sort(sortByModified),
        [apps, searchString],
    )

    const renderedApps =
        apps !== null && filteredAndSortedApps.length !== 0 && !!accessToken ? (
            filteredAndSortedApps.map(app => (
                <AppCard key={app.name} app={app} getPathname={() => getPathname(app)} token={accessToken} />
            ))
        ) : (
            <div className={classes.noApps}>No Applications</div>
        )

    return (
        <div className={classes.root}>
            <div className={classes.topbar}>
                <Header />
                <HeaderButtons />
            </div>
            <Divider />
            <div className={classes.container}>
                <LoadingPlaceholder loading={appsLoading} message="Getting your apps" spinnerSize={65} textVariant="h5">
                    <ErrorPlaceholder
                        error={hasErrorOccured}
                        message="Encountered an error while getting your apps"
                        textVariant="h5"
                        onTryAgain={() => dispatch(AtlasActions.getApps())}
                    >
                        <ErrorPlaceholder
                            error={apps.length === 0}
                            message="You have no available apps at the moment!"
                            textVariant="h5"
                            onTryAgain={() => dispatch(AtlasActions.getApps())}
                        >
                            <Typography className={classes.selectAppTitle} data-testid={headerTestId} variant="h2">
                                Select an Application
                            </Typography>
                            <Divider />
                            <div className={classes.searchFieldContainer}>
                                <TextField
                                    className={classes.searchField}
                                    hiddenLabel
                                    inputProps={{ "data-testid": searchbarTestId }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon className={classes.searchIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder="Search for an Application"
                                    size="small"
                                    variant="filled"
                                    onChange={onSearchChange}
                                />
                            </div>
                            <Divider />
                            <div className={classes.appsGrid}>{renderedApps}</div>
                        </ErrorPlaceholder>
                    </ErrorPlaceholder>
                </LoadingPlaceholder>
            </div>
        </div>
    )
}

export default AppsView

const headerTestId = "qa-apps-view-header"
const searchbarTestId = "qa-apps-view-searchbar"
