import CloseIcon from "@mui/icons-material/Close"
import InfoIcon from "@mui/icons-material/Info"
import InfoIconOutlined from "@mui/icons-material/InfoOutlined"
import SearchIcon from "@mui/icons-material/Search"
import {
    Box,
    Checkbox,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    InputAdornment,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import dayjs from "dayjs"
import { CollapsibleCard } from "@windgis/shared"
import CardTitle from "components/sidebar/ais/CardTitle"
import { Subscription } from "model/ais/Subscription"
import { fromNowWithCheck } from "utils/timeUtils"
import { VesselUiModel } from "../../models/Vessel.ui.model"
import VesselsDetails from "../VesselsDetails/VesselsDetails"
import { StyledTextField } from "./AisSubscriptionCard.styles"

export type AisSubscriptionCardProps = {
    areLiveVesselsVisibleOnMap?: boolean
    followedVesselId?: string
    searchValue: string
    subscription: Subscription
    vesselsCount: number
    vesselsRecord: Record<string, VesselUiModel[]>
    isArchiveFeatureEnabled: boolean
    isArchiveMetadataLoading: boolean
    isArchivePlayerActive: boolean
    isToggled: boolean
    isToggleDisabled?: boolean
    onArchivePlayerCheck: (isChecked: boolean) => void
    onFollowVesselClick?: (vesselId: string, coordinates: { lat?: number; lon?: number }) => void
    onSearchValueChange: (value: string) => void
    onToggleVisibility: (isVisible: boolean) => void
    onZoomInClick?: (coordinates: { lat?: number; lon?: number }) => void
}

const AisSubscriptionCard = ({
    areLiveVesselsVisibleOnMap,
    followedVesselId,
    searchValue,
    subscription,
    vesselsCount,
    vesselsRecord,
    isArchiveFeatureEnabled,
    isArchiveMetadataLoading,
    isArchivePlayerActive,
    isToggled,
    isToggleDisabled,
    onArchivePlayerCheck,
    onFollowVesselClick,
    onSearchValueChange,
    onToggleVisibility,
    onZoomInClick,
}: AisSubscriptionCardProps) => {
    const vesselsEntries = Object.entries(vesselsRecord)
    const showVessels = vesselsEntries.length > 0

    return (
        <Box boxShadow="0 2px 12px 1px rgba(60, 62, 66, 0.1)">
            <CollapsibleCard
                Component={
                    <CardTitle
                        sub={subscription}
                        toggleCard={() => (isToggleDisabled ? undefined : onToggleVisibility(!isToggled))}
                        onToggleVisibility={isVisible => !isVisible && onArchivePlayerCheck(false)}
                    />
                }
                headerPadding={1}
                id={subscription.id}
                padding={0}
                paddingBottom={0}
                unmountOnExit
                isToggled={isToggled}
                isToggleDisabled={isToggleDisabled}
                onToggleClick={() => onToggleVisibility(!isToggled)}
            >
                <Stack>
                    <Stack gap={2} padding={2}>
                        <Stack flexDirection="row" justifyContent="space-between">
                            <Typography>
                                Update Frequency:{" "}
                                <Typography component="span" fontWeight="bold">
                                    every {subscription.updateFrequency} minutes
                                </Typography>
                            </Typography>

                            <Tooltip
                                arrow
                                placement="right"
                                title={
                                    <>
                                        <Box>
                                            <Typography component="span" fontWeight="bold">
                                                Description:
                                            </Typography>{" "}
                                            <Typography component="span">{subscription.description}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="span" fontWeight="bold">
                                                Type:
                                            </Typography>{" "}
                                            <Typography component="span">{subscription.type}</Typography>
                                        </Box>
                                    </>
                                }
                            >
                                <InfoIconOutlined fontSize="small" />
                            </Tooltip>
                        </Stack>

                        {isArchiveFeatureEnabled && (
                            <Stack alignItems="center" flexDirection="row" marginLeft={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Box marginRight={1}>
                                                <Checkbox
                                                    checked={isArchivePlayerActive}
                                                    onChange={e => onArchivePlayerCheck(e.target.checked)}
                                                />
                                            </Box>
                                        }
                                        label="Enable timelapse re-play"
                                    />
                                </FormGroup>
                                {isArchiveMetadataLoading && <CircularProgress size={20} />}
                            </Stack>
                        )}
                    </Stack>

                    <Divider />

                    <Stack gap={2} padding={2}>
                        <Typography>Vessels ({vesselsCount})</Typography>
                        <StyledTextField
                            InputProps={{
                                endAdornment: searchValue ? (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => onSearchValueChange("")}>
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder="Search vessel"
                            value={searchValue}
                            variant="filled"
                            disabled={!showVessels && searchValue === ""}
                            onChange={e => onSearchValueChange(e.target.value)}
                        />
                    </Stack>

                    <Stack gap={2} paddingBottom={2} paddingInline={2}>
                        {showVessels ? (
                            vesselsEntries.map(([type, vessels], index) => (
                                <VesselsDetails
                                    key={`${type}-${index}-${subscription.id}`}
                                    areLiveVesselsVisible={areLiveVesselsVisibleOnMap}
                                    followedVesselId={followedVesselId}
                                    latestUpdate={fromNowWithCheck(dayjs(subscription.latestUpdateUtc))}
                                    searchValue={searchValue}
                                    type={type}
                                    vessels={vessels}
                                    onFollowVesselClick={onFollowVesselClick}
                                    onZoomInClick={onZoomInClick}
                                />
                            ))
                        ) : (
                            <Typography>
                                <Stack component="span" flexDirection="row" gap={1}>
                                    <InfoIcon fontSize="small" /> No vessels to display
                                </Stack>
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </CollapsibleCard>
        </Box>
    )
}

export default AisSubscriptionCard
