import React, { useState } from "react"
import LanguageIcon from "@mui/icons-material/Translate"
import { FormControlLabel, List, Paper, Popover, Radio, RadioGroup, Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles(() => ({
    icon: {
        color: "#484848",
    },
    iconContainer: {
        "&:hover": {
            backgroundColor: "whitesmoke",
        },
        alignItems: "center",
        cursor: "pointer",
        display: "flex",
        height: 29,

        justifyContent: "center",
        width: 29,
    },
    iconRoot: {
        backgroundColor: "white",
        borderRadius: 4,
        boxShadow: "0 0 0 2px rgb(0 0 0 / 10%)",
        position: "absolute",
        right: 10,
        top: 341,
        zIndex: 2,
    },
    languagesContainer: {
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        padding: 0,
    },
    list: {
        maxHeight: 280,
        overflow: "auto",
        overflowX: "overlay",
        position: "relative",
    },
    listTitle: {
        padding: 8,
        textAlign: "center",
    },
    radioText: {
        paddingLeft: 8,
        textTransform: "capitalize",
    },
    sectionContainer: {
        backgroundColor: "#fff",
        padding: 0,
    },
}))

const LanguageSelector = ({ fullScreenEl, languages, value, onChange }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const id = open ? "language-selector-menu" : undefined

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onLanguageChanged = e => {
        let newLanguage = e.target.value
        onChange(newLanguage)
    }

    const getLanguageTestId = languageTitle => {
        return languageRadioButtonTestId + languageTitle.toLocaleLowerCase().replaceAll(" ", "-")
    }

    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Map Language">
                <div className={classes.iconContainer} data-testid={languageButtonTestId} onClick={handleClick}>
                    <LanguageIcon className={classes.icon} />
                </div>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "left",
                    vertical: "center",
                }}
                container={fullScreenEl}
                id={id}
                open={open}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                onClose={handleClose}
            >
                <Paper data-testid={languageContainerTestId}>
                    <div className={classes.listTitle}>
                        <Typography variant="h6">Map Language</Typography>
                    </div>
                    <List className={classes.list} subheader={<li />}>
                        <li className={classes.sectionContainer}>
                            <ul className={classes.languagesContainer}>
                                <RadioGroup
                                    aria-label="basement selector"
                                    name="basement selector"
                                    value={value}
                                    onChange={onLanguageChanged}
                                >
                                    {[...languages]
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map(language => (
                                            <FormControlLabel
                                                key={language.name}
                                                className={classes.radioText}
                                                control={<Radio data-testid={getLanguageTestId(language.name)} />}
                                                label={language.name}
                                                value={language.code}
                                            />
                                        ))}
                                </RadioGroup>
                            </ul>
                        </li>
                    </List>
                </Paper>
            </Popover>
        </div>
    )
}

export default LanguageSelector

const languageButtonTestId = "qa-language-selector-language-button"
const languageContainerTestId = "qa-language-selector-language-container"
const languageRadioButtonTestId = "qa-language-selector-language-radio-button-"
