import CheckIcon from "@mui/icons-material/Check"
import { Grid, MenuItem } from "@mui/material"
import { CustomTypography } from "@windgis/shared"
import ButtonLink from "components/common/ButtonLink/ButtonLink"
import { useStyles } from "./styles"

type Props = {
    description: string
    favIconUrl: string
    moduleUrl: string
    name: string
    isAccessible: boolean
}

const ModuleItem = ({ description, favIconUrl, moduleUrl, name, isAccessible }: Props) => {
    const classes = useStyles()

    return (
        <MenuItem className={classes.rootItem}>
            <Grid
                className={classes.clickableContainer}
                component={ButtonLink}
                container
                justifyContent="space-between"
                target="_blank"
                to={{ pathname: moduleUrl }}
                wrap="nowrap"
            >
                <Grid alignItems="center" container item xs>
                    <img alt="" className={classes.moduleIcon} src={favIconUrl} />
                    <div>
                        <CustomTypography className={classes.moduleTitle}>{name}</CustomTypography>
                        <CustomTypography className={classes.moduleDescription}>{description}</CustomTypography>
                    </div>
                </Grid>

                <Grid alignItems="center" container item wrap="nowrap" xs="auto">
                    {isAccessible ? (
                        <>
                            <CheckIcon className={classes.checkedIcon} fontSize="small" />
                            <CustomTypography className={classes.activeText}> Active </CustomTypography>
                        </>
                    ) : (
                        <CustomTypography className={classes.noAccessBtn}>Find out more</CustomTypography>
                    )}
                </Grid>
            </Grid>
        </MenuItem>
    )
}

export default ModuleItem
