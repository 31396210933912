import React from "react"
import { Backdrop, Dialog } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { getToggledWidgetsSet, getWidgets } from "features/mapTools/selectors"
import { toggleWidget } from "features/mapTools/slice"
import widgetTypes from "utils/tools/widgets"
import PrintPreview from "./printPreview"

const PrintModal = () => {
    // getModalStyle is not a pure function, we roll the style only on the first render
    const dispatch = useDispatch()
    const widgets = useSelector(getWidgets)
    const modalOpen = useSelector(getToggledWidgetsSet).has(widgetTypes.PRINT)
    const printWidget = widgets.find(widget => widget.name === widgetTypes.PRINT)

    const handleClose = () => {
        dispatch(toggleWidget(printWidget.name))
    }

    return (
        <Dialog BackdropComponent={Backdrop} maxWidth={false} open={modalOpen} onClose={handleClose}>
            <PrintPreview handleClose={handleClose} />
        </Dialog>
    )
}

export default PrintModal
