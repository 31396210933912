import { Card, CardMedia, Grid, css, styled } from "@mui/material"
import CustomChip from "components/CustomChip/CustomChip"

export const StyledCard = styled(Card)`
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
`
export const StyledCardMedia = styled(CardMedia)(
    ({ theme }) => css`
        aspect-ratio: 16 / 9;
        background-color: ${theme.customColors.appBgColor};
        width: 100%;
    `,
)

export const StyledGridItem = styled(Grid)`
    min-width: 0;
`

export const StyledCustomChip = styled(CustomChip)(
    ({ theme }) => css`
        align-self: center;
        background-color: #d3e2ea;
        color: ${theme.palette.primary.main};
        fill: ${theme.palette.primary.main};
    `,
)
