import { BoxChartWithLabelsCanvas, PieChartWithLabelsCanvas } from "@emblautec/layer-styler-component"
import { Styling } from "features/common/models/legend"
import { LineIcon, PointIcon, PolygonIcon, RasterIcon, SymbolIcon } from "../../icons/LegendIcons"

type LegendIconProps = {
    styling?: Styling
}

const LegendIcon = ({ styling }: LegendIconProps) => {
    if (!styling || typeof styling.type !== "string") return <></>

    switch (styling.type) {
        case "symbol":
            return (
                <SymbolIcon
                    symbolColor={styling["symbolColor"]}
                    symbolIconColor={styling["symbolIconColor"]}
                    symbolImage={styling["symbolImage"]}
                />
            )
        case "circle":
            return (
                <PointIcon
                    circleColor={styling["circleColor"]}
                    circleStrokeColor={styling["circleStrokeColor"]}
                    circleStrokeWidth={styling["circleStrokeWidth"]}
                />
            )
        case "line":
            return (
                <LineIcon
                    circleColor={styling["circleColor"]}
                    lineColor={styling["lineColor"]}
                    lineDashArray={styling["lineDashArray"]}
                />
            )

        case "fill":
            return (
                <PolygonIcon
                    circleColor={styling["circleColor"]}
                    fillColor={styling["fillColor"]}
                    lineColor={styling["lineColor"]}
                />
            )
        case "pie-chart":
            return (
                <PieChartWithLabelsCanvas
                    centerFillPercentage={styling["pie-chartCenterSize"] / styling["pie-chartSize"]}
                    colors={styling["pie-chartColors"]}
                    height={240}
                    labels={styling["pie-chartLabels"]}
                    style={{
                        fontSize: 12,
                        strokeColor: styling["pie-chartStrokeColor"],
                        strokeWidth: styling["pie-chartStrokeWidth"],
                    }}
                    width={370}
                />
            )
        case "box-chart":
            return (
                <BoxChartWithLabelsCanvas
                    colors={styling["box-chartColors"]}
                    columns={styling["box-chartColumns"]}
                    height={50 * styling["box-chartRows"]}
                    labels={styling["box-chartLabels"]}
                    rows={styling["box-chartRows"]}
                    style={{
                        strokeColor: styling["box-chartStrokeColor"],
                        strokeWidth: styling["box-chartStrokeWidth"],
                    }}
                    width={370}
                />
            )

        case "raster":
            return <RasterIcon rasterColor={styling["rasterColor"]} />

        default:
            console.error(`Styling type not supported from: ${styling.type}`)
            return <></>
    }
}

export default LegendIcon
