import { makeStyles } from "@mui/styles"

export const useStyles = makeStyles({
    circularProgress: {
        marginRight: -16,
    },
    content: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        position: "absolute",
        right: 45,
        top: 49,
        zIndex: 2,
    },
    input: {
        padding: "2px 32px 2px 6px !important",
    },
    textField: {
        backgroundColor: "white",
        borderRadius: "10px !important",
        minWidth: 300,
    },
})
