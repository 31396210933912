import { IconButton, Typography, css, styled } from "@mui/material"

export const StyledHeaderDiv = styled("div")(
    ({ theme }) => css`
        align-items: center;
        background-color: ${theme.customColors.primaryColor};
        color: ${theme.customColors.white};
        display: grid;
        gap: ${theme.spacing(1)};
        grid-template-columns: auto min-content min-content;
        padding: ${theme.spacing(1)};
    `,
)
export const StyledDownloadContainerDiv = styled("div")`
    align-items: center;
    display: flex;
`
export const StyledIconButton = styled(IconButton)(
    ({ theme }) => css`
        color: ${theme.customColors.white};
    `,
)

export const StyledSelectedValueTypography = styled(Typography)(
    ({ theme }) => css`
        color: ${theme.customColors.white};
        margin-right: 5px;
        margin-top: 2px;
    `,
)
