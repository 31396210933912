import { useEffect, useState } from "react"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import TuneIcon from "@mui/icons-material/Tune"
import { Checkbox, FormControlLabel, FormGroup, Popover, Stack, TextField, Typography } from "@mui/material"
import { Dayjs } from "dayjs"
import { CustomModal } from "@windgis/shared"
import CustomDateRangePicker from "components/CustomDatePicker/CustomRangeDatePicker"
import { updateArchiveDisplayOptions } from "reducers/ais"
import { getArchiveDisplayOptions } from "selectors/aisSelectors"
import { useAppDispatch } from "store/hooks/useAppDispatch"
import { useAppSelector } from "store/hooks/useAppSelector"

type Props = {
    anchorEl: HTMLElement | null
    currentInterval: [Dayjs, Dayjs]
    endDate: Dayjs
    open: boolean
    startDate: Dayjs
    handlePopoverClose: () => void
    handlePopoverOpen: (e: React.MouseEvent<HTMLElement>) => void
    onApply: (e: [Dayjs, Dayjs]) => void
    onClose: () => void
}

const AisSettingsModal = ({
    anchorEl,
    currentInterval,
    endDate,
    open = false,
    startDate,
    handlePopoverClose,
    handlePopoverOpen,
    onApply,
    onClose,
}: Props) => {
    const [playbackInterval, setPlaybackInterval] = useState<[Dayjs, Dayjs]>(currentInterval)
    const dispatch = useAppDispatch()
    const currentDisplayOptions = useAppSelector(getArchiveDisplayOptions)
    const [displayOptions, setDisplayOptions] = useState(currentDisplayOptions)

    const handleDateChange = (newDates: [Dayjs, Dayjs]) => {
        setPlaybackInterval(newDates)
    }

    const handleClear = () => {
        setPlaybackInterval([startDate, endDate])
    }

    const handleConfirm = () => {
        dispatch(updateArchiveDisplayOptions(displayOptions))
        onApply(playbackInterval)
    }

    const handleClose = () => {
        setDisplayOptions(currentDisplayOptions)
        onClose()
    }

    useEffect(() => {
        setPlaybackInterval(currentInterval)
    }, [currentInterval])

    const openPopover = Boolean(anchorEl)
    const id = open ? "date-range-popover" : undefined

    return (
        <CustomModal
            dialogTitle="Settings"
            dialogType="apply"
            isOpen={open}
            handleClose={handleClose}
            onConfirm={handleConfirm}
        >
            <Stack spacing={2}>
                <Stack alignItems="center" direction="row" spacing={1}>
                    <CalendarMonthIcon /> <Typography>Custom date range:</Typography>
                </Stack>
                <div>
                    <Stack direction="row" gap={1}>
                        <TextField
                            inputProps={{ style: { fontWeight: "normal" } }}
                            label="Start Date"
                            value={playbackInterval[0].format("YYYY-MM-DD HH:mm")}
                            onClick={handlePopoverOpen}
                        />

                        <TextField
                            inputProps={{ style: { fontWeight: "normal" } }}
                            label="End Date"
                            value={playbackInterval[1].format("YYYY-MM-DD HH:mm")}
                            onClick={handlePopoverOpen}
                        />
                    </Stack>
                    <Popover
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            horizontal: "left",
                            vertical: "bottom",
                        }}
                        id={id}
                        open={openPopover}
                        onClose={handlePopoverClose}
                    >
                        <CustomDateRangePicker
                            maxDate={endDate}
                            minDate={startDate}
                            values={playbackInterval}
                            onChange={handleDateChange}
                            onClear={handleClear}
                        />
                    </Popover>
                </div>
                <Stack alignItems="center" direction="row" spacing={1}>
                    <TuneIcon /> <Typography>Display options</Typography>
                </Stack>
                <FormGroup>
                    <Stack spacing={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={displayOptions.vesselName}
                                    onChange={e =>
                                        setDisplayOptions(prevOptions => ({
                                            ...prevOptions,
                                            vesselName: (e.target as HTMLInputElement).checked,
                                        }))
                                    }
                                />
                            }
                            label="Vessel name"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={displayOptions.speed}
                                    onChange={e =>
                                        setDisplayOptions(prevOptions => ({
                                            ...prevOptions,
                                            speed: (e.target as HTMLInputElement).checked,
                                        }))
                                    }
                                />
                            }
                            label="Speed"
                        />
                    </Stack>
                </FormGroup>
            </Stack>
        </CustomModal>
    )
}

export default AisSettingsModal
