import { ExpandLess } from "@mui/icons-material"
import { Box, Button, IconButton, Stack, Table, TableCell, css, styled } from "@mui/material"

type StyledTableCellKeyProps = {
    isIndented?: boolean
}

export const StyledStack = styled(Stack)(
    ({ theme }) => css`
        background-color: ${theme.customColors.white};
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
        position: sticky;
        top: 0;
        z-index: 2;
        padding-block: ${theme.spacing(1)};
    `,
)

export const StyledTable = styled(Table)({
    "& tr:last-of-type td": {
        borderBottom: 0,
    },
    backgroundColor: "white",
    borderCollapse: "collapse",
    tableLayout: "fixed",
})

export const StyledTableCellKey = styled(TableCell, {
    shouldForwardProp: prop => prop !== "isIndented",
})<StyledTableCellKeyProps>(({ theme, isIndented = false }) => ({
    borderBottom: `1px solid ${theme.customColors.borderColor}`,
    padding: theme.spacing(1),
    paddingLeft: isIndented ? "12px" : 0,
    textAlign: "left",
    textTransform: "capitalize",
}))

export const StyledTableCellValue = styled(TableCell)(({ theme }) => ({
    borderBottom: `1px solid ${theme.customColors.borderColor}`,
    padding: theme.spacing(1, 0),
    textAlign: "right",
    wordBreak: "break-word",
}))

export const ExpandedExpandLess = styled(ExpandLess)(({ theme }) => ({
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}))

export const CollapsedExpandLess = styled(ExpandLess)(({ theme }) => ({
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    borderRadius: 0,
    color: theme.customColors.black,
    height: 24,
    marginRight: theme.spacing(1),
    width: 24,
}))

export const SquaredIconButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.customColors.secondaryColor,
    borderRadius: 3,
    height: 30,
    marginLeft: theme.spacing(1),
    paddingBottom: 0,
    paddingTop: 0,
    width: 27,
}))

export const StyledBox = styled(Box)(({ theme }) => ({
    "&:not(:first-of-type)": {
        display: "none",
    },
    fontSize: 14,
    fontWeight: "bold",
    paddingBlock: theme.spacing(1),
    textAlign: "left",
    textTransform: "capitalize",
}))

export const StyledRootStack = styled(Stack)(({ theme }) => ({
    backgroundColor: "white",
    borderRadius: 5,
    overflow: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
        bottom: 8,
        left: 8,
        right: 8,
    },
    width: "100%",
    zIndex: 4,
}))

export const StyledCollapseAllButton = styled(Button)(({ theme }) => ({
    color: theme.customColors.primaryColor,
    height: 38,
    paddingLeft: theme.spacing(2),
}))

export const StyledFeaturesBox = styled(Box)(({ theme }) => ({
    "&::-webkit-scrollbar-track": {
        borderLeft: "1px solid" + theme.customColors.borderColor + " !important",
        marginTop: 40,
    },
    // Add margin after last collapsible card but only if it's not the last layer
    "& > div:not(:last-child) > .MuiCollapse-root:last-child": {
        marginBottom: theme.spacing(2),
    },
    backgroundColor: "white",
    marginTop: 38,
    minHeight: 100,
    overflowX: "hidden",
    overflowY: "auto",
    padding: theme.spacing(0, 2, 2, 2),
}))
