import Tooltip from "@mui/material/Tooltip"
import { CustomTypography } from "@windgis/shared"

const ReadOnlyField = props => {
    return (
        <Tooltip placement="top-start" title={props.tooltipTitle || ""} disableHoverListener={!props.tooltip}>
            <div>
                <CustomTypography textWeight="bold" variant="body2">
                    {props.label}
                </CustomTypography>
                <CustomTypography variant="body2">{props.text}</CustomTypography>
            </div>
        </Tooltip>
    )
}
export default ReadOnlyField
