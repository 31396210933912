import MapboxDraw from "@mapbox/mapbox-gl-draw"
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode"
import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode } from "mapbox-gl-draw-circle"
import { mapboxDrawStyles } from "utils/mapboxDrawStyles"

export const createDraw = () => {
    var modes = {
        ...MapboxDraw.modes,
        direct_select: DirectMode,
        drag_circle: DragCircleMode,
        draw_circle: CircleMode,
        simple_select: SimpleSelectMode,
        static: StaticMode,
    }

    return new MapboxDraw({
        controls: {
            combine_features: false,
            line_string: false,
            point: false,
            polygon: false,
            trash: false,
            uncombine_features: false,
        },
        modes: modes,
        styles: mapboxDrawStyles,
        userProperties: true,
    })
}
