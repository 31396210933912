import React, { useState } from "react"
import MapIcon from "@mui/icons-material/Map"
import { FormControlLabel, List, ListSubheader, Paper, Popover, Radio, RadioGroup, Typography } from "@mui/material"
import Tooltip from "@mui/material/Tooltip"
import { useBasemapSelectorStyles } from "./styles/basemapSelectorStyles"

const BasemapSelector = ({ basemaps, fullScreenEl, value, onChange }) => {
    const classes = useBasemapSelectorStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const id = open ? "basemap-selector-menu" : undefined
    const basemapTypes = ["Vector", "Raster"]

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const onBasemapChanged = basemap => {
        onChange(basemap)
    }

    const getBasemapTestId = basemapTitle => {
        return basemapRadioButtonTestId + basemapTitle.toLocaleLowerCase().replaceAll(" ", "-")
    }
    return (
        <div className={classes.iconRoot}>
            <Tooltip title="Basemaps">
                <div className={classes.iconContainer} data-testid={basemapButtonTestId} onClick={handleClick}>
                    <MapIcon className={classes.icon} />
                </div>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: "left",
                    vertical: "center",
                }}
                container={fullScreenEl}
                id={id}
                open={open}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                onClose={handleClose}
            >
                <Paper data-testid={basemapContainerTestId}>
                    <div className={classes.listTitle}>
                        <Typography variant="h6">Basemap</Typography>
                    </div>
                    <List className={classes.list} subheader={<li />}>
                        {basemapTypes.map(basemapType => (
                            <li key={`section-${basemapType}`} className={classes.sectionContainer}>
                                <ul className={classes.basemapsContainer}>
                                    <ListSubheader>{basemapType}</ListSubheader>
                                    <RadioGroup aria-label="basement selector" name="basement selector" value={value}>
                                        <FormControlLabel
                                            className={classes.radioText}
                                            control={
                                                <Radio
                                                    checked={value.type === "none"}
                                                    data-testid={getBasemapTestId("none")}
                                                    onClick={() => onBasemapChanged({ type: "none" })}
                                                />
                                            }
                                            label="None"
                                            value="none"
                                        />
                                        {basemaps
                                            .filter(basemap => basemap.type === basemapType.toLocaleLowerCase())
                                            .map(basemap => (
                                                <FormControlLabel
                                                    key={basemap.title}
                                                    className={classes.radioText}
                                                    control={
                                                        <Radio
                                                            checked={basemap.title === value.title}
                                                            data-testid={getBasemapTestId(basemap.title)}
                                                            onClick={() => onBasemapChanged(basemap)}
                                                        />
                                                    }
                                                    label={basemap.title}
                                                    value={basemap.title}
                                                />
                                            ))}
                                    </RadioGroup>
                                </ul>
                            </li>
                        ))}
                    </List>
                </Paper>
            </Popover>
        </div>
    )
}

export default BasemapSelector

const basemapButtonTestId = "qa-basemap-selector-basemap-button"
const basemapContainerTestId = "qa-basemap-selector-basemap-container"
const basemapRadioButtonTestId = "qa-basemap-selector-basemap-radio-button-"
