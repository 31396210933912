import { useCallback, useEffect, useLayoutEffect, useState } from "react"

export interface Size {
    height: number
    width: number
}

/**
 * `useElementSize` is a hook to measure the size of a DOM element.
 * It tracks the width and height of the element and updates them on window resize or element changes.
 *

 * @return - A tuple containing a ref callback and the size of the element. The ref callback should be passed to the element you want to measure. The size object contains the width and height of the element.
 */

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [(node: T | null) => void, Size] {
    const [ref, setRef] = useState<T | null>(null)
    const [size, setSize] = useState<Size>({ height: 0, width: 0 })

    const handleSize = useCallback(() => {
        if (ref) {
            setSize({
                height: ref.offsetHeight,
                width: ref.offsetWidth,
            })
        }
    }, [ref])

    const useEnviromentEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect

    useEnviromentEffect(() => {
        if (!ref) return

        handleSize()

        const resizeObserver = new ResizeObserver(handleSize)
        resizeObserver.observe(ref)

        return () => resizeObserver.disconnect()
    }, [ref, handleSize])

    return [setRef, size]
}

export default useElementSize
