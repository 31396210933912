import { FC, Fragment } from "react"
import LaunchIcon from "@mui/icons-material/Launch"
import { Box, Link, Stack, Table, TableRow, Tooltip } from "@mui/material"
import dayjs from "dayjs"
import { CustomTypography } from "@windgis/shared"
import useGetIsPublicApp from "app/hooks/useGetIsPublicApp"
import config from "config"
import {
    StyledTableCellKey,
    StyledTableCellValue,
} from "features/infobox/components/DefaultInfobox/DefaultInfobox.styles"
import { getLayerStyles } from "selectors/layerSelector"
import { useAppSelector } from "store/hooks/useAppSelector"
import useDprLocationHistory from "./useDprLocationHistory"

type Props = {
    feature: mapboxgl.MapboxGeoJSONFeature
}

const DprProperties: FC<Props> = ({ feature }) => {
    // @ts-ignore TODO: fix this
    const styles = useAppSelector(getLayerStyles(feature?.layer?.metadata?.resourceId))
    const isPublic = useGetIsPublicApp()

    const { locationHistory, shouldDisplayInfo } = useDprLocationHistory({ feature })

    const getDisplayName = (historyEntry: any) => {
        if (
            (styles[0].type as any) === "pie-chart" ||
            (styles[0].type as any) === "box-chart" ||
            !!historyEntry.dprLink
        ) {
            return (
                <Link
                    color="primary"
                    href={isPublic ? config.dprShowcaseUrl : historyEntry.dprLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    underline="none"
                >
                    <Tooltip title="Open DPR in new tab">
                        <Stack direction="row" gap={1} paddingTop={2}>
                            <CustomTypography color="primary" textWeight="bold" variant="body2">
                                {styles[0].properties.filter(x => x.title === "labels")?.[0]?.value[
                                    historyEntry.milestoneBitPosition
                                ] ?? historyEntry.milestoneDisplayName}
                            </CustomTypography>
                            <LaunchIcon color="primary" fontSize="small" />
                        </Stack>
                    </Tooltip>
                </Link>
            )
        } else {
            return historyEntry.milestoneDisplayName
        }
    }

    if (!shouldDisplayInfo) return null

    return locationHistory?.map(historyEntry => (
        <Fragment key={`${historyEntry.locationName}-${historyEntry.milestoneId}-${historyEntry.dprDate}`}>
            {getDisplayName(historyEntry)}
            <Box
                borderLeft={theme => `1px dashed ${theme.customColors.borderColor}`}
                marginBlock={1}
                marginLeft={1}
                paddingLeft={1}
            >
                <Table>
                    <TableRow key="Completion Date">
                        <StyledTableCellKey width={"35%"}>Milestone Date</StyledTableCellKey>
                        <StyledTableCellValue>{dayjs(historyEntry.dprDate).format("L")}</StyledTableCellValue>
                    </TableRow>
                    <TableRow key="Subproject">
                        <StyledTableCellKey>Subproject</StyledTableCellKey>
                        <StyledTableCellValue>
                            {historyEntry.scope} / {historyEntry.vessel}
                        </StyledTableCellValue>
                    </TableRow>
                </Table>
            </Box>
        </Fragment>
    ))
}

export default DprProperties
