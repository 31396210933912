import { RootState } from "../store/models/RootState"

export const getAISSubscriptionData = (state: RootState) => state.AIS.subscriptionData

export const getAISSubscriptionInfo = (state: RootState) => state.AIS.subscriptionInfo

export const getArchiveData = (state: RootState) => state.AIS.archiveData

export const getBoatDetails = (state: RootState) => state.AIS.boatDetailsDictionary

export const getArchiveDisplayOptions = (state: RootState) => state.AIS.archiveDisplayOptions

export const getSelectedArchiveSubscriptionId = (state: RootState) => state.AIS.selectedArchiveSubscriptionId

export const getPickedArchiveData = (state: RootState) => state.AIS.pickedArchiveData

export const getIsPlaybackPlaying = (state: RootState) => state.AIS.isPlaybackPlaying

export const getAISSubscriptionVisibility = (state: RootState, subscriptionId: string) =>
    state.AIS.subscriptionData[subscriptionId]?.visibility

export const getAreAISPlaybackBoatsOnMap = (state: RootState) => state.AIS.areAISPlaybackBoatsOnMap

export const getSelectedVesselHasNoData = (state: RootState) => state.AIS.selectedVesselHasNoData
