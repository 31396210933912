export function getColor(colors: string[], index: number) {
    return colors[index % colors.length]
}

export function getColors(colors: string[], count: number) {
    let colorArray = []

    let colorIndex = 0
    for (let i = 0; i < count; i++) {
        colorArray.push(colors[colorIndex])

        if (colorIndex >= colors.length) {
            colorIndex = 0
        } else {
            colorIndex = i
        }
    }

    return colorArray
}

export function interpolateColors(color1: string, color2: string, steps: number) {
    let colorArray = []

    let stepValue = 1.0 / (steps + 1)

    for (let ratio = 0.0; ratio <= 1.0; ratio += stepValue) {
        colorArray.push(interpolateColor(color1, color2, ratio))
    }

    return colorArray
}

export function interpolateColorArray(colors: string[], steps: number) {
    let colorArray = []

    colorArray.push(colors[0])

    if (steps === 1) {
        return colorArray
    }

    let stepValue = (colors.length - 1) / (steps - 1)

    let colorValue = stepValue

    for (let i = 1; i < steps - 1; i++) {
        let color = Math.floor(colorValue)
        let ratio = colorValue % 1
        colorArray.push(interpolateColor(colors[color], colors[color + 1], ratio))
        colorValue += stepValue
    }

    colorArray.push(colors[colors.length - 1])

    return colorArray
}

function interpolateColor(a: string, b: string, amount: number) {
    var ah = parseInt(a.replace(/#/g, ""), 16),
        ar = ah >> 16,
        ag = (ah >> 8) & 0xff,
        ab = ah & 0xff,
        bh = parseInt(b.replace(/#/g, ""), 16),
        br = bh >> 16,
        bg = (bh >> 8) & 0xff,
        bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab)

    return "#" + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1)
}
