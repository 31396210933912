import { Dayjs } from "dayjs"
import { GeoJsonProperties } from "geojson"
import { BoatDetailsInfobox } from "features/ais/models/BoatDetailsInfobox"
import { excludedProperties, isValidProperty } from "utils/infoBoxUtils"

type BuildPropertiesProps = {
    featureProperties: GeoJsonProperties | BoatDetailsInfobox
    latestCompletedColumn?: string
    progressColumn?: string
}

export type Property = [string, string | number | Dayjs]

export type BuildProprtiesResult = {
    properties: Property[]
    hasPropertiesToDisplay: boolean
}

export const useBuildProperties = ({
    featureProperties,
    latestCompletedColumn,
    progressColumn,
}: BuildPropertiesProps): BuildProprtiesResult => {
    if (!featureProperties)
        return {
            properties: [],
            hasPropertiesToDisplay: false,
        }

    const featurePropertiesFiltered = Object.entries(featureProperties).filter(
        ([key, value]) =>
            isValidProperty([key, value]) &&
            !excludedProperties.includes(key) &&
            key !== progressColumn &&
            key !== latestCompletedColumn,
    )

    if (featurePropertiesFiltered.length === 0) {
        return {
            properties: [],
            hasPropertiesToDisplay: false,
        }
    }

    return {
        properties: featurePropertiesFiltered,
        hasPropertiesToDisplay: true,
    }
}
