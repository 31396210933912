import { useEffect, useMemo, useState } from "react"
import { FilterList, KeyboardBackspace, Palette } from "@mui/icons-material"
import { Grid, IconButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { v4 as generateUuid } from "uuid"
import { LayerFilterEditor, LayerStyleEditor } from "@emblautec/layer-styler-component"
import { ID_COLUMN, TruncatedTypography, getClosestDivisors, hasCPT } from "@windgis/shared"
import * as datasetActions from "actions/atlasClient"
import LoadingPlaceholder from "components/common/LoadingPlaceholder/LoadingPlaceholder"
import { STYLE_TYPES } from "constants/layers/styleTypes"
import { useFetchDprDatasetQuery } from "features/dprIntegration/api"
import { useFetchRasterByIdQuery } from "features/rasters/api"
import { SourceType } from "model/enums/SourceType"
import { Operators } from "model/map/LayerFilterType"
import { setLayerIsFiltered } from "reducers/layerSelector"
import * as layerSelectorActions from "reducers/layerSelector"
import * as mapActions from "reducers/map"
import { getFeatureFlags } from "selectors/featureFlagsSelectors"
import { getLayerForStyling, getLayerStyles, getLayerVisibility, getShouldFilter } from "selectors/layerSelector"
import { getFiltersByLayerIdWithExpectedType, getSourceMinZoom } from "selectors/mapSelectors"
import { getStyleConfig } from "selectors/stylesSelectors"
import { makeVisibilityProperty } from "utils/creators/styleProperties/visibilityPropertyCreator"
import * as GeometryUtils from "utils/GeometryUtils"
import { StyledTab, StyledTabs } from "../../common/customTabs/customTabs"
import { useLayerSelectorStylerStyle } from "./styles"

const CHARACTER_VARYING = "character varying"
const DOUBLE_PRECISION = "double precision"
const STYLE_TAB = "style"
const FILTER_TAB = "filter"

const LayerEditor = ({ colorsArray, isDigitizeLayer }) => {
    const classes = useLayerSelectorStylerStyle()

    const [datasetColumns, setDatasetColumns] = useState([])
    const [selectedDatasetColumn, setSelectedDatasetColumn] = useState(null)
    const [distinctColumnValues, setDistinctColumnValues] = useState([])
    const [minMaxColumnValues, setMinMaxColumnValues] = useState([]) //[min,max]

    const shouldFilter = useSelector(getShouldFilter)

    const [tab, setTab] = useState(shouldFilter ? FILTER_TAB : STYLE_TAB)

    const layer = useSelector(getLayerForStyling)

    if (!layer) return null

    const styles = useSelector(getLayerStyles(layer.resourceId))
    const sourceMinZoomLimit = useSelector(getSourceMinZoom(layer.sourceId))
    const visible = useSelector(getLayerVisibility(layer.resourceId))
    const styleConfig = useSelector(getStyleConfig)

    const featureFlags = useSelector(getFeatureFlags)

    const filters = useSelector(getFiltersByLayerIdWithExpectedType(layer.resourceId))

    const { data: dprDataset = null, isLoading } = useFetchDprDatasetQuery(layer.resourceId, {
        skip: !featureFlags.DPR || layer.type !== "vector" || layer.isBuffer,
    })

    const { data: raster = null } = useFetchRasterByIdQuery(layer.resourceId, { skip: layer.type !== "raster" })
    const hasCpt = useMemo(() => {
        return hasCPT(raster?.colorPalette)
    }, [raster])

    const dispatch = useDispatch()

    useEffect(() => {
        //Based on the selected layer we bring the dataset columns and by default select the first column
        if (Object.keys(layer).length === 0) {
            setDatasetColumns([])
            setSelectedDatasetColumn(null)
            setDistinctColumnValues([])
            setMinMaxColumnValues([])
        }

        if (layer && layer.resourceId && layer.type !== SourceType.Raster && !layer.isBuffer) {
            dispatch(datasetActions.getDatasetColumns(layer.resourceId)).then(res => {
                const results = res.result.filter(x => x.name !== ID_COLUMN)
                setDatasetColumns(results)
                setSelectedDatasetColumn(results[0])
            })
        }
    }, [layer.resourceId])

    useEffect(() => {
        //Based on the selected column we bring the distinct values and min,max pair
        if (selectedDatasetColumn) {
            const columnName = selectedDatasetColumn.name
            const selectedDatasetId = layer.resourceId
            if (selectedDatasetColumn.type !== DOUBLE_PRECISION) {
                dispatch(datasetActions.getDistinctColumnValues(selectedDatasetId, columnName)).then(res => {
                    setDistinctColumnValues(res.result)
                })
            }
            if (selectedDatasetColumn.type !== CHARACTER_VARYING) {
                dispatch(datasetActions.getMinMaxColumnValues(selectedDatasetId, columnName)).then(res => {
                    setMinMaxColumnValues([res.result.minValue, res.result.maxValue])
                })
            }
        }
    }, [selectedDatasetColumn])

    const filteredColumns = useMemo(() => {
        const columns = !dprDataset
            ? datasetColumns
            : datasetColumns.filter(
                  x =>
                      x.id !== dprDataset.progressColumnId &&
                      x.id !== dprDataset.latestCompletedColumnId &&
                      x.name !== ID_COLUMN,
              )

        setSelectedDatasetColumn(columns[0])
        return columns
    }, [datasetColumns, dprDataset])

    const clearEditView = () => {
        dispatch(layerSelectorActions.setStylerLayerId(null))
    }

    const initializeNewStyle = (layerId, properties) => {
        const paintProperties = properties.filter(x => x.type === "paint")

        const layoutProperties = properties.filter(x => x.type === "layout")
        layoutProperties.push(makeVisibilityProperty(visible))

        dispatch(mapActions.addMapPaint({ layerId, properties: paintProperties }))
        dispatch(mapActions.addMapLayout({ layerId, properties: layoutProperties }))

        return paintProperties
    }

    const getStyleConfigWithAutoSelectDprMilestones = type => {
        if (!dprDataset) return styleConfig

        if (type !== "box-chart" && type !== "pie-chart") return styleConfig

        const milestoneNames = dprDataset.milestones.map(x => x.displayName)
        const [rows, columns] = getClosestDivisors(milestoneNames.length)

        const getValue = (name, value) => {
            switch (name) {
                case `${type}-labels`:
                    return milestoneNames
                case `${type}-colors`:
                    return value.slice(0, milestoneNames.length)
                case `${type}-mask`:
                    return ["get", dprDataset.progressColumn.name]
                case `${type}-rows`:
                    return rows
                case `${type}-columns`:
                    return columns
                default:
                    return value
            }
        }

        return {
            ...styleConfig,
            [type]: styleConfig[type].map(x => ({
                ...x,
                value: getValue(x.name, x.value),
            })),
        }
    }

    const onStyleTypeChanged = (type, style) => {
        const styleConfig = getStyleConfigWithAutoSelectDprMilestones(type)
        const properties = JSON.parse(JSON.stringify(styleConfig[type]))
        dispatch(
            mapActions.updateMapLayer({
                layerId: style.styleId,
                resourceId: layer.resourceId,
                sourceId: layer.sourceId,
                sourceName: layer.sourceName,
                type,
            }),
        )
        const newStyle = initializeNewStyle(style.styleId, properties)
        dispatch(
            layerSelectorActions.changeTypeOfLayerStyle({
                layerId: layer.resourceId,
                properties,
                styleId: style.styleId,
                type,
            }),
        )

        return newStyle
    }

    const onPropertyChanged = (styleId, property) => {
        dispatch(
            mapActions.updateMapProperty({
                layerId: styleId,
                property,
            }),
        )

        dispatch(
            layerSelectorActions.changePropertyOfLayerStyle({
                layerId: layer.resourceId,
                property,
                styleId,
            }),
        )
    }

    const onPropertiesChanged = (style, newProperties) => {
        //A more efficient implementeation will take into account this layer prop when searching for the style in the reducer.
        const layerId = style.styleId

        dispatch(
            mapActions.updateMapPaint({
                layerId,
                properties: newProperties.filter(x => x.type === "paint"),
            }),
        )

        dispatch(
            mapActions.updateMapLayout({
                layerId,
                properties: [...newProperties.filter(x => x.type === "layout"), makeVisibilityProperty(visible)],
            }),
        )

        dispatch(
            layerSelectorActions.changePropertiesOfLayerStyle({
                layerId: layer.resourceId,
                properties: newProperties,
                styleId: style.styleId,
            }),
        )
    }

    const onAddStyleToLayer = (symbolStyle = false) => {
        if (layer.type === STYLE_TYPES.raster) addStyleToRasterLayer()
        else addStyleToVectorLayer(symbolStyle)
    }

    const addStyleToVectorLayer = symbolStyle => {
        const styleType = symbolStyle ? STYLE_TYPES.symbol : GeometryUtils.geometryTypeToMapboxType(layer.geometryType)

        const properties = styleConfig[styleType]
        const layerId = generateUuid()

        initializeNewStyle(layerId, properties)
        dispatch(mapActions.addMapZoomRange({ layerId, maxZoom: 24, minZoom: sourceMinZoomLimit }))

        dispatch(
            mapActions.addMapLayer({
                newLayer: {
                    layerId: layerId,
                    resourceId: layer.resourceId,
                    sourceId: layer.sourceId,
                    sourceName: layer.sourceName,
                    type: styleType,
                },
                targetResourceId: layer.resourceId,
            }),
        )

        const style = {
            maxZoom: 24,
            minZoom: 0,
            properties,
            styleId: layerId,
            type: styleType,
        }
        dispatch(layerSelectorActions.addStyleToLayer({ layerId: layer.resourceId, style }))
    }

    const addStyleToRasterLayer = () => {
        const layerId = generateUuid()
        const properties = styleConfig[STYLE_TYPES.raster]

        dispatch(
            mapActions.addMapLayer({
                newLayer: {
                    layerId: layerId,
                    resourceId: layer.resourceId,
                    sourceId: layer.sourceId,
                    sourceName: layer.sourceName,
                    type: SourceType.Raster,
                },
                targetResourceId: layer.resourceId,
            }),
        )
        initializeNewStyle(layerId, properties)
        dispatch(mapActions.addMapZoomRange({ layerId, maxZoom: 24, minZoom: 0 }))

        const style = {
            maxZoom: 24,
            minZoom: 0,
            properties,
            styleId: layerId,
            type: SourceType.Raster,
        }
        dispatch(layerSelectorActions.addStyleToLayer({ layerId: layer.resourceId, style }))
    }

    const onZoomSliderChange = (style, zoom) => {
        dispatch(mapActions.updateMapZoomRange({ layerId: style.styleId, maxZoom: zoom[1], minZoom: zoom[0] }))
        dispatch(
            layerSelectorActions.updateStyleZoomRange({
                layerId: layer.resourceId,
                maxZoom: zoom[1],
                minZoom: zoom[0],
                styleId: style.styleId,
            }),
        )
    }

    const onRemoveStyleFromLayer = styleLayer => {
        dispatch(mapActions.removeMapLayer(styleLayer.styleId))
        dispatch(layerSelectorActions.removeStyleFromLayer({ layerId: layer.resourceId, styleId: styleLayer.styleId }))
    }

    const onStyleDrop = (styleId, beforeStyleId) => {
        dispatch(
            layerSelectorActions.changeStyleOrder({
                beforeStyleId,
                layerId: layer.resourceId,
                styleId,
            }),
        )
        dispatch(
            mapActions.moveLayer({
                beforeLayerId: beforeStyleId,
                layerId: styleId,
            }),
        )
    }

    const onFiltersUpdate = filters => {
        const formattedFilters = filters.map(filter => {
            if (filter.operatorName === Operators.Between) {
                return {
                    applied: true,
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    secondValue: filter.secondValue,
                    type: filter.type,
                    value: filter.value,
                }
            } else {
                return {
                    applied: true,
                    fieldName: filter.fieldName,
                    operatorName: filter.operatorName,
                    type: filter.type,
                    value: filter.value,
                }
            }
        })
        dispatch(mapActions.setFilters({ filters: formattedFilters, layerId: layer.resourceId }))
        dispatch(setLayerIsFiltered({ layerResourceId: layer.resourceId, value: filters.length > 0 }))
    }

    return (
        <div className="edit-view-container">
            <Grid alignItems="center" className={classes.header} container wrap="nowrap">
                <IconButton size="large" onClick={clearEditView}>
                    <KeyboardBackspace className={classes.backIcon} />
                </IconButton>
                <TruncatedTypography variant="h2">{layer.name}</TruncatedTypography>
            </Grid>

            <div className={classes.stylerTabs}>
                <StyledTabs TabIndicatorProps={<div />} value={tab}>
                    <StyledTab
                        icon={<Palette />}
                        label={<div>Style</div>}
                        value={STYLE_TAB}
                        onClick={() => setTab(STYLE_TAB)}
                    />
                    {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && !layer.isBuffer && (
                        <StyledTab
                            icon={<FilterList />}
                            label={<div>Filter</div>}
                            value={FILTER_TAB}
                            onClick={() => setTab(FILTER_TAB)}
                        />
                    )}
                </StyledTabs>
            </div>

            {tab === STYLE_TAB && (
                <LoadingPlaceholder loading={isLoading}>
                    <div className={classes.stylerDivWrapper}>
                        <LayerStyleEditor
                            colorsArray={colorsArray}
                            columns={datasetColumns}
                            distinctColumnValues={distinctColumnValues}
                            layer={{ ...layer, styles: styles.map((style, index) => ({ ...style, index })) }}
                            minMaxColumnValues={minMaxColumnValues}
                            //column stuff
                            minZoomLimit={sourceMinZoomLimit ?? 0}
                            selectedDatasetColumn={selectedDatasetColumn}
                            styleConfig={styleConfig}
                            hasCpt={hasCpt}
                            isDigitizeLayer={isDigitizeLayer}
                            isDprLayer={!!dprDataset}
                            setSelectedDatasetColumn={setSelectedDatasetColumn}
                            onAddStyleToLayer={onAddStyleToLayer}
                            onPropertiesChanged={onPropertiesChanged}
                            //onHandlers
                            onPropertyChanged={onPropertyChanged}
                            onPropertyExpressionTypeChanged={onStyleTypeChanged}
                            onRemoveStyleFromLayer={onRemoveStyleFromLayer}
                            onStyleDrop={onStyleDrop}
                            onTypeChanged={onStyleTypeChanged}
                            onZoomSliderChange={onZoomSliderChange}
                        />
                    </div>
                </LoadingPlaceholder>
            )}
            {layer.type === SourceType.Vector && featureFlags.LAYER_FILTERS && tab === FILTER_TAB && (
                <div className={classes.stylerDivWrapper}>
                    <LayerFilterEditor
                        columns={filteredColumns}
                        initialFiltersList={filters}
                        onFiltersUpdate={onFiltersUpdate}
                    />
                </div>
            )}
        </div>
    )
}

export default LayerEditor
