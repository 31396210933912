import React from "react"
import { Typography, TypographyProps } from "@mui/material"

export type CustomTypographyProps = {
    className?: string
    textWeight?: "bold" | "inherit" | "regular" | "semibold"
} & TypographyProps

const textWeightDict: Record<string, string | number> = {
    bold: 700,
    inherit: "inherit",
    regular: 400,
    semibold: 600,
}

const CustomTypography = React.forwardRef<HTMLSpanElement, CustomTypographyProps>(
    ({ children, className = "", textWeight = "inherit", ...otherProps }, ref) => (
        <Typography className={className} fontWeight={textWeightDict[textWeight]} ref={ref} {...otherProps}>
            {children}
        </Typography>
    ),
)

export default CustomTypography
