import CheckedIcon from "@mui/icons-material/CheckCircleOutline"
import { adaptV4Theme, createTheme } from "@mui/material"
import { darken, lighten } from "@mui/material/styles"
import styles from "scss/variables.module.scss"

const PRIMARY_COLOR = styles.primaryColor
const SECONDARY_COLOR = styles.secondaryColor
const APP_BG = styles.appBgColor
const APP_BG_LIGHT = styles.appBgColorLight
const APP_BG_DARK = styles.appBgColorDark
const SUCCESS_COLOR = styles.successColor
const WARNING_COLOR = styles.warningColor
const SEMANTIC_2 = styles.semantic2
const ERROR_COLOR = styles.errorColor
const APP_TEXT_COLOR = styles.appTextColor
const APP_TEXT_COLOR_LIGHT = styles.appTextColorLight
const TEXT_COLOR_WHITE = styles.whiteText
const MUTED_COLOR = styles.mutedColor
const WHITE = styles.white
const LIGHT_GRAY = styles.lightGray
const GRAY = styles.gray
const DARK_GRAY = styles.darkGray
const BLACK = styles.black
const FIELD_BG = styles.fieldBackgroundColor
const BORDER_COLOR = styles.borderColor
const PRIMARY3 = styles.primary3
const SECONDARY_BACKGROUND_COLOR = styles.secondaryBackgroundColor

const theme: any = {
    customColors: {
        appBgColor: APP_BG,
        appBgColorDark: APP_BG_DARK,
        appBgColorLight: APP_BG_LIGHT,
        appTextColor: APP_TEXT_COLOR,
        appTextColorLight: APP_TEXT_COLOR_LIGHT,
        black: BLACK,
        borderColor: BORDER_COLOR,
        darkGray: DARK_GRAY,
        errorColor: ERROR_COLOR,
        gray: GRAY,
        lightGray: LIGHT_GRAY,
        mutedColor: MUTED_COLOR,
        primary3: PRIMARY3,
        primaryColor: PRIMARY_COLOR,
        secondaryBackgroundColor: SECONDARY_BACKGROUND_COLOR,
        secondaryColor: SECONDARY_COLOR,
        semantic2: SEMANTIC_2,
        successColor: SUCCESS_COLOR,
        warningColor: WARNING_COLOR,
        white: WHITE,
        whiteText: TEXT_COLOR_WHITE,
    },
    customSpacing: {
        appCardWidth: 300,
        generalSidePadding: 20,
        mapRightMargin: 10,
    },
    overrides: {
        MuiAppBar: {
            root: {
                boxShadow: "none",
                height: 48,
                minHeight: 48,
            },
        },
        MuiAutocomplete: {
            root: {
                "& .MuiFormControl-root": {
                    borderRadius: 5,
                    marginBottom: 6,
                    marginTop: 6,
                },
                paddingLeft: 6,
                paddingRight: 6,
            },
        },
        MuiButton: {
            containedSecondary: {
                "&:hover": {
                    backgroundColor: lighten(SECONDARY_COLOR, 0.4),
                },
                color: PRIMARY_COLOR,
            },
            root: {
                textTransform: "capitalize",
            },
        },
        MuiCardContent: {
            root: {
                "&:last-child": {
                    paddingBottom: 0,
                },
            },
        },
        MuiCheckbox: {
            colorSecondary: {
                "&.Mui-checked": {
                    "&.Mui-disabled": {
                        background: `radial-gradient(${lighten(MUTED_COLOR, 0.5)} 50%,transparent 0,transparent)`,
                    },
                    background: `radial-gradient(${PRIMARY_COLOR} 50%,transparent 0,transparent)`,
                    color: SECONDARY_COLOR,

                    outline: `2px solid ${PRIMARY_COLOR}`,
                },
                "&.Mui-disabled": {
                    color: FIELD_BG,
                    cursor: "not-allowed",
                    outline: `2px solid ${lighten(MUTED_COLOR, 0.5)}`,
                },

                borderRadius: 5,

                outlineOffset: -5,
            },
            root: {
                "&:hover": { background: "transparent", color: PRIMARY_COLOR },
                "&.Mui-checked": {
                    "&:hover": {
                        background: `radial-gradient(${lighten(PRIMARY_COLOR, 0.2)} 50%,transparent 0,transparent)`,
                        outlineColor: lighten(PRIMARY_COLOR, 0.2),
                    },
                },

                color: MUTED_COLOR,

                padding: 0,
            },
        },
        MuiChip: {
            colorWarning: {
                backgroundColor: lighten(WARNING_COLOR, 0.8),
                color: darken(WARNING_COLOR, 0.2),
            },
        },
        MuiDialog: {
            paper: {
                borderRadius: 8,
                maxWidth: "fit-content",
                minWidth: 400,
            },
        },
        MuiDialogActions: {
            root: {
                justifyContent: "flex-start",
                padding: 20,
            },
        },
        MuiDialogContent: {
            dividers: {
                padding: 20,
            },
        },
        MuiDialogTitle: {
            root: {
                "& .MuiFormControl-root": {
                    borderRadius: 5,
                    marginBottom: 6,
                    marginTop: 6,
                },
                padding: 20,
            },
        },
        MuiFilledInput: {
            input: {
                fontSize: 14,
            },
            root: {
                backgroundColor: FIELD_BG,
                height: 56,
            },
            sizeSmall: {
                height: 40,
            },
        },
        MuiFormControl: {
            root: {
                color: "gray",
            },
        },
        MuiFormControlLabel: {
            label: {
                color: APP_TEXT_COLOR,
            },
        },
        MuiFormLabel: {
            root: {
                "&$focused": {
                    // increase the specificity for the pseudo class
                    color: PRIMARY_COLOR,
                },
                color: APP_TEXT_COLOR,
            },
        },
        MuiIconButton: {
            colorSecondary: {
                "&:hover": {
                    backgroundColor: lighten(SECONDARY_COLOR, 0.4),
                },
                backgroundColor: SECONDARY_COLOR,
                color: PRIMARY_COLOR,
            },
        },
        MuiInput: {
            root: {
                color: APP_TEXT_COLOR,
            },
            underline: {
                "&:after": {
                    borderBottom: PRIMARY_COLOR + " 2px solid",
                },
                "&:before": {
                    borderBottom: "gray 1px solid",
                },
                "&:hover:before": {
                    borderBottom: "lightgray 1px solid !important",
                },
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: "transparent",
            },
        },
        MuiList: {
            root: {
                padding: 0,
            },
        },
        MuiMenuItem: {
            root: {
                color: APP_TEXT_COLOR,
            },
        },
        MuiPickersBasePicker: {
            pickerView: {
                backgroundColor: APP_BG,
            },
        },
        MuiPickersModal: {
            withAdditionalAction: {
                backgroundColor: APP_BG,
            },
        },
        MuiRadio: {
            root: {
                "&:hover": { background: "transparent", color: PRIMARY_COLOR },
                "&.Mui-checked": {
                    "&:hover": {
                        background: `radial-gradient(${lighten(SECONDARY_COLOR, 0.2)} 30%,transparent 0,transparent)`,
                        color: lighten(PRIMARY_COLOR, 0.2),
                    },

                    "&.Mui-disabled": {
                        background: `radial-gradient(${lighten(MUTED_COLOR, 0.8)} 30%,transparent 0,transparent)`,
                    },
                    background: `radial-gradient(${SECONDARY_COLOR} 30%,transparent 0,transparent)`,
                },

                "&.Mui-disabled": {
                    cursor: "not-allowed",
                },

                background: "transparent",
            },
        },
        MuiSelect: {
            icon: {
                color: APP_TEXT_COLOR,
            },
            root: {
                width: "100%",
            },
            select: {
                color: APP_TEXT_COLOR,
                width: "100%",
            },
        },
        MuiSlider: {
            rail: {
                height: 2,
            },
        },
        MuiTab: {
            root: {
                backgroundColor: APP_BG_LIGHT,
                fontSize: "14px",
                lineHeight: "20px",
                textTransform: "capitalize",
            },
            wrapper: {
                color: APP_TEXT_COLOR,
            },
        },
        MuiTableCell: {
            body: {
                color: APP_TEXT_COLOR,
            },
        },
        MuiTablePagination: {
            actions: {
                color: APP_TEXT_COLOR,
            },
            root: {
                color: APP_TEXT_COLOR,
            },
        },
        MuiTableSortLabel: {
            root: {
                color: APP_TEXT_COLOR,
            },
        },
        MuiToolbar: {
            root: {
                height: 48,
                minHeight: "48px !important",
                paddingLeft: "16px !important",
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: MUTED_COLOR,
                fontSize: 14,
                maxWidth: 500,
                padding: 10,
            },
            tooltipPlacementBottom: {
                margin: "6px 0px 0px 0px !important",
            },
            tooltipPlacementTop: {
                margin: "4px 0px 0px 0px !important",
            },
        },
        //Try not to override the elevation because most components accept the prop: elevation={x}
        MuiTypography: {
            root: {
                color: APP_TEXT_COLOR,
            },
        },
    },
    palette: {
        background: { main: APP_BG },
        error: {
            main: ERROR_COLOR,
        },
        mode: "light",
        primary: {
            dark: darken(PRIMARY_COLOR, 0.2),
            light: lighten(PRIMARY_COLOR, 0.2),
            main: PRIMARY_COLOR,
        },
        secondary: {
            dark: darken(SECONDARY_COLOR, 0.2),
            light: lighten(SECONDARY_COLOR, 0.2),
            main: SECONDARY_COLOR,
        },
        secondaryBackground: {
            main: APP_BG_LIGHT,
        },
        success: {
            dark: darken(SUCCESS_COLOR, 0.2),
            light: lighten(SUCCESS_COLOR, 0.2),
            main: SUCCESS_COLOR,
        },
        text: {
            main: APP_TEXT_COLOR,
            secondary: MUTED_COLOR,
        },
        warning: {
            main: WARNING_COLOR,
        },
    },
    props: {
        MuiButton: {
            disableElevation: true,
        },
        MuiCheckbox: {
            color: "secondary",
        },
        MuiRadio: {
            checkedIcon: <CheckedIcon />,
            disableRipple: true,
        },
        MuiTextField: {
            inputProps: {
                style: { fontWeight: "bold" },
            },
        },
        MuiTooltip: {
            arrow: true,
            disableInteractive: true,
        },
    },
    typography: {
        body1: {
            fontSize: 16,
            lineHeight: "22px",
        },
        body2: {
            fontSize: 14,
            lineHeight: "20px",
        },
        caption: {
            fontSize: 12,
            lineHeight: "18px",
        },
        fontFamily: [
            '"Nunito Sans"',
            "-apple-system",
            "BlinkMacSystemFont",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
        ].join(","),
        h1: {
            fontSize: 24,
            lineHeight: "36px",
        },
        h2: {
            fontSize: 20,
            lineHeight: "30px",
        },
        h3: {
            fontSize: 18,
            lineHeight: "26px",
        },
        subtitle1: {
            fontSize: 16,
            lineHeight: "24px",
        },
        useNextVariants: true,
    },
}

export default createTheme(adaptV4Theme(theme))
