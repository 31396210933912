import React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import StyleTypeVisualizer from "../../../styleTypeVisualizer"

const StylesSubList = ({
    dndContext,
    selectedStyleId,
    styles,
    styleDeletionDisabled,
    onRemoveStyleFromLayer,
    onSelectStyle,
    onStyleDrop,
}) => {
    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) return

        if (result.destination.index === result.source.index) return
        onStyleDrop(result.draggableId, styles[result.destination.index].styleId)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={dndContext} type={dndContext}>
                {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {styles.map((style, index) => (
                            <Draggable key={style.styleId} draggableId={style.styleId} index={index}>
                                {provided => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        onClick={() => onSelectStyle(style.styleId)}
                                    >
                                        <StyleTypeVisualizer
                                            expressionType={style.expressionType}
                                            layerProperties={style.properties}
                                            selected={style.styleId === selectedStyleId}
                                            type={style.type}
                                            styleDeletionDisabled={styleDeletionDisabled}
                                            onRemove={() => onRemoveStyleFromLayer(style)}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

StylesSubList.defaultProps = {
    startIndex: 0,
}

export default StylesSubList
