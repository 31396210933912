import React from "react"
import { TextField } from "@mui/material"
import { Box } from "@mui/system"
import ZoomValueTextField from "../ZoomValueTextField/ZoomValueTextField"

const TitleTextField = ({ value, isDefaultValue, isReadOnly, isZoomDependent, onTitleChanged }) => {
    const onChange = e => {
        onTitleChanged(e.target.value)
    }

    return (
        <>
            <div className="textfield-container">
                {isZoomDependent ? (
                    <ZoomValueTextField value={value} onTitleChanged={onTitleChanged} />
                ) : (
                    <TextField
                        className="textfield"
                        InputProps={{
                            readOnly: isReadOnly,
                        }}
                        value={value}
                        variant="standard"
                        disabled={isDefaultValue}
                        onChange={onChange}
                    />
                )}
            </div>
            <Box className="spacing-color" width={8} />
        </>
    )
}

export default TitleTextField
