import { styled } from "@mui/material"
import TextField from "@mui/material/TextField"

export const StyledTextField = styled(TextField)`
    input {
        padding: 8px;
        font-weight: normal !important;
    }

    .MuiInputAdornment-root {
        margin: 0;
    }

    .MuiInputAdornment-positionStart {
        margin-top: 0 !important;
    }
`
