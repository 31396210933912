import React, { useEffect, useState } from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useMap } from "@emblautec/react-map-gl"

const useStyles = makeStyles(() => ({
    image: {
        maxHeight: 40,
    },
    root: {
        backgroundColor: "rgba(0,0,0,0)",
        pointerEvents: "none",
        position: "absolute",
        right: 20,
        top: 20,
        zIndex: 1,
    },
}))

const MapNorthArrow = ({ modal = false }) => {
    const { current } = useMap()
    const classes = useStyles()
    const [north, setNorth] = useState(current.getBearing())

    useEffect(() => {
        let arrow = document.getElementById("north-arrow-icon")
        current.on("rotate", () => {
            setNorth(current.getBearing())
            arrow.style.transform = `rotate(${-north}deg)`
        })
    })
    return (
        <div className={classes.root} id="map-north-arrow">
            <img
                alt="map-north-arrow"
                className={classes.image}
                id="north-arrow-icon"
                src={import.meta.env.BASE_URL + "media/arrow.png"}
            />
        </div>
    )
}

export default MapNorthArrow
